import {
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import dayjs from "dayjs";

import {
  Box,
  Chip,
  Stack,
  Modal,
  Typography,
  IconButton,
  Paper,
  Divider,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import Toast from "../../../utils/Toast";

interface propsType {
  comments: any;
  callback: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  maxHeight: "80vh",
  bgcolor: "background.paper",
  border: "0px solid #000",
  borderRadius: "1rem",
  boxShadow: 24,
  p: 3,
};

const CommentsViewer = (props: propsType, ref: any) => {
  const toastRef: any = useRef();

  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    return () => {
      setOpenModal(false);
    };
  }, []);

  const open = () => {
    setOpenModal(true);
  };

  const close = () => {
    setOpenModal(false);
  };

  const CommentCard = (param: any) => {
    const { commentInfo, index } = param;

    return (
      <Paper
        sx={{
          width: "100%",
          borderRadius: "7px",
          backgroundColor: "rgba(70, 117, 231, 0.07)",
          color: "inherit",
          padding: "16px",
          boxSizing: "border-box",
        }}
        elevation={0}
      >
        <Stack direction={"column"} spacing={2}>
          <Stack
            direction={"row"}
            spacing={2}
            sx={{ justifyContent: "space-between" }}
          >
            {commentInfo.type === "requestHongbo" ? (
              <Chip label="홍보실 전송" size="small" color="info" />
            ) : commentInfo.type === "return" ? (
              <Chip label="반려" size="small" color="default" sx={{ color: "#fff", backgroundColor: "#515151"}}/>
            ) : commentInfo.type === "reject" ? (
              <Chip label="보완 요청" size="small" color="error" />
            ) : commentInfo.type === "toss" ? (
              <Chip label="담당자 토스" size="small" color="warning" />
            ) : commentInfo.type === "complete" ? (
              <Chip label="홍보실 완료" size="small" color="success" />
            ) : (
              <Chip label="일반" size="small" color="primary" />
            )}
            <Stack
              direction={"row"}
              spacing={2}
              sx={{ justifyContent: "flex-end" }}
            >
              <Typography gutterBottom sx={{ color: "#808080" }}>
                {commentInfo.user_name}
              </Typography>
              <Typography gutterBottom sx={{ color: "#808080" }}>
                {dayjs(commentInfo.sk).format("YYYY-MM-DD HH:mm:ss")}
              </Typography>
            </Stack>
          </Stack>
          <Divider />
          <Typography
            gutterBottom
            sx={{
              lineHeight: "40px",
              fontWeight: "600 !important",
              whiteSpace: "pre-line",
            }}
          >
            {commentInfo.comment}
          </Typography>
        </Stack>
      </Paper>
    );
  };

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    open,
  }));

  return (
    <>
      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack direction="column" spacing={2}>
            <Stack
              direction="row"
              spacing={2}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Typography
                gutterBottom
                sx={{ lineHeight: "40px", fontWeight: "800 !important" }}
              >
                의견 보기
              </Typography>
              <Tooltip title="창닫기">
                <IconButton aria-label="close" onClick={close}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Stack>
            <Box
              sx={{
                maxHeight: "calc(80vh - 60px)",
                overflowY: "auto",
              }}
            >
              <Stack direction={"column"} spacing={2}>
                {props.comments?.map((commentInfo: any, index: number) => (
                  <CommentCard
                    key={`comment-list-${index}`}
                    commentInfo={commentInfo}
                    index={index}
                  />
                ))}
              </Stack>
            </Box>
          </Stack>
        </Box>
      </Modal>
      <Toast ref={toastRef} />
    </>
  );
};

export default forwardRef(CommentsViewer);
