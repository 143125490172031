import { useEffect, useRef, useState } from "react";

import { TextField } from "@mui/material";
import { HttpOpenAIAiApi } from "../../interface/openai-ai-api";
import { HttpNaverAiApi } from "../../interface/naver-ai-api";
import { HttpMainApi } from "../../interface/main-api";
import { userState } from "../../interface/MainInterface";
import LoadingCircle from "../../utils/LoadingCircle";
import Toast from "../../utils/Toast";
import remarkGfm from "remark-gfm";
import ReactMarkdown from "react-markdown";
import { ModelHandler } from "../../interface/model-handler";

interface propsType {
  userState: userState;
  getLeftCount: any;
}

const mainApi = new HttpMainApi();

const OptionPage = (props: propsType) => {
  const toastRef: any = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [ableRequest, setAbleRequest] = useState(true);
  const [editResult, setEditResult] = useState("");
  const [inputData, setInputData] = useState("");
  const [formstyle, setFormstyle] = useState(
    "주어진 문서를 3줄로 요약합니다.\n1.\n2.\n3."
  );
  const [selectedOption, setSelectedOption] = useState("opt1");

  const [model, setModel] = useState("");
  const modelHandler = new ModelHandler(model);

  useEffect(() => {
    modelHandler.getUsingModel()
      .then(usingModel => {
        setModel(usingModel);
      })
      .catch((errorMsg : any) => {
        toastRef.current?.toast("" + errorMsg, "error", 4000, {
          vertical: "top",
          horizontal: "center",
        });
      })
    return () => {
      setIsLoading(false);
      setAbleRequest(true);
      setEditResult("");
    };
  }, []);
  
  const [aiApi, handleStreamResponse] = modelHandler.getModelElements();

  const createAnswer = async () => {
    if (!inputData) {
      toastRef.current?.toast("내용을 입력하세요.", "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
      return;
    }
    if (!formstyle) {
      toastRef.current?.toast("양식을 입력하세요.", "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
      return;
    }

    const param: any = {
      command: "create_option_v1",
      inputData: inputData,
      formstyle: formstyle,
    };
    setIsLoading(true); // 로딩바 활성화
    setAbleRequest(false); // 생성 버튼 disable

    const streamResponse = await aiApi.postStream(param);
    setIsLoading(false); // 로딩바 비활성화

    handleStreamResponse(streamResponse, setEditResult);
    setAbleRequest(true);
  };

  const handleSelectChange = (event: any) => {
    setSelectedOption(event.target.value);
    if (event.target.value === "opt1") {
      setFormstyle("주어진 문서를 3줄로 요약합니다.\n1.\n2.\n3.");
    } else if (event.target.value === "opt2") {
      setFormstyle(
        "주어진 문서의 핵심 내용을 표로 요약합니다.\n구분(회사명, 인사이트) / 내용"
      );
    } else if (event.target.value === "opt3") {
      setFormstyle(
        "주어진 문서를 보고서로 작성합니다.\n1.제목\n2.개요\n4.핵심 내용 표로 작성\n5.인사이트 한가지"
      );
    } else if (event.target.value === "opt4") {
      setFormstyle("");
    }
  };
  return (
    <>
      <div className="container flex">
        <div className="left summary-txt">
          <div className="of-scroll">
            <div className="summary">
              <h2>보도자료</h2>
              <TextField
                size="small"
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                }}
                fullWidth
                multiline
                rows={1}
                placeholder={"내용을 입력하세요."}
                value={inputData}
                onChange={(e) => {
                  setInputData(e.target.value);
                }}
                type="search"
                autoComplete="off"
                inputProps={{ enterKeyHint: "Enter" }}
              />
            </div>
            <div className="reference">
              <h2>양식</h2>
              <TextField
                size="small"
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                }}
                fullWidth
                multiline
                rows={1}
                placeholder={"양식을 입력하세요."}
                value={formstyle}
                onChange={(e) => {
                  setFormstyle(e.target.value);
                }}
                type="search"
                autoComplete="off"
                inputProps={{ enterKeyHint: "Enter" }}
              />
            </div>
          </div>
          <div className="summ-btn-wrap flex justify-content-center">
            <select
              id="option"
              value={selectedOption}
              onChange={handleSelectChange}
            >
              <option value="opt1">요약하기</option>
              <option value="opt2">표로작성</option>
              <option value="opt3">보고서양식</option>
              <option value="opt4">자유양식</option>
            </select>
            <button
              className="btn-prim"
              disabled={!ableRequest}
              onClick={() => {createAnswer();}}
            >
              명령하기
            </button>
          </div>
        </div>
        <div className="right summ-result">
          <h2>답변 내용</h2>
          <div className="markdown" style={{ height: "calc(100% - 60px)" }}>
            <ReactMarkdown remarkPlugins={[remarkGfm]}>
              {editResult}
            </ReactMarkdown>
          </div>
        </div>
      </div>

      <LoadingCircle loading={isLoading} />
      {/* 알림 토스트 */}
      <Toast ref={toastRef} />
    </>
  );
};

export default OptionPage;
