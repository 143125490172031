import { useEffect, useRef, useState, forwardRef } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

import Button from "@mui/material/Button";
import Input from "@mui/material/Input";

import { HttpUnAuthApi } from "../../interface/unauth-api";

import Toast from "../Toast";

interface propsType {
  rootBucket: string | undefined;
  uploadDir: string;
  callBack: any;
}

const unAuthApi = new HttpUnAuthApi();

const S3Uploader = (props: propsType, ref: any) => {
  const toastRef: any = useRef();
  const attachInputRef: any = useRef();

  const [click, setClick] = useState(false);
  const [targetCnt, setTargetCnt] = useState(0);
  const [okCnt, setOkCnt] = useState(0);
  const [resultFiles, setResultFiles] = useState<any>([]);

  const fncUpload = () => {
    setTargetCnt(0);
    setOkCnt(0);
    setResultFiles([]);
    attachInputRef.current?.click();
  };

  const handleAttachInput = async (e: React.ChangeEvent<HTMLInputElement>) => {
    // const acceptableFormats = [
    //   "image/jpeg",
    //   "image/jpg",
    //   "image/png",
    //   "image/bmp",
    //   "image/gif",
    //   "application/pdf",
    //   "text/csv",
    //   "text/plain",
    //   "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    //   "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    //   "application/vnd.ms-excel",
    //   "application/vnd.ms-powerpoint",
    //   "application/mspowerpoint",
    //   "application/x-mspowerpoint",
    //   "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    //   "application/msword",
    //   "application/csv",
    //   "application/excel",
    //   "video/mp4",
    //   "video/avi",
    //   "video/mov",
    //   "video/quicktime",
    // ];
    if (e.target.files !== null) {
      const files: FileList = e.target.files;
      // for (let i = 0; i < files.length; i++) {
      //   const file = files[i];
      //   if (!acceptableFormats.includes(file.type)) {
      //     alert("지원하지 않는 파일입니다.");
      //     return;
      //   }
      // }
      setTargetCnt(files.length);

      let result: any = [...resultFiles];

      for await (const fileInfo of files) {
        const file = fileInfo;
        const type = file.name.split(".").pop();
        const filePath = `${props.uploadDir}${uuidv4()}.${type}`;

        console.log(filePath);

        const params = {
          command: "get_cf_presigned_url",
          key: filePath,
        };

        const res = await unAuthApi.post(params);
        console.log("res : ", res);
        if (res.code === "200") {
          const upload_url = res.response.upload_url;
          console.log("upload_url : ", upload_url); 
          const uploadRes = await axios.put(upload_url, fileInfo);
          console.log("uploadRes : ", uploadRes); // statusCode 200 === Success
          if (uploadRes.status === 200) {
            result = [...result, { FILE_NAME: file.name, FILE_PATH: res.response.object_url }];
            setOkCnt((setOkCnt) => setOkCnt + 1);
          } else {
            props.callBack({ status: "fail" });
            break;
          }
        }
      }
      setResultFiles(result);
    }
  };

  useEffect(() => {
    if (okCnt === targetCnt) {
      props.callBack({ status: "ok", fileInfo: resultFiles });
      setClick(false);
    } else setClick(true);
  }, [okCnt, targetCnt]);

  useEffect(() => {
    setClick(false);
    setTargetCnt(0);
    setOkCnt(0);
    setResultFiles([]);
  }, []);
  return (
    <>
      <button
        className="add-file btn-ghost"
        onClick={() => {
          fncUpload();
        }}
        disabled={click}
      >
        + 파일첨부
      </button>

      <Input
        key="attachment"
        color="primary"
        type="file"
        inputProps={{ multiple: true }}
        inputRef={attachInputRef}
        onChange={handleAttachInput}
        sx={{ display: "none" }}
      />
      <Toast ref={toastRef} />
    </>
  );
};

export default forwardRef(S3Uploader);
