import {
  useCallback,
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { TextField, Button, Tooltip, Checkbox, FormControlLabel } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Toast from "../../../utils/Toast";
import LoadingCircle from "../../../utils/LoadingCircle";
import { userState } from "../../../interface/MainInterface";
import { HttpMainApi } from "../../../interface/main-api";
import { HttpCustomApi } from "../../../interface/custom-api";

interface propsType {
  userState: userState;
  pressInfo: any;
  callback: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "400px",
  bgcolor: "background.paper",
  border: "0px solid #000",
  borderRadius: "1rem",
  boxShadow: 24,
  p: 3,
};

const mainApi = new HttpMainApi();
const vectoCoreApi = new HttpCustomApi(process.env.REACT_APP_VECTOR_CORE);

const PopAddComment = (props: propsType, ref: any) => {
  const toastRef: any = useRef();
  const commentRef: any = useRef();

  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSecret, setIsSecret] = useState(false);
  const [comment, setComment] = useState("");
  const [flag, setFlag] = useState("");

  useEffect(() => {
    return () => {
      setOpenModal(false);
      setIsLoading(false);
      setIsSecret(false);
      setComment("");
      setFlag("");
    };
  }, []);

  const open = useCallback((flag: string) => {
    setFlag(flag);
    setOpenModal(true);
  }, []);

  const addComment = async () => {
    if (comment === "") {
      toastRef.current?.toast("의견을 입력해주세요.", "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
      commentRef.current.focus();
      return;
    }

    setIsLoading(true);
    const param: any = {
      command: "add_press_comment",
      press_info: props.pressInfo,
      comment_info: {
        user_id: props.userState.id,
        user_name: props.userState.name,
        type: "normal",
        comment: comment,
      },
    };

    const res = await mainApi.post(param);
    if (res.code === "200") {
      if (flag === "") {
        await props.callback("reload_comment");
        close();
      }
    } else {
      console.error(res.response.error_msg);
      toastRef.current?.toast("" + res.response.error_msg, "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
    }
    setIsLoading(false);
  };

  // 홍보실 전송
  const requestHongbo = async () => {
    if (!window.confirm("해당 보도자료를 홍보실로 전송합니다.")) return;
    // 홍보실 전송 comment 저장
    // 보도자료 상태 변경
    setIsLoading(true);
    const param: any = {
      command: "send_press_info",
      press_info: props.pressInfo,
      comment_info: {
        user_id: props.userState.id,
        user_name: props.userState.name,
        type: "requestHongbo",
        comment: comment,
      },
    };

    const res = await mainApi.post(param);
    if (res.code === "200") {
      let pressSubject = props.pressInfo.category ? `[${props.pressInfo.category}] `: "";
      pressSubject = pressSubject + props.pressInfo.title;

      let requestBody = comment + "\n\n보도자료 URL : " + window.location.href;
      const hartParam: any = {
        gubun: "ITVOC_REG",
        User: props.userState.id,
        Body: requestBody,
        Subject: pressSubject,
        CharUser: "PRDEPT",
        ReqReplyDate: new Date().toISOString().slice(0, 10).replace(/-/g, '.'),
        Category1: "ECAAAA",
        Category2: "VP02",
        Category3: "VP02117",
        SecretYn: isSecret ? 1 : 0
      }

      try {
        const hres = await fetch("https://vpn.haap-hyundaidept.com/haap", {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(hartParam),
        });
        console.log("hres ---> ", hres);
      } catch (error) {
        console.error('에러 발생: ', error);
      }
      
      
      if (flag === "requestHongbo") {
        await props.callback("reload_request_hongbo");
        close();
      }
    } else {
      console.error(res.response.error_msg);
      toastRef.current?.toast("" + res.response.error_msg, "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
    }

    setIsLoading(false);
  };

  // 반려
  const returnPress = async() => {
    if (!window.confirm("반려 처리합니다.")) return;
    // 반려 comment 저장
    // 보도자료 상태 변경
    setIsLoading(true);
    const param: any = {
      command: "return_press",
      press_info: props.pressInfo,
      comment_info: {
        user_id: props.userState.id,
        user_name: props.userState.name,
        type: "return",
        comment: comment,
      },
    };

    const res = await mainApi.post(param);
    if (res.code === "200") {
      if (flag === "return") {
        await props.callback("reload_return");
        close();
      }
    } else {
      console.error(res.response.error_msg);
      toastRef.current?.toast("" + res.response.error_msg, "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
    }
    setIsLoading(false);
  }

  // 보완 요청
  const rejectPress = async () => {
    if (!window.confirm("보완 요청 처리합니다.")) return;
    // 보완 요청 comment 저장
    // 보도자료 상태 변경
    setIsLoading(true);
    const param: any = {
      command: "reject_press",
      press_info: props.pressInfo,
      comment_info: {
        user_id: props.userState.id,
        user_name: props.userState.name,
        type: "reject",
        comment: comment,
      },
    };

    const res = await mainApi.post(param);
    if (res.code === "200") {
      if (flag === "reject") {
        await props.callback("reload_reject");
        close();
      }
    } else {
      console.error(res.response.error_msg);
      toastRef.current?.toast("" + res.response.error_msg, "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
    }
    setIsLoading(false);
  };

  // 홍보실 최종 완료
  const completePress = async () => {
    if (
      !window.confirm(
        "최종 완료하시겠습니까?\n최종 완료된 보도자료는 더이상 수정할 수 없습니다."
      )
    )
      return;

    setIsLoading(true);
    // 테마 임베딩
    let ebdText: any = props.pressInfo.form_data;
    ebdText.title = props.pressInfo.title;
    const ebdParam: any = {
      command: "naver_embedding",
      text: JSON.stringify(ebdText).substring(0, 700),
    };
    const vectorRes = await mainApi.post(ebdParam);
    console.log("vectorRes : ", vectorRes);
    if (vectorRes.code !== "200") {
      console.error(vectorRes.response.error_msg);
      toastRef.current?.toast(
        "" + vectorRes.response.error_msg,
        "error",
        4000,
        { vertical: "top", horizontal: "center" }
      );
      setIsLoading(false);
      return;
    }

    // itne vectore db insert
    const itneParam: any = {
      command: "create_doc",
      index_name: "naverx-haap-search",
      doc_body: {
        id: props.pressInfo.sk,
        text: JSON.stringify(ebdText),
        metadata: JSON.stringify({
          pk: props.pressInfo.pk,
          sk: props.pressInfo.sk,
        }),
        embedding: vectorRes.response.embedding,
      },
    };

    const vectorResult = await vectoCoreApi.auth_request(
      "api",
      itneParam,
      process.env.REACT_APP_TENANT_KEY
    );
    console.log("vectorResult : ", vectorResult);
    if (vectorResult.code !== "200") {
      console.error(vectorResult.response.error_msg);
      toastRef.current?.toast(
        "" + vectorResult.response.error_msg,
        "error",
        4000,
        { vertical: "top", horizontal: "center" }
      );
      setIsLoading(false);
      return;
    }

    // extream search db insert
    const insertData: any = {
      pk: "naverx-haap-search",
      sk: props.pressInfo.sk,
    };

    insertData["data"] = props.pressInfo;

    const insertParam: any = {
      command: "put_extream_data",
      data: insertData,
    };

    const extreamResult = await vectoCoreApi.auth_request(
      "api",
      insertParam,
      process.env.REACT_APP_TENANT_KEY
    );
    console.log("extreamResult : ", extreamResult);
    if (extreamResult.code !== "200") {
      console.error(extreamResult.response.error_msg);
      toastRef.current?.toast(
        "" + extreamResult.response.error_msg,
        "error",
        4000,
        { vertical: "top", horizontal: "center" }
      );
      setIsLoading(false);
      return;
    }

    // 홍보실 전송 comment 저장
    // 보도자료 상태 변경
    const param: any = {
      command: "complete_press",
      press_info: props.pressInfo,
      comment_info: {
        user_id: props.userState.id,
        user_name: props.userState.name,
        type: "complete",
        comment: comment,
      },
    };

    const res = await mainApi.post(param);
    if (res.code === "200") {
      if (flag === "complete") {
        await props.callback("reload_complete");
        close();
      }
    } else {
      console.error(res.response.error_msg);
      toastRef.current?.toast("" + res.response.error_msg, "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
    }
    setIsLoading(false);
  };

  const close = () => {
    setComment("");
    setIsSecret(false);
    setFlag("");
    setOpenModal(false);
  };

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    open,
  }));

  return (
    <>
      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack direction="column" spacing={2} sx={{ height: "100%" }}>
            <Stack
              direction="row"
              spacing={2}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              {flag === "" && ( // 일반 의견
                <Typography
                  gutterBottom
                  sx={{ lineHeight: "40px", fontWeight: "800 !important" }}
                >
                  의견 추가하기
                </Typography>
              )}
              {flag === "requestHongbo" && ( // 홍보실 전송 의견
              <div style={{display: "flex"}}>
                <Typography
                  gutterBottom
                  sx={{ lineHeight: "40px", fontWeight: "800 !important" }}
                >
                  홍보실 전송 대한 의견을 추가해주세요.
                </Typography>
                <FormControlLabel 
                  control={<Checkbox disableRipple checked={isSecret} onChange={() => setIsSecret(!isSecret)}/>} 
                  label="비밀글"
                  sx={{ ml : "10px", padding: "0px 13px 0px 3px", backgroundColor: "#f4f4f4", borderRadius: "23px" }}
                />
              </div>
              )}
              {flag === "return" && ( // 반려시 의견
                <Typography
                  gutterBottom
                  sx={{ lineHeight: "40px", fontWeight: "800 !important" }}
                >
                  반려에 대한 의견을 추가해주세요.
                </Typography>
              )}
              {flag === "reject" && ( // 반려시 의견
                <Typography
                  gutterBottom
                  sx={{ lineHeight: "40px", fontWeight: "800 !important" }}
                >
                  보완요청에 대한 의견을 추가해주세요.
                </Typography>
              )}
              {flag === "complete" && ( // 홍보실 최종완료 의견
                <Typography
                  gutterBottom
                  sx={{ lineHeight: "40px", fontWeight: "800 !important" }}
                >
                  홍보실 최종 완료 대한 의견을 추가해주세요.
                </Typography>
              )}
              <Tooltip title="창닫기">
                <IconButton aria-label="close" onClick={close}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Stack>
            <TextField
              inputRef={commentRef}
              id="txtComment"
              value={comment}
              label="의견"
              placeholder="남기실 의견을 입력하세요."
              autoFocus
              fullWidth
              multiline
              rows={11}
              onChange={(e) => {
                setComment(e.target.value);
              }}
              type="search"
              autoComplete="off"
              inputProps={{ enterKeyHint: "Enter" }}
            />
            <Stack
              direction="row"
              spacing={2}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button variant="outlined" onClick={close} disabled={isLoading}>
                취소
              </Button>
              {flag === "" && (
                <Button
                  variant="contained"
                  onClick={addComment}
                  disabled={isLoading}
                >
                  추가
                </Button>
              )}
              {flag === "requestHongbo" && (
                <Button
                  variant="contained"
                  onClick={requestHongbo}
                  disabled={isLoading}
                >
                  홍보실 전송
                </Button>
              )}
              {flag === "return" && (
                <Button
                  variant="contained"
                  onClick={returnPress}
                  disabled={isLoading}
                >
                  반려
                </Button>
              )}
              {flag === "reject" && (
                <Button
                  variant="contained"
                  onClick={rejectPress}
                  disabled={isLoading}
                >
                  보완 요청
                </Button>
              )}
              {flag === "complete" && (
                <Button
                  variant="contained"
                  onClick={completePress}
                  disabled={isLoading}
                >
                  최종 완료
                </Button>
              )}
            </Stack>
          </Stack>
        </Box>
      </Modal>
      <Toast ref={toastRef} />
      <LoadingCircle loading={isLoading} />
    </>
  );
};

export default forwardRef(PopAddComment);
