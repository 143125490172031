import { Box, Button, Checkbox, Divider, IconButton, Paper, Stack, Tooltip, Typography } from "@mui/material";
import KeyIcon from "@mui/icons-material/Key";
import { userState } from "../../interface/MainInterface";
import { DataGrid, GridCellParams, GridColDef, GridFilterModel, GridRenderCellParams, GridRowSelectionModel, useGridApiRef } from "@mui/x-data-grid";
import { useEffect, useRef, useState } from "react";
import { HttpMainApi } from "../../interface/main-api";
import Delete from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import PopAddUser from "./PopAddUser";

interface propsType {
    userState: userState;
}

const mainApi = new HttpMainApi();

const MngUser2 = (props: propsType) => {
    const toastRef: any = useRef();
    const addUserRef: any = useRef();

    const [isLoading, setIsLoading] = useState(false);
    const [userList, setUserList] = useState<any>([]);
    const [question, setQuestion] = useState("");
    const [selectedQuestionOption, setSelectedQuestionOption] = useState("name");

    const [rows, setRows] = useState([]);
    const columns: GridColDef[] = [
        {field: 'sk', headerName: 'ID (사번)', headerAlign: "center", align: "center", flex: 2},
        {field: 'name', headerName: "이름", headerAlign: "center", align: "center", flex: 1},
        {field: 'company', headerName: "소속", headerAlign: "center", align: "center", flex: 1.5},
        {field: 'dept', headerName: "부서", headerAlign: "center", align: "center", flex: 1.5},
        {field: 'is_auth', headerName: "승인", headerAlign: "center", align: "center", flex: 1,
            renderCell: (param: GridRenderCellParams) => {
                return (
                    <Checkbox
                        checked={param.row.is_auth === undefined ? false : param.row.is_auth}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            set_auth_info(e.target.checked, param.row);
                        }}
                    />
                )
            }
        },
        {field: 'is_admin', headerName: "홍보실 권한", headerAlign: "center", align: "center", flex: 1,
            renderCell: (param: GridRenderCellParams) => {
                return (
                    <Checkbox
                        checked={param.row.is_admin === undefined ? false : param.row.is_admin}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            set_admin_info(e.target.checked, param.row);
                        }}
                    />
                )
            }
        },
        // {field: 'is_super_admin', headerName:  "슈퍼어드민", headerAlign: "center", align: "center", flex: 1,
        //     renderCell: (param: GridRenderCellParams) => {
        //         return (
        //             <Checkbox
        //                 checked={param.row.is_super_admin === undefined ? false : param.row.is_super_admin}
        //                 onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        //                     set_super_admin_info(e.target.checked, param.row);
        //                 }}
        //             />
        //         )
        //     }
        // },
        {field: 'init_passwd', headerName: "패스워드 초기화", headerAlign: "center", align: "center", flex: 1,
            renderCell: (param: GridRenderCellParams) => {
                return (
                    <>
                        {param.row.dtype == "C" ? (
                            <IconButton
                            onClick={() => {
                                reset_user_password(param.row);
                            }}
                            >
                                <Tooltip title="패스워드 초기화">
                                    <KeyIcon
                                        className="data-table-img-button"
                                        color="primary"
                                    />
                                </Tooltip>
                            </IconButton>
                        ) : (
                            <></>
                        )}
                    </>
                )
            }
        },
        {field: 'delete_user', headerName: "사용자 삭제", headerAlign: "center", align: "center", flex: 1,
            renderCell: (param: GridRenderCellParams) => {
                return (
                    <IconButton
                        onClick={() => {
                            delete_user(param.row);
                        }}
                    >
                        <Tooltip title="사용자 삭제">
                            <Delete
                                className="data-table-img-button"
                                color="error"
                              />
                        </Tooltip>
                    </IconButton>
                )
            }
        }
    ];

    useEffect(() => {
        get_user_list();
        return () => {
          setIsLoading(false);
          setUserList([]);
          setRows([]);
        };
    }, []);

    useEffect(() => {
        let temp_list = [];
        if (question === "") {
            setRows(userList);
        } else {
            const filteredList = userList.filter((item: any) => {
                const value = item[selectedQuestionOption];
                return value && typeof value === "string" && value.includes(question);
            });
            setRows(filteredList);
        }
    }, [selectedQuestionOption, userList, question]);

    const handleSelectOptionChange = (event: any) => {
        // setCurrentPage(1);
        setSelectedQuestionOption(event.target.value);
    };

    const get_user_list = async () => {
        const param: any = {
            command: "get_user_list",
            search_conditions: {
                user_id: "",
                user_name: "",
            },
        };

        setIsLoading(true);
        const res = await mainApi.post(param);
        if (res.code === "200") {
            setUserList(res.response.user_list);
            setRows(res.response.user_list);
        } else {
            console.error(res.response.error_msg);
            toastRef.current?.toast("" + res.response.error_msg, "error", 4000, {
                vertical: "top",
                horizontal: "center",
            });
        }
        setIsLoading(false);
    };

    const set_auth_info = async (addAuth: boolean, userInfo: any) => {
        // 수정권한 확인
        if (!props.userState.isAdmin) {
            toastRef.current?.toast("권한부여 권한이 없습니다.", "error", 4000, {
                vertical: "top",
                horizontal: "center",
            });
            return;
        }
        let comfirmText = "해당 사용자를 승인하시겠습니까?";
        if (!addAuth) {
            comfirmText = "해당 사용자의 승인을 해제하시겠습니까?";
        }
    
        if (!window.confirm(comfirmText)) return;
    
        setIsLoading(true);
        const param: any = {
          command: "set_auth_info",
          user_info: {
            pk: userInfo.pk,
            sk: userInfo.sk,
            is_auth: addAuth,
          },
          manager_info: {
            id: props.userState.id,
          },
        };
    
        const res = await mainApi.post(param);
        if (res.code === "200") {
          get_user_list();
        } else {
          console.error(res.response.error_msg);
          toastRef.current?.toast("" + res.response.error_msg, "error", 4000, {
            vertical: "top",
            horizontal: "center",
          });
        }
        setIsLoading(false);
    };

    const set_admin_info = async (addAdmin: boolean, userInfo: any) => {
        // 수정권한 확인
        if (!props.userState.isAdmin) {
            toastRef.current?.toast("권한부여 권한이 없습니다.", "error", 4000, {
                vertical: "top",
                horizontal: "center",
            });
            return;
        }
        let comfirmText = "해당 사용자에게 홍보실 권한을 부여하시겠습니까?";
        if (!addAdmin) {
            comfirmText = "해당 사용자로부터 홍보실 권한을 해제하시겠습니까?";
        }

        if (!window.confirm(comfirmText)) return;

        setIsLoading(true);
        const param: any = {
            command: "set_admin_info",
            user_info: {
                pk: userInfo.pk,
                sk: userInfo.sk,
                is_admin: addAdmin,
            },
            manager_info: {
                id: props.userState.id,
            },
        };

        const res = await mainApi.post(param);
        if (res.code === "200") {
            get_user_list();
        } else {
            console.error(res.response.error_msg);
            toastRef.current?.toast("" + res.response.error_msg, "error", 4000, {
                vertical: "top",
                horizontal: "center",
            });
        }
        setIsLoading(false);
    };

    const set_super_admin_info = async (addAdmin: boolean, userInfo: any) => {
        // 수정권한 확인
        if (!props.userState.isSuperAdmin) {
            toastRef.current?.toast("권한부여 권한이 없습니다.", "error", 4000, {
                vertical: "top",
                horizontal: "center",
            });
            return;
        }
        let comfirmText = "해당 사용자에게 슈퍼어드민 권한을 부여하시겠습니까?";
        if (!addAdmin) {
            comfirmText = "해당 사용자로부터 슈퍼어드민 권한을 해제하시겠습니까?";
        }

        if (!window.confirm(comfirmText)) return;

        setIsLoading(true);
        const param: any = {
            command: "set_super_admin_info",
            user_info: {
                pk: userInfo.pk,
                sk: userInfo.sk,
                is_super_admin: addAdmin,
            },
            manager_info: {
                id: props.userState.id,
            },
        };

        const res = await mainApi.post(param);
        if (res.code === "200") {
            get_user_list();
        } else {
            console.error(res.response.error_msg);
            toastRef.current?.toast("" + res.response.error_msg, "error", 4000, {
                vertical: "top",
                horizontal: "center",
            });
        }
        setIsLoading(false);
    };
    
    const reset_user_password = async (userInfo: any) => {
        if (!window.confirm("해당 사용자의 패스워드를 초기화합니다.")) return;
    
        const param: any = {
          command: "reset_user_password",
          user_info: {
            pk: userInfo.pk,
            sk: userInfo.sk,
          },
          manager_info: {
            id: props.userState.id,
          },
        };
    
        const res = await mainApi.post(param);
        if (res.code === "200") {
          toastRef.current?.toast(res.response.msg, "success", 4000, {
            vertical: "top",
            horizontal: "center",
          });
        } else {
          console.error(res.response.error_msg);
          toastRef.current?.toast("" + res.response.error_msg, "error", 4000, {
            vertical: "top",
            horizontal: "center",
          });
        }
        setIsLoading(false);
    };

    const delete_user = async (userInfo: any) => {
        // 수정권한 확인
        if (!props.userState.isAdmin) {
          toastRef.current?.toast("권한부여 권한이 없습니다.", "error", 4000, {
            vertical: "top",
            horizontal: "center",
          });
          return;
        }
        let comfirmText =
          "해당 사용자를 삭제하시겠습니까?삭제한 데이터는 복구할 수 없습니다.";
        if (!window.confirm(comfirmText)) return;
    
        setIsLoading(true);
        const param: any = {
          command: "delete_user",
          user_info: {
            pk: userInfo.pk,
            sk: userInfo.sk,
            dtype: userInfo.dtype
          },
          manager_info: {
            id: props.userState.id,
          },
        };
    
        const res = await mainApi.post(param);
        if (res.code === "200") {
          get_user_list();
        } else {
          console.error(res.response.error_msg);
          toastRef.current?.toast("" + res.response.error_msg, "error", 4000, {
            vertical: "top",
            horizontal: "center",
          });
        }
        setIsLoading(false);
    };
    
    const openAddUser = () => {
        addUserRef.current.open();
    }

    return (
        <>
            <Box sx={{ p: 0, height: "100%" }}>
                <Stack direction={"column"} spacing={0}>
                    <Box className="my-account-header-root">
                        <Typography
                        variant="h5"
                        gutterBottom
                        className="my-account-header-title"
                        >
                        사용자 관리
                        </Typography>
                    </Box>
                    <Divider />
                    <Box
                        sx={{pt: 4, pl: 4, pr: 4, pb: 4}}
                    >
                        <Paper sx={{ p : 2 }} elevation={5}>
                            <div 
                                style={{ display: "flex", justifyContent: "space-between" }}
                            >
                                <div 
                                    className="search-date-wrap"
                                    style={{ justifyContent: "start" }}
                                >
                                    <select
                                        id="question-option"
                                        value={selectedQuestionOption}
                                        onChange={handleSelectOptionChange}
                                    >
                                        <option value="sk">ID</option>
                                        <option value="name">이름</option>
                                        <option value="company">소속</option>
                                        <option value="dept">부서</option>
                                    </select>
                                    <div 
                                        className="srch-date-input-wrap"
                                    >
                                        <input
                                            style={{ width: "240px" }}
                                            type="text"
                                            placeholder="검색어 입력..."
                                            value={question}
                                            onChange={(e) => {
                                                setQuestion(e.target.value);
                                            }}
                                        />
                                    </div>
                                </div>
                                <Box sx={{ display: "flex" }}>
                                    <Button
                                        variant="contained"
                                        onClick={openAddUser}
                                        startIcon={<AddIcon />}
                                        sx={{ borderRadius: "7px" }}
                                    >
                                        사용자 추가
                                    </Button>
                                </Box>
                            </div>
                            
                            <DataGrid
                                rows={rows}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: {page: 0, pageSize: 10},
                                    },
                                }}
                                getRowId={(row) => row.sk}
                                pageSizeOptions={[10, 15, 20]}
                                sx={{ mt: 2 }}
                            />
                        </Paper>
                    </Box>
                </Stack>
            </Box>
            <PopAddUser 
                ref={addUserRef}
                userState={props.userState}
                refresh={get_user_list}
            />
        </>
    )
}

export default MngUser2;