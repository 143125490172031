import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { Button, CardMedia, Typography } from "@mui/material";
import { userState } from "../../interface/MainInterface";

// 환경 확인
const isLocalhost =
  window.location.hostname === "localhost" ||
  window.location.hostname === "127.0.0.1" ||
  window.location.hostname.startsWith("haap-dev");

interface propsType {
  userState: userState;
}

const MainPage = (props: propsType) => {
  let navigate = useNavigate();
  const fncSignUp = useCallback(() => {
    navigate("/signup");
  }, [navigate]);

  const fncSignIn = useCallback(() => {
    navigate("/signin");
  }, [navigate]);

  useEffect(() => {
    if (props.userState.isAuth) window.location.href = "/myIngList";
    else window.location.href = "/unapproved";
  }, []);
  const imagePath = isLocalhost
    ? "/images/intro-dev-img.png"
    : "/images/intro-img.png";

  const devClass = isLocalhost && "dev";

  return (
    <>
      {!props.userState.isAuth && (
        <>
          <section className={`layout-wrapper login ${devClass}`}>
            <div className="login-header flex justify-content-sb align-items-center">
              <h1>
                <img src="/images/login-logo.png" />
              </h1>
              <div className="login-btn-wrap flex align-items-center">
                <button className="btn-join btn-text" onClick={fncSignUp}>
                  회원가입
                </button>
                <button className="btn-login btn-prim" onClick={fncSignIn}>
                  로그인
                </button>
              </div>
            </div>
            <div className="intro-img">
              <img src={imagePath} />
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default MainPage;
