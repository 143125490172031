import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

import { Box, Paper, Stack, Typography, Button, Avatar } from "@mui/material";

interface propsType {
  pressInfo: any;
}

const CardSearchResult = (props: propsType) => {
  const navigate = useNavigate();
  const getMainText = (inputText: string) => {
    let result: string = inputText;
    if (inputText.split("본문").length > 1) {
      result = inputText.split("본문")[1];
      if (result.substring(0, 2).split(":").length > 1) {
        result = result.split(":")[1];
      }
    }
    return result;
  };
  return (
    <>
      <Box sx={{ position: "relative", zIndex: "100", minWidth: "250px" }}>
        <Button
          sx={{ width: "100%", textAlign: "left", p: 0 }}
          onClick={() => {
            // window.open("/makePress/" + props.pressInfo.sk, "_blank");
            navigate("/makePress/" + props.pressInfo.sk);
          }}
        >
          <Paper
            sx={{
              width: "100%",
              borderRadius: "7px",
              backgroundColor: "rgba(70, 117, 231, 0.07)",
              color: "inherit",
              padding: "16px",
              boxSizing: "border-box",
            }}
            elevation={0}
          >
            <Stack direction={"column"} spacing={1}>
              {/* 아바타, 이름영역 */}
              <Stack direction={"row"} spacing={1}>
                <Avatar
                  sx={{ bgcolor: "#9d97c7", width: "36px", height: "36px" }}
                >
                  {props.pressInfo.user_name[0]}
                </Avatar>
                <Typography
                  sx={{
                    marginTop: "7px !important",
                    fontSize: "14px !important",
                    color: "#7a7a7a !important",
                  }}
                >
                  {props.pressInfo.user_name}
                </Typography>
              </Stack>
              {/* 보도자료 제목 */}
              <Box>
                <Typography
                  sx={{
                    fontWeight: "800 !important",
                    textOverflow: "ellipsis",
                  }}
                >
                  {props.pressInfo.title}
                </Typography>
              </Box>
              {/* 보도자료 내용 */}
              <Box>
                <Typography className="search-result-view">
                  {getMainText(props.pressInfo.result)}
                </Typography>
              </Box>
              {/* 통계정보 */}
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  sx={{
                    fontSize: "11px !important",
                    color: "#00000080 !important",
                  }}
                >{`Created : ${dayjs(props.pressInfo.create_time).format(
                  "YYYY-MM-DD"
                )}`}</Typography>
                <Typography
                  sx={{
                    fontSize: "11px !important",
                    color: "#00000080 !important",
                  }}
                >
                  {`History : ${props.pressInfo.history_cnt}`}
                </Typography>
              </Box>
            </Stack>
          </Paper>
        </Button>
      </Box>
    </>
  );
};

export default CardSearchResult;
