import {
  useCallback,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";

import {
  Box,
  Stack,
  Tab,
  Tabs,
  Modal,
  IconButton,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { userState } from "../../interface/MainInterface";
import MyTheme from "./MyTheme";
import CommonTheme from "./CommonTheme";

interface propsType {
  userState: userState;
  selectTheme: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "90%",
  bgcolor: "background.paper",
  border: "0px solid #000",
  borderRadius: "1rem",
  boxShadow: 24,
  p: 3,
};

const PopThemeSelect = (props: propsType, ref: any) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState("common");
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    return () => {
      setOpenModal(false);
      setSelectedTab("common");
    };
  }, []);

  const open = useCallback(() => {
    setOpenModal(true);
  }, []);

  const close = () => {
    setOpenModal(false);
  };

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    open,
  }));

  return (
    <>
      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack direction={"column"} spacing={2}>
            <Box sx={{ width: "100%" }}>
              <Stack
                direction="row"
                spacing={2}
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Tabs
                  value={selectedTab}
                  onChange={handleChange}
                  aria-label="wrapped label tabs example"
                >
                  <Tab value="common" label="공통 테마" />
                  <Tab value="my" label="나의 테마" />
                </Tabs>
                <Tooltip title="창닫기">
                  <IconButton aria-label="close" onClick={close}>
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Box>
            {selectedTab === "common" && (
              <CommonTheme
                userState={props.userState}
                startMode={"select"}
                selectTheme={(theme: any) => {
                  props.selectTheme(theme);
                  close();
                }}
                height="calc(80vh - 68px)"
              />
            )}
            {selectedTab === "my" && (
              <MyTheme
                userState={props.userState}
                startMode={"select"}
                selectTheme={(theme: any) => {
                  props.selectTheme(theme);
                  close();
                }}
                height="calc(80vh - 68px)"
              />
            )}
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default forwardRef(PopThemeSelect);
