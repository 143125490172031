// 환경 확인
const isLocalhost =
  window.location.hostname === "localhost" ||
  window.location.hostname === "127.0.0.1" ||
  window.location.hostname.startsWith("haap-dev");

// 개발 환경 설정
const devConfig = {
  Auth: {
    userPoolId: "ap-northeast-2_1FgM5Qp9A",
    userPoolWebClientId: "3q07cpbc9rhpl1i6tgdbchqvto",
    identityPoolId: "ap-northeast-2:d3e17199-21d8-4b85-b537-95921df0c389",
  },
  aws_cloud_logic_custom: [
    {
      name: "MainApi",
      endpoint:
        "https://prrb507od3.execute-api.ap-northeast-2.amazonaws.com/Poc/",
    },
    {
      name: "UnAuth",
      endpoint:
        "https://prrb507od3.execute-api.ap-northeast-2.amazonaws.com/Poc/",
    },
  ],
};

// 운영 환경 설정
const prodConfig = {
  Auth: {
    userPoolId: "ap-northeast-2_JTsVsD2fs",
    userPoolWebClientId: "7nr2b9fnnulipd5odsis5bh200",
    identityPoolId: "ap-northeast-2:56cc38f7-e986-4707-aef8-74a0b3e2798b",
  },
  aws_cloud_logic_custom: [
    {
      name: "MainApi",
      endpoint:
        "https://cf8cob3ux7.execute-api.ap-northeast-2.amazonaws.com/Prod/",
    },
    {
      name: "UnAuth",
      endpoint:
        "https://cf8cob3ux7.execute-api.ap-northeast-2.amazonaws.com/Prod/",
    },
  ],
};

// 환경에 따라 설정 선택
const config = isLocalhost ? devConfig : prodConfig;

// 공통 설정
const awsmobile = {
  aws_project_region: "ap-northeast-2",
  Auth: {
    region: "ap-northeast-2",
    ...config.Auth,
  },
  aws_cloud_logic_custom: config.aws_cloud_logic_custom.map((api) => ({
    ...api,
    region: "ap-northeast-2",
  })),
};

export default awsmobile;
