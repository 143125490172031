import { useCallback, useEffect, forwardRef, useImperativeHandle, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";

import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import LogoutIcon from "@mui/icons-material/Logout";

import Drawer from "@mui/material/Drawer";
import CardMedia from "@mui/material/CardMedia";

import { userState } from "../interface/MainInterface";
import LeftMenu from "./LeftMenu";
import { appConfig } from "../config/Config";

interface propsType {
  userState: userState;
  open: boolean;
  winSize: string;
  selectedMenu: string;
  headerLarge: boolean;
  drawerWidth: number;
  largeDrawerWidth: number;
  handleDrawer: any;
  menuOver: any;
  menuOut: any;
}

const Header = (props: propsType, ref: any) => {
  let navigate = useNavigate();

  const LeftMenuRef: any = useRef();

  // 헤더 메뉴 버튼 클릭시 좌측 메뉴 활성/비활성화
  const handleDrawer = () => {
    props.handleDrawer();
  };

  useEffect(() => {
    return () => {};
  }, []);

  // 메뉴 클릭시 페이지 이동
  const move = useCallback(
    (loc: string) => {
      if (props.winSize === "xs") {
        // 모바일 모드일때는 메뉴 선택시 좌측메뉴를 최소화
        props.handleDrawer();
      }
      // 페이지 이동
      if (loc === "/makePress") navigate("/myIngList"); // 작성중인 보도자료를 먼저 선택하도록
      else navigate(loc);
    },
    [navigate, props]
  );

  // 로그아웃
  const signOut = async () => {
    if (!window.confirm("로그아웃 하시겠습니까?")) return;
    window.localStorage.removeItem(appConfig.sessionName);
    await Auth.signOut();
    window.location.href = "/hartlogin";
  };

  const getLeftCount = () => {
    LeftMenuRef.current.getLeftCount();
  };

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    getLeftCount,
  }));

  return (
    <Box sx={{ flexGrow: 1, position: "sticky", top: "0", zIndex: "700" }}>
      {/* 상단 고정메뉴 Component */}
      <AppBar position="fixed" sx={{ backgroundColor: "#f8f9fa", boxShadow: "0 5px 15px rgba(0, 0, 0, 0.1)" }}>
        {/* 좌측 메뉴 Drawer */}
        <Drawer
          sx={{
            width: props.drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: props.drawerWidth,
              boxSizing: "border-box",
              overflowY: "auto",
              overflowX: "hidden",
              backgroundColor: "#2E3341",
              color: "#ffffff",
              transition: "width 0.5s",
            },
          }}
          anchor="left"
          open={props.open}
          variant={"persistent"}
          onMouseOver={() => {
            if (props.winSize === "xs") {
              props.menuOver();
            } else {
              if (!props.headerLarge) props.menuOver();
            }
          }}
          onMouseOut={() => {
            if (props.winSize === "xs") {
              props.menuOut();
            } else {
              if (!props.headerLarge) props.menuOut();
            }
          }}
          onClose={() => {
            props.handleDrawer();
          }}
          className="main-sidebar sidebar-dark-primary"
        >
          <LeftMenu
            ref={LeftMenuRef}
            userState={props.userState}
            drawerWidth={props.drawerWidth}
            largeDrawerWidth={props.largeDrawerWidth}
            selectedMenu={props.selectedMenu}
            move={move}
          />
        </Drawer>
        {/* 좌측 메뉴 Drawer */}
        {/* 상단 툴바 */}
        <Toolbar
          sx={{
            ml: `${props.drawerWidth}px`,
            minHeight: "44px !important",
            display: "flex",
            justifyContent: "space-between",
            pr: "0px !important",
            transition: "margin 0.5s",
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawer}
            edge="start"
            sx={{ mr: 2, color: "#212121", display: "flex" }}
          >
            <CardMedia
              component="img"
              image={"/images/side-toggle-button.png"}
              sx={{ width: "16px", height: "16px", objectFit: "contain" }}
            />
          </IconButton>
          <Box sx={{ pr: 2 }}>
            <Button
              variant="text"
              onClick={signOut}
              startIcon={<LogoutIcon />}
              sx={{ color: "#9a9a9a", fontSize: "14px", fontWeight: "700" }}
            >
              로그아웃
            </Button>
          </Box>
        </Toolbar>
        {/* 상단 툴바 */}
      </AppBar>
    </Box>
  );
};

export default forwardRef(Header);
