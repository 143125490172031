import { useCallback, useEffect, useRef, useState, forwardRef, useImperativeHandle } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import { TextField, Button, TableContainer, Table, TableBody, TableRow, TableCell, Paper, Tooltip } from "@mui/material";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import CloseIcon from "@mui/icons-material/Close";
import Toast from "../../utils/Toast";
import LoadingCircle from "../../utils/LoadingCircle";
import { userState } from "../../interface/MainInterface";
import { HttpMainApi } from "../../interface/main-api";

interface propsType {
  callback: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  height: "700px",
  bgcolor: "background.paper",
  border: "0px solid #000",
  borderRadius: "1rem",
  boxShadow: 24,
  p: 3,
};

const mainApi = new HttpMainApi();

const PopSearchAdmin = (props: propsType, ref: any) => {
  const toastRef: any = useRef();
  const nameRef: any = useRef();

  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [adminList, setAdminList] = useState<any>([]);

  useEffect(() => {
    return () => {
      setOpenModal(false);
      setIsLoading(false);
      setName("");
      setAdminList([]);
    };
  }, []);

  const open = useCallback(() => {
    get_admin_list();
    setOpenModal(true);
  }, []);

  const close = () => {
    setName("");
    setOpenModal(false);
  };

  const get_admin_list = async () => {
    const param: any = {
      command: "get_admin_list",
      name: name,
    };

    const res = await mainApi.post(param);
    console.log("admin_list : ", res);
    if (res.code === "200") {
      setAdminList(res.response.admin_list);
    } else {
      console.error(res.response.error_msg);
      toastRef.current?.toast("" + res.response.error_msg, "error", 4000, { vertical: "top", horizontal: "center" });
    }
  };

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    open,
  }));

  return (
    <>
      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack direction="column" spacing={2} sx={{ height: "100%" }}>
            <Stack direction="row" spacing={2} sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography gutterBottom sx={{ lineHeight: "40px", fontWeight: "800 !important" }}>
                담당자 찾기
              </Typography>
              <Tooltip title="창닫기">
                <IconButton aria-label="close" onClick={close}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Stack>
            <Stack direction={"row"} spacing={2}>
              <TextField
                inputRef={nameRef}
                id="txtComment"
                value={name}
                label="담당자명"
                size="small"
                autoFocus
                placeholder="검색할 담당자 명을 입력하세요."
                type="search"
                autoComplete="off"
                inputProps={{ enterKeyHint: "Enter" }}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !e.nativeEvent.isComposing) get_admin_list();
                }}
              />
              <Tooltip title="담당자 검색">
                <Button variant="contained" onClick={get_admin_list} sx={{ borderRadius: "7px" }}>
                  <PersonSearchIcon />
                </Button>
              </Tooltip>
            </Stack>
            <Box
              sx={{
                height: "517px",
                p: 1,
                overflowY: "auto",
              }}
            >
              <TableContainer component={Paper} elevation={5}>
                <Table className="data-table">
                  <TableBody>
                    {adminList.map((adminInfo: any, index: number) => (
                      <TableRow key={adminInfo.sk} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                        <TableCell>
                          <Tooltip title="선택">
                            <IconButton
                              onClick={async () => {
                                await props.callback({ command: "set_toss_user", userId: adminInfo.sk, userName: adminInfo.name });
                                close();
                              }}
                            >
                              <HowToRegIcon className="data-table-img-button" />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {adminInfo.name}
                        </TableCell>
                        <TableCell scope="row">{adminInfo.sk}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <Stack direction="row" spacing={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button variant="outlined" onClick={close} disabled={isLoading}>
                닫기
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
      <Toast ref={toastRef} />
      <LoadingCircle loading={isLoading} />
    </>
  );
};

export default forwardRef(PopSearchAdmin);
