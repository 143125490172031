import {
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";

import { saveAs } from "file-saver";

import {
  Box,
  Button,
  Stack,
  Modal,
  Typography,
  IconButton,
  Tooltip,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import Toast from "../Toast";

interface propsType {
  files: any;
  canDelete: boolean;
  callback: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "600px",
  height: "400px",
  bgcolor: "background.paper",
  border: "0px solid #000",
  borderRadius: "1rem",
  boxShadow: 24,
  p: 3,
};

const AttachFileViewer = (props: propsType, ref: any) => {
  const toastRef: any = useRef();

  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    return () => {
      setOpenModal(false);
    };
  }, []);

  const open = () => {
    setOpenModal(true);
  };

  const close = () => {
    setOpenModal(false);
  };

  const AttachButton = (param: any) => {
    const { fileInfo, index } = param;

    return (
      <Stack
        direction={"row"}
        spacing={2}
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Button
          key={`file-${index}`}
          variant="text"
          sx={{
            justifyContent: "flex-start",
            textAlign: "left",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            display: "block",
          }}
          size="small"
          fullWidth
          onClick={() => {
            fncDownloadAttach(fileInfo);
          }}
        >
          {fileInfo.FILE_NAME}
        </Button>
        {props.canDelete ? (
          <Button
            sx={{ width: "30px", color: "#ff000070" }}
            onClick={() => {
              if (window.confirm("첨부파일을 삭제합니다.")) {
                const callbackParam: any = {
                  command: "delete",
                  fileInfo: fileInfo,
                };
                props.callback(callbackParam);
              }
            }}
            size="small"
          >
            삭제
          </Button>
        ) : (
          <></>
        )}
      </Stack>
    );
  };

  const fncDownloadAttach = async (attachInfo: any) => {
    await fetch(attachInfo.FILE_PATH, { method: "GET" })
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        saveAs(blob, `${attachInfo.FILE_NAME}`);
      })
      .catch((err) => {
        console.error("err : ", err);
      });
  };

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    open,
  }));

  return (
    <>
      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack direction="column" spacing={2}>
            <Stack
              direction="row"
              spacing={2}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Typography
                gutterBottom
                sx={{ lineHeight: "40px", fontWeight: "800 !important" }}
              >
                첨부파일 다운로드
              </Typography>
              <Tooltip title="창닫기">
                <IconButton aria-label="close" onClick={close}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Stack>
            <Box
              sx={{
                height: "342px",
                overflowY: "auto",
              }}
            >
              <Stack direction={"column"} spacing={1}>
                {props.files?.map((fileInfo: any, index: number) => (
                  <AttachButton
                    key={`attach-file-${index}`}
                    fileInfo={fileInfo}
                    index={index}
                  />
                ))}
              </Stack>
            </Box>
          </Stack>
        </Box>
      </Modal>
      <Toast ref={toastRef} />
    </>
  );
};

export default forwardRef(AttachFileViewer);
