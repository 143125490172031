import { useState, useRef, useEffect } from "react";
import { Auth } from "aws-amplify";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Typography } from "@mui/material";

import Toast from "../../utils/Toast";
import LoadingCircle from "../../utils/LoadingCircle";
import { HttpUnAuthApi } from "../../interface/unauth-api";
import { userState } from "../../interface/MainInterface";

interface propsType {
  userState: userState;
}

const unauthApi = new HttpUnAuthApi();

const SignUp = (props: propsType) => {
  const toastRef: any = useRef();
  const [isLoading, setIsLoading] = useState(false);

  const [userEmail, setUserEmail] = useState("");
  const [userPwd, setUserPwd] = useState("");
  const [userPwdConfirm, setUserPwdConfirm] = useState("");
  const [userName, setUserName] = useState("");
  const [userCompany, setUserCompany] = useState("");
  const [userDept, setUserDept] = useState("");
  const [needConfirm, setNeedConfirm] = useState(false);
  const [verifyCode, setVerifyCode] = useState("");
  const [realEmail, setRealEmail] = useState("");
  const [realPwd, setRealPwd] = useState("");

  useEffect(() => {
    return () => {
      setIsLoading(false);
      setUserEmail("");
      setUserPwd("");
      setUserPwdConfirm("");
      setUserName("");
      setUserCompany("");
      setUserDept("");
      setNeedConfirm(false);
      setVerifyCode("");
      setRealEmail("");
      setRealPwd("");
    };
  }, []);

  const fncSignUp = async () => {
    if (userEmail === "" || userEmail === undefined) {
      toastRef.current?.toast("이메일을 입력하세요.", "error", 4000, { vertical: "top", horizontal: "center" });
      return;
    }
    if (userPwd === "" || userPwd === undefined) {
      toastRef.current?.toast("패스워드를 입력하세요.", "error", 4000, { vertical: "top", horizontal: "center" });
      return;
    }
    if (userPwd !== userPwdConfirm) {
      toastRef.current?.toast("패스워드가 일치하지 않습니다.", "error", 4000, { vertical: "top", horizontal: "center" });
      return;
    }
    if (userName === "" || userName === undefined) {
      toastRef.current?.toast("이름을 입력하세요.", "error", 4000, { vertical: "top", horizontal: "center" });
      return;
    }

    setRealEmail(userEmail);
    setRealPwd(userPwd);

    setIsLoading(true);
    const param: any = {
      email: userEmail,
      password: userPwd,
      name: userName,
      company: userCompany,
      dept: userDept,
      dtype: "C", // 사번로그인 사용자: N, cognito 사용자: C
    };

    const res = await unauthApi.create_user(param);
    if (res.code === "200") {
      toastRef.current?.toast("입력하신 이메일로 발송된 인증코드를 입력하세요", "success", 4000, { vertical: "top", horizontal: "center" });
      setNeedConfirm(true);
    } else {
      if (res.code === "201" || res.code === "202") alert(`${res.response.error_msg}`);
      else alert(`알수 없는 에러가 발생했습니다.\n- CODE : ${res.code}\n- message : ${res.response.error_msg}`);
    }
    setIsLoading(false);
  };

  const fncConfirm = async () => {
    if (verifyCode === "" || verifyCode === undefined) {
      toastRef.current?.toast("인증번호를 입력하세요.", "error", 4000, { vertical: "top", horizontal: "center" });
      return;
    }

    try {
      await Auth.confirmSignUp(realEmail, verifyCode);
      toastRef.current?.toast("인증에 성공했습니다.", "success", 4000, { vertical: "top", horizontal: "center" });
      autoSignIn();
      // setNeedConfirm(false);
    } catch (error: any) {
      toastRef.current?.toast("인증번호가 맞지않습니다.", "error", 4000, { vertical: "top", horizontal: "center" });
    }
  };

  const handleVerifyCodeEnter = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") fncConfirm();
  };

  const autoSignIn = async () => {
    if (realEmail === "" || realEmail === undefined) {
      toastRef.current?.toast("이메일을 입력하세요.", "error", 4000, { vertical: "top", horizontal: "center" });
      return;
    }
    if (realPwd === "" || realPwd === undefined) {
      toastRef.current?.toast("패스워드를 입력하세요.", "error", 4000, { vertical: "top", horizontal: "center" });
      return;
    }

    try {
      await Auth.signIn(realEmail, realPwd);
      window.location.href = "/";
    } catch (error: any) {}
  };

  const fncResendCode = async () => {
    try {
      await Auth.resendSignUp(realEmail);
      toastRef.current?.toast("인증번호를 발송했습니다.", "success", 4000, { vertical: "top", horizontal: "center" });
    } catch (err) {
      console.error("error resending code: ", err);
    }
  };

  return (
    <>
      <Box sx={{ p: 0.5, display: "flex", height: "calc(100vh)", bgcolor: "#F2F5FD" }}>
        <Box component={"div"} sx={{ width: "100%", maxWidth: "56rem", margin: "auto" }}>
          <Paper elevation={4} sx={{ pt: 10, pb: 10, pl: 20, pr: 20, borderRadius: "21px" }}>
            {needConfirm ? (
              <Stack spacing={8} direction="column">
                <Typography sx={{ display: "flex", justifyContent: "center", fontWeight: "700", fontSize: "28px", lineHeight: "33.41px" }}>
                  {"이메일인증"}
                </Typography>
                <Stack direction={"column"} spacing={0.5}>
                  <Typography sx={{ fontWeight: "500", fontSize: "12px", lineHeight: "normal", color: "#2a2a2a" }}>{"인증번호"}</Typography>
                  <TextField
                    id="txtVerifyCode"
                    value={verifyCode}
                    placeholder="인증번호를 입력해주세요."
                    variant="outlined"
                    autoFocus
                    fullWidth
                    required
                    type="search"
                    autoComplete="off"
                    inputProps={{ enterKeyHint: "Enter", style: { padding: "12px 16px 10px 16px" } }}
                    onChange={(e) => {
                      setVerifyCode(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      handleVerifyCodeEnter(e);
                    }}
                  />
                </Stack>

                <Button variant="contained" onClick={fncConfirm}>
                  이메일 인증하기
                </Button>
                <Button variant="text" onClick={fncResendCode}>
                  인증번호 재발송
                </Button>
              </Stack>
            ) : (
              <Stack spacing={8} direction="column">
                <Typography sx={{ display: "flex", justifyContent: "center", fontWeight: "700", fontSize: "28px", lineHeight: "33.41px" }}>
                  {"회원가입"}
                </Typography>
                <Stack direction={"column"} spacing={2}>
                  <Stack direction={"column"} spacing={0.5}>
                    <Typography sx={{ fontWeight: "500", fontSize: "12px", lineHeight: "normal", color: "#2a2a2a" }}>
                      {"이메일주소"}
                    </Typography>
                    <TextField
                      id="signUpEmail"
                      value={userEmail}
                      placeholder="이메일을 입력해주세요."
                      variant="outlined"
                      autoFocus
                      fullWidth
                      required
                      type="search"
                      autoComplete="off"
                      inputProps={{ enterKeyHint: "Enter", style: { padding: "12px 16px 10px 16px" } }}
                      onChange={(e) => {
                        setUserEmail(e.target.value);
                      }}
                    />
                  </Stack>
                  <Stack direction={"column"} spacing={0.5}>
                    <Typography sx={{ fontWeight: "500", fontSize: "12px", lineHeight: "normal", color: "#2a2a2a" }}>
                      {"비밀번호"}
                    </Typography>
                    <TextField
                      id="signUpPwd"
                      value={userPwd}
                      placeholder="비밀번호를 입력해주세요."
                      variant="outlined"
                      fullWidth
                      required
                      type="password"
                      autoComplete="current-password"
                      inputProps={{ autoComplete: "new-password", enterKeyHint: "Enter", style: { padding: "12px 16px 10px 16px" } }}
                      onChange={(e) => {
                        setUserPwd(e.target.value);
                      }}
                    />
                  </Stack>
                  <Stack direction={"column"} spacing={0.5}>
                    <Typography sx={{ fontWeight: "500", fontSize: "12px", lineHeight: "normal", color: "#2a2a2a" }}>
                      {"비밀번호 확인"}
                    </Typography>
                    <TextField
                      id="signUpPwdComfirm"
                      value={userPwdConfirm}
                      placeholder="비밀번호 확인을 위해 다시 입력해주세요."
                      variant="outlined"
                      fullWidth
                      required
                      type="password"
                      autoComplete="current-password"
                      inputProps={{ autoComplete: "new-password", enterKeyHint: "Enter", style: { padding: "12px 16px 10px 16px" } }}
                      onChange={(e) => {
                        setUserPwdConfirm(e.target.value);
                      }}
                    />
                  </Stack>
                  <Stack direction={"column"} spacing={0.5}>
                    <Typography sx={{ fontWeight: "500", fontSize: "12px", lineHeight: "normal", color: "#2a2a2a" }}>
                      {"사용자 이름"}
                    </Typography>
                    <TextField
                      id="signUpName"
                      value={userName}
                      placeholder="사용자 이름을 입력해주세요."
                      variant="outlined"
                      fullWidth
                      required
                      type="search"
                      autoComplete="off"
                      inputProps={{ enterKeyHint: "Enter", style: { padding: "12px 16px 10px 16px" } }}
                      onChange={(e) => {
                        setUserName(e.target.value);
                      }}
                    />
                  </Stack>
                </Stack>
                <Button variant="contained" onClick={fncSignUp}>
                  회원가입하기
                </Button>
              </Stack>
            )}
          </Paper>
        </Box>
      </Box>
      <Toast ref={toastRef} />
      <LoadingCircle loading={isLoading} />
    </>
  );
};

export default SignUp;
