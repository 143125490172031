import { useCallback, useEffect, useRef, useState, forwardRef, useImperativeHandle } from "react";

import {
  Box,
  Stack,
  Modal,
  Typography,
  IconButton,
  TextField,
  Button,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Tooltip,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import DoneIcon from "@mui/icons-material/Done";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

import { userState } from "../../interface/MainInterface";
import { HttpMainApi } from "../../interface/main-api";

import Toast from "../../utils/Toast";
import LoadingCircle from "../../utils/LoadingCircle";

interface propsType {
  userState: userState;
  callback: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  height: "700px",
  bgcolor: "background.paper",
  border: "0px solid #000",
  borderRadius: "1rem",
  boxShadow: 24,
  p: 3,
};

const mainApi = new HttpMainApi();

const PopCategory = (props: propsType, ref: any) => {
  const toastRef: any = useRef();
  const categoryNameRef: any = useRef();

  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [themeCategoryList, setThemeCategoryList] = useState<any>([]);

  useEffect(() => {
    return () => {
      setOpenModal(false);
      setIsLoading(false);
      setCategoryName("");
      setThemeCategoryList([]);
    };
  }, []);

  const open = useCallback(() => {
    get_theme_category_list();
    setOpenModal(true);
  }, []);

  const close = () => {
    setCategoryName("");
    setOpenModal(false);
  };

  const get_theme_category_list = async () => {
    const param: any = {
      command: "get_theme_category_list",
    };
    setIsLoading(true);
    const res = await mainApi.post(param);
    if (res.code === "200") {
      setThemeCategoryList(res.response.theme_category_list);
    } else {
      console.error(res.response.error_msg);
      toastRef.current?.toast("" + res.response.error_msg, "error", 4000, { vertical: "top", horizontal: "center" });
    }
    setIsLoading(false);
  };

  const put_theme_category = async () => {
    if (categoryName === "") {
      toastRef.current?.toast("저장할 카테고리명을 입력하세요.", "error", 4000, { vertical: "top", horizontal: "center" });
      categoryNameRef.current.focus();
      return;
    }

    if (!window.confirm(`공통테마 카테고리에 ${categoryName}을 추가하시겠습니까?`)) return;

    const param: any = {
      command: "put_theme_category",
      name: categoryName,
      user_id: props.userState.id,
      user_name: props.userState.name,
    };

    setIsLoading(true);

    const res = await mainApi.post(param);
    if (res.code === "200") {
      setCategoryName("");
      get_theme_category_list();
    } else {
      console.error(res.response.error_msg);
      toastRef.current?.toast("" + res.response.error_msg, "error", 4000, { vertical: "top", horizontal: "center" });
    }
    setIsLoading(false);
  };

  const delete_theme_category = async (pk: string, sk: string) => {
    if (!props.userState.isAdmin) {
      toastRef.current?.toast("권한이 없습니다.", "error", 4000, { vertical: "top", horizontal: "center" });
      return;
    }

    if (!window.confirm(`공통테마 카테고리에서 ${sk}을 삭제하시겠습니까?\n삭제한 데이터는 복구할 수 없습니다.`)) return;

    const param: any = {
      command: "delete_theme_category",
      pk: pk,
      sk: sk,
    };
    setIsLoading(true);
    const res = await mainApi.post(param);
    if (res.code === "200") {
      setCategoryName("");
      get_theme_category_list();
    } else {
      console.error(res.response.error_msg);
      toastRef.current?.toast("" + res.response.error_msg, "error", 4000, { vertical: "top", horizontal: "center" });
    }
    setIsLoading(false);
  };

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    open,
  }));

  return (
    <>
      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack direction="column" spacing={2} sx={{ height: "100%" }}>
            <Stack direction="row" spacing={2} sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography gutterBottom sx={{ lineHeight: "40px", fontWeight: "800 !important" }}>
                카테고리 선택
              </Typography>
              <Tooltip title="창닫기">
                <IconButton aria-label="close" onClick={close}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Stack>
            <Stack direction={"row"} spacing={2}>
              <TextField
                inputRef={categoryNameRef}
                id="txtCategoryName"
                value={categoryName}
                label="카테고리 추가"
                size="small"
                autoFocus
                placeholder="추가할 카테고리명을 입력하세요."
                onChange={(e) => {
                  setCategoryName(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !e.nativeEvent.isComposing) put_theme_category();
                }}
                type="search"
                autoComplete="off"
                inputProps={{ enterKeyHint: "Enter" }}
              />
              <Tooltip title="카테고리 추가">
                <Button variant="contained" onClick={put_theme_category} sx={{ borderRadius: "7px" }}>
                  <PlaylistAddIcon />
                </Button>
              </Tooltip>
            </Stack>
            <Box
              sx={{
                height: "517px",
                p: 1,
                overflowY: "auto",
              }}
            >
              <TableContainer component={Paper} elevation={5}>
                <Table className="data-table">
                  <TableBody>
                    {themeCategoryList.map((categoryInfo: any, index: number) => (
                      <TableRow key={categoryInfo.sk} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                        <TableCell width={"20px"}>
                          <Tooltip title="카테고리 삭제">
                            <IconButton
                              onClick={async () => {
                                delete_theme_category(categoryInfo.pk, categoryInfo.sk);
                              }}
                            >
                              <RemoveCircleOutlineIcon className="data-table-img-button color-red" />
                            </IconButton>
                          </Tooltip>
                        </TableCell>

                        <TableCell component="th" scope="row">
                          {categoryInfo.sk}
                        </TableCell>
                        <TableCell align="right">
                          <Tooltip title="카테고리 선택">
                            <IconButton
                              onClick={async () => {
                                await props.callback({ command: "set_theme_category", category: categoryInfo.sk });
                                close();
                              }}
                            >
                              <DoneIcon className="data-table-img-button" />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <Stack direction="row" spacing={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button variant="outlined" onClick={close} disabled={isLoading}>
                닫기
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
      <Toast ref={toastRef} />
      <LoadingCircle loading={isLoading} />
    </>
  );
};

export default forwardRef(PopCategory);
