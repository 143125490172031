import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

import {
  Box,
  Stack,
  Typography,
  TextField,
  Grid,
  IconButton,
  Pagination,
} from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";

import { userState } from "../../interface/MainInterface";

import LoadingCircle from "../../utils/LoadingCircle";
import Toast from "../../utils/Toast";

import { HttpCustomApi } from "../../interface/custom-api";
import { HttpMainApi } from "../../interface/main-api";
import { useNavigate } from "react-router-dom";
import "./SearchFilterPress.css";
const mainApi = new HttpMainApi();

interface propsType {
  userState: userState;
  searchResult: any;
  setSearchResult: any;
}

let _maxCount: number = 30;
const PAGING_SIZE = 5;
const NOTICE_PER_PAGE = 10;
const NOTICE_CNT = PAGING_SIZE * NOTICE_PER_PAGE;
const vectoCoreApi = new HttpCustomApi(process.env.REACT_APP_VECTOR_CORE);

const SearchFilterPress = (props: propsType) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams: any = queryString.parse(location.search);

  const toastRef: any = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [question, setQuestion] = useState("");
  const [result, setResult] = useState<any>(props.searchResult);

  const [currentPage, setCurrentPage] = useState(1);
  const [currentRange, setCurrentRange] = useState(1);
  const [selectedOption, setSelectedOption] = useState("all");
  const [hoveredRowIndex, setHoveredRowIndex] = useState(-1);

  useEffect(() => {
    if (queryParams.q !== undefined) {
      setQuestion(queryParams.q);
      if (result.length === 0) fncQuestion(queryParams.q);
    } else {
      props.setSearchResult([]);
      setResult([]);
    }
    return () => {
      setIsLoading(false);
      setQuestion("");
    };
  }, []);

  const fncQuestion = async (searchText: string) => {
    setResult([]);

    if (searchText === "") {
      alert("검색어를 입력하세요.");
      return;
    }
    setIsLoading(true);
    const param: any = {
      command: "get_search_press_list",
      sk: searchText,
      searchOption: selectedOption,
    };

    const res = await mainApi.post(param);

    if (res.code === "200") {
      setResult(res.response.result);
    } else {
      toastRef.current?.toast(
        "Error fncQuestion : " + res.response.error_msg,
        "error",
        3000,
        { vertical: "top", horizontal: "center" }
      );
    }

    setIsLoading(false);
  };

  // 페이지 변경
  const NOTICE_PER_PAGE = 10; // 페이지당 게시물 수

  const handleSelectChange = (event: any) => {
    setSelectedOption(event.target.value);
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setCurrentPage(page);
  };

  return (
    <>
      <div className="container flex">
        <div className="list-table">
          <div className="title-wrap">
            <h2>보도자료 검색</h2>
            <div className="search-wrap">
              <select
                id="option"
                value={selectedOption}
                onChange={handleSelectChange}
              >
                <option value="all">내용</option>
                <option value="opt1">제목</option>
                <option value="opt2">작성자</option>
                <option value="opt3">카테고리</option>
              </select>
              <div className="srch-input-wrap">
                <input
                  type="text"
                  placeholder="검색어 입력..."
                  value={question}
                  onChange={(e) => {
                    setQuestion(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && !e.nativeEvent.isComposing)
                      fncQuestion(question);
                  }}
                />
                <button
                  className="btn-search"
                  onClick={() => {
                    fncQuestion(question);
                  }}
                />
              </div>
            </div>
          </div>

          <div className="table-wrap">
            <table>
              <colgroup>
                <col width="120px" />
                <col width="120px" />
                <col width="180px" />
                <col className="dynamic-width" />
                <col width="120px" />
              </colgroup>
              <thead>
                <tr>
                  <th>번호</th>
                  <th>카테고리</th>
                  <th>기사제목</th>
                  <th>내용</th>
                  <th>작성자</th>
                </tr>
              </thead>
              <tbody>
                {result
                  ?.slice(
                    (currentPage - 1) * NOTICE_PER_PAGE,
                    currentPage * NOTICE_PER_PAGE
                  )
                  ?.map((item: any, index: number) => (
                    <tr
                      className={`active ${
                        hoveredRowIndex === index ? "hoveredRow" : ""
                      }`}
                      key={index}
                      onMouseEnter={() => setHoveredRowIndex(index)} // 마우스 올릴 때
                      onMouseLeave={() => setHoveredRowIndex(-1)} // 마우스 떠날 때
                      onClick={() => {
                        navigate("/makePress/" + item.sk);
                      }}
                    >
                      <td className="text-truncate100">{item.sk}</td>
                      <td className="text-truncate100">{item.category}</td>
                      <td className="text-truncate200">{item.title}</td>
                      <td className="text-truncate400">{item.result}</td>
                      <td className="text-truncate100">{item.user_name}</td>
                    </tr>
                  )) ?? (
                  <tr>
                    <td>데이터가 없습니다.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="pagination">
            <Stack spacing={2} mt={4}>
              <Pagination
                count={Math.ceil(result.length / NOTICE_PER_PAGE)} // 전체 페이지 수 계산
                page={currentPage}
                siblingCount={3}
                onChange={handlePageChange}
                shape="rounded"
              />
            </Stack>
          </div>
        </div>
      </div>

      <Toast ref={toastRef} />
      <LoadingCircle loading={isLoading} />
    </>
  );
};

export default SearchFilterPress;
