import {
  useCallback,
  useEffect,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";

import {
  Box,
  Stack,
  Modal,
  Typography,
  Button,
  TextField,
  IconButton,
  Tooltip,
  Autocomplete,
} from "@mui/material";

import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import SaveIcon from "@mui/icons-material/Save";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";

import { HttpMainApi } from "../../interface/main-api";
import { userState } from "../../interface/MainInterface";

import { CommonUtils } from "../../utils/common_utils";

import LoadingCircle from "../../utils/LoadingCircle";
import Toast from "../../utils/Toast";

import PopCategory from "./PopCategory";

interface propsType {
  userState: userState;
  type: string;
  refresh: any;
  themeCategoryList: string[];
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "500px",
  height: "80%",
  bgcolor: "background.paper",
  border: "0px solid #000",
  borderRadius: "1rem",
  boxShadow: 24,
  p: 3,
};

const utils = new CommonUtils();
const mainApi = new HttpMainApi();

const PopThemeMake = (props: propsType, ref: any) => {
  const toastRef: any = useRef();
  const titleRef: any = useRef();
  const formNameRef: any = useRef();
  const categoryRef: any = useRef();
  const categorySelectRef: any = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const [title, setTitle] = useState("");
  const [formName, setFormName] = useState("");
  const [formList, setFormList] = useState<any>([]);
  const [formData, setFormData] = useState<any>({});
  const [category, setCategory] = useState<string | null>("");
  const [ableRequest, setAbleRequest] = useState(false);

  useEffect(() => {
    return () => {
      setIsLoading(false);
      setOpenModal(false);
      setTitle("");
      setFormName("");
      setFormList([]);
      setFormData({});
      setAbleRequest(false);
      setCategory("");
    };
  }, []);

  const open = useCallback(() => {
    setOpenModal(true);
  }, []);

  const close = () => {
    setTitle("");
    setFormName("");
    setFormList([]);
    setFormData({});
    setOpenModal(false);
  };

  // 테마 항목 추가
  const addFromList = () => {
    if (formName !== "") {
      let newList: any = [...formList, formName];
      setFormName("");
      setFormList(newList);
    }
  };

  // 테마 항목 삭제(메모리상에만 처리 별도 저장 클릭시 DB 저장)
  const removeFormData = (index: number, value: string) => {
    let oldList: any = [...formList];
    oldList.splice(index, 1);
    setFormList(oldList);

    const newData: any = { ...formData };
    delete newData[value];
    setFormData(newData);
  };

  // 테마 생성하기
  const createNewTheme = async () => {
    console.log();
    if (!category || category.trim() === "") {
      alert("카테고리를 선택해주세요.");
      return;
    }

    if (!window.confirm("테마를 저장하시겠습니까?")) return;

    setIsLoading(true);
    const param: any = {
      command: "create_theme_info",
      theme_info: {
        title: title,
        form_list: formList,
        form_example: formData,
        user_id: props.userState.id,
        user_name: props.userState.name,
        type: props.type,
        category: category,
      },
    };

    const res = await mainApi.post(param);
    setIsLoading(false);
    if (res.code === "200") {
      // 보도자료 작성 페이지로 이동
      await props.refresh();
      close();
    } else {
      console.error(res.response.error_msg);
      toastRef.current?.toast("" + res.response.error_msg, "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
    }
  };

  // 테마 저장버튼 활성화
  useEffect(() => {
    if (title !== "" && title !== undefined && formList.length > 0)
      setAbleRequest(true);
    else setAbleRequest(false);
  }, [title, formList]);

  // 카테고리 선택 버튼 클릭
  const openSelectCategory = () => {
    categorySelectRef.current.open();
  };

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    open,
  }));

  return (
    <>
      <Modal
        open={openModal}
        onClose={() => {
          close();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack direction={"column"} spacing={2}>
            <Stack
              direction="row"
              spacing={2}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Typography
                variant="h5"
                gutterBottom
                className="sub-header-title"
                sx={{ lineHeight: "40px !important" }}
              >
                테마 만들기
              </Typography>
              <Tooltip title="창닫기">
                <IconButton aria-label="close" onClick={close}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Stack>
            {/* 제목 */}
            <TextField
              inputRef={titleRef}
              id="txtTitle"
              value={title}
              label="제목"
              placeholder="직접 입력 하기"
              autoFocus
              fullWidth
              size="small"
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              type="search"
              autoComplete="off"
              inputProps={{ enterKeyHint: "Enter" }}
            />
            {/* 카테고리(공통테마일때만) */}
            {props.type === "common" ? (
              <Stack direction={"row"} spacing={0} className="div-template-add">
                <Box className="text-template-add">
                  <TextField
                    id="txtCategory"
                    inputRef={categoryRef}
                    value={category}
                    placeholder="카테고리를 선택하세요."
                    fullWidth
                    size="small"
                    type="search"
                    autoComplete="off"
                    inputProps={{ enterKeyHint: "Enter" }}
                  />
                </Box>
                <Box className="btn-template-add">
                  <Button
                    variant="text"
                    onClick={openSelectCategory}
                    fullWidth
                    sx={{ height: "40px", fontWeight: "700", color: "#ffffff" }}
                    startIcon={<SearchIcon />}
                  >
                    카테고리
                  </Button>
                </Box>
              </Stack>
            ) : (
              <Stack direction={"row"} spacing={0} className="div-template-add">
                {props.themeCategoryList.length > 0 && (
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={props.themeCategoryList.filter(
                      (option) => option !== "전체"
                    )}
                    sx={{ minWidth: 300 }}
                    size="small"
                    value={category}
                    onChange={(event: any, newValue: string | null) => {
                      setCategory(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Category" />
                    )}
                  />
                )}
              </Stack>
            )}
            {/* 테마 항목 추가 영역 */}
            <Stack direction={"row"} spacing={0} className="div-template-add">
              <Box className="text-template-add">
                <TextField
                  id="txtFormName"
                  inputRef={formNameRef}
                  value={formName}
                  placeholder="추가할 데이터 항목을 넣어주세요."
                  fullWidth
                  onChange={(e) => {
                    setFormName(e.target.value);
                  }}
                  size="small"
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && !e.nativeEvent.isComposing)
                      addFromList();
                  }}
                  type="search"
                  autoComplete="off"
                  inputProps={{ enterKeyHint: "Enter" }}
                />
              </Box>
              <Box className="btn-template-add">
                <Button
                  variant="text"
                  onClick={addFromList}
                  fullWidth
                  sx={{ height: "40px", fontWeight: "700", color: "#ffffff" }}
                  startIcon={<AddIcon />}
                >
                  추가
                </Button>
              </Box>
            </Stack>
            {/* 테마 상세 영역 */}
            <Stack
              direction={"column"}
              spacing={1}
              className="template-form-list-make"
            >
              {formList.map((value: string, index: number) => (
                <Stack key={`formData-${index}`} direction={"row"} spacing={0}>
                  <Stack direction={"column"} spacing={0}>
                    <IconButton
                      sx={{ p: 0 }}
                      color="primary"
                      disabled={index === 0}
                      onClick={() => {
                        let newList: any = [...formList];
                        newList.splice(index, 1);
                        newList.splice(index - 1, 0, value);
                        setFormList(newList);
                      }}
                    >
                      <ExpandLessIcon sx={{ fontSize: "1.3rem" }} />
                    </IconButton>
                    <IconButton
                      sx={{ p: 0 }}
                      color="primary"
                      disabled={index === formList.length - 1}
                      onClick={() => {
                        let newList: any = [...formList];
                        newList.splice(index, 1);
                        newList.splice(index + 1, 0, value);
                        setFormList(newList);
                      }}
                    >
                      <ExpandMoreIcon sx={{ fontSize: "1.3rem" }} />
                    </IconButton>
                  </Stack>
                  <Box className="template-content-title">{value}</Box>
                  <Box className="template-content-content">
                    <TextField
                      size="small"
                      fullWidth
                      multiline
                      placeholder={"예시를 입력하세요."}
                      value={utils.isEmptyObj(formData) ? "" : formData[value]}
                      onChange={(e) => {
                        const newData: any = { [value]: e.target.value };
                        setFormData({ ...formData, ...newData });
                      }}
                      type="search"
                      autoComplete="off"
                      inputProps={{ enterKeyHint: "Enter" }}
                    />
                  </Box>
                  <Tooltip title="항목 삭제">
                    <IconButton
                      onClick={() => {
                        removeFormData(index, value);
                      }}
                    >
                      <RemoveCircleOutlineIcon sx={{ color: "#ff000090" }} />
                    </IconButton>
                  </Tooltip>
                </Stack>
              ))}
            </Stack>
            {/* 테마 저장 버튼 */}
            <Button
              variant="contained"
              onClick={createNewTheme}
              startIcon={<SaveIcon />}
              sx={{ borderRadius: "7px" }}
              disabled={!ableRequest}
            >
              테마 생성
            </Button>
          </Stack>
          <Toast ref={toastRef} />
          <LoadingCircle loading={isLoading} />
        </Box>
      </Modal>
      <PopCategory
        ref={categorySelectRef}
        userState={props.userState}
        callback={(param: any) => {
          if (param.command === "set_theme_category") {
            setCategory(param.category);
          }
        }}
      />
    </>
  );
};

export default forwardRef(PopThemeMake);
