import { API, Auth } from "aws-amplify";
import * as AuthMain from "../auth/AuthMain";
import { openAIAiApiUrl, naverAiApiUrl } from "./MainInterface";
import axios from "axios";

export abstract class ApiBase {
  protected apiName: string;
  protected urlPath: string;
  protected authToken: string | undefined;

  abstract onApiName(): string;
  abstract onUrlPath(): string;

  constructor() {
    this.apiName = this.onApiName();
    this.urlPath = this.onUrlPath();
    this.authToken = undefined;
  }

  protected async getToken() {
    if (this.authToken === undefined) {
      try {
        const session = await Auth.currentSession();
        this.authToken = `Bearer ${session.getIdToken()?.getJwtToken()}`;
      } catch (error) {
        return undefined;
      }
    }

    return this.authToken;
  }

  protected getApiKey() {
    const isLocalhost =
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1" ||
    window.location.hostname.startsWith("haap-dev");

  return isLocalhost ? process.env.REACT_APP_LOCAL_API_KEY : process.env.REACT_APP_API_KEY;
  }

  protected getApiUrlPath() {
    const isLocalhost =
      window.location.hostname === "localhost" ||
      window.location.hostname === "127.0.0.1" ||
      window.location.hostname.startsWith("haap-dev");

    return isLocalhost ? process.env.REACT_APP_API_GATEWAY_LOCAL_URL : process.env.REACT_APP_API_GATEWAY_URL;
  }
  
  protected async requestPost(requestBody: any): Promise<any> {
    let header: any = {};
    let authToken: any;

    if (this.apiName === "MainApi") {
      authToken = await this.getToken();
       if (authToken !== undefined) {
         header = {
           Authorization: authToken,
         };
       }
    }

    const request = {
      headers: header,
      body: requestBody,
    };
    try {
      const response = await API.post(this.apiName, this.urlPath, request);
      return response;
    } catch (error) {
      return { code: "500", response: { error_msg: error } };
    }
  }

  protected async requestPostAxiosWithKey(requestBody: any): Promise<any> {
    try {
      let header: any = { headers: { "x-api-key": this.getApiKey() }, timeout: 60000 };
      const response = await axios.post(this.getApiUrlPath() + "/" + this.urlPath, requestBody, header);
      return response.data;
    } catch (error) {
      console.error(error);
      return { code: "500", response: { error_msg: error } };
    }
  }

  protected async requestStream(requestBody: any): Promise<any> {
    if (this.apiName === "OpenAIAiApi") {
      // Stream 형식으로 Request
      const response = await fetch(openAIAiApiUrl, {
        method: "POST",
        body: JSON.stringify(requestBody),
      });
      return response;
    }
    if (this.apiName == "NaverAiApi") {
      // Stream 형식으로 Request
      const response = await fetch(naverAiApiUrl, {
        method: "POST",
        body: JSON.stringify(requestBody),
      });
      return response;
    }
  }
}
