import {
  useCallback,
  useEffect,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";

import {
  Box,
  Stack,
  Modal,
  Typography,
  Button,
  TextField,
  IconButton,
  Tooltip,
  Tabs,
  Tab,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import { HttpMainApi } from "../../interface/main-api";
import { userState } from "../../interface/MainInterface";
import LoadingCircle from "../../utils/LoadingCircle";
import Toast from "../../utils/Toast";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface propsType {
  userState: userState;
  themeInfo: any;
  type: string;
  refresh: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "500px",
  height: "80%",
  bgcolor: "background.paper",
  border: "0px solid #000",
  borderRadius: "1rem",
  boxShadow: 24,
  p: 3,
  overflow: "auto",
};

const mainApi = new HttpMainApi();

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const PopOneShotMake = (props: propsType, ref: any) => {
  const toastRef: any = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [openOneShotModal, setOpenOneShotModal] = useState(false);
  const [ableRequest, setAbleRequest] = useState(false);
  const [oneShotOption1, setOneShotOption1] = useState("");
  const [oneShotOption2, setOneShotOption2] = useState("");
  const [oneShotOption3, setOneShotOption3] = useState("");
  const [oneShotOption4, setOneShotOption4] = useState("");
  const [tabvalue, setTabvalue] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabvalue(newValue);
  };

  useEffect(() => {
    return () => {
      setIsLoading(false);
      setOpenOneShotModal(false);
      setAbleRequest(false);
    };
  }, []);

  useEffect(() => {
    if (props.themeInfo) {
      setOneShotOption1(props.themeInfo.one_shot?.option1 ?? "");
      setOneShotOption2(props.themeInfo.one_shot?.option2 ?? "");
      setOneShotOption3(props.themeInfo.one_shot?.option3 ?? "");
      setOneShotOption4(props.themeInfo.one_shot?.option4 ?? "");
    }
  }, [props.themeInfo]);

  const open = useCallback(() => {
    setOpenOneShotModal(true);
  }, []);

  const close = () => {
    setOpenOneShotModal(false);
  };

  //샘플 보도자료 등록
  const saveOneShot = async () => {
    let newThemeInfo: any = { ...props.themeInfo };
    newThemeInfo.one_shot = {
      option1: oneShotOption1,
      option2: oneShotOption2,
      option3: oneShotOption3,
      option4: oneShotOption4,
    };

    const param: any = {
      command: "put_theme_info",
      theme_info: newThemeInfo,
    };
    setIsLoading(true);
    const res = await mainApi.post(param);
    if (res.code === "200") {
      await props.refresh();
      close();
    } else {
      console.error(res.response.error_msg);
      toastRef.current?.toast("" + res.response.error_msg, "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
    }
    setIsLoading(false);
  };

  // 등록 저장버튼 활성화
  useEffect(() => {
    if (oneShotOption1 !== "") setAbleRequest(true);
    else setAbleRequest(false);
  }, [oneShotOption1]);

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    open,
  }));

  return (
    <>
      <Modal
        open={openOneShotModal}
        onClose={() => {
          close();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack direction={"column"} spacing={2}>
            <Stack
              direction="row"
              spacing={2}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Typography
                variant="h5"
                gutterBottom
                className="sub-header-title"
                sx={{ lineHeight: "40px !important" }}
              >
                샘플 보도자료 등록
              </Typography>
              <Tooltip title="창닫기">
                <IconButton aria-label="close" onClick={close}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Stack>
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={tabvalue}
                  onChange={handleTabChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="제목" {...a11yProps(0)} />
                  <Tab label="리드문" {...a11yProps(1)} />
                  <Tab label="본문" {...a11yProps(2)} />
                  <Tab label="인용문" {...a11yProps(3)} />
                </Tabs>
              </Box>
              <CustomTabPanel value={tabvalue} index={0}>
                <TextField
                  size="small"
                  fullWidth
                  multiline
                  rows={25}
                  placeholder={"제목 예시를 입력하세요."}
                  value={oneShotOption1}
                  onChange={(e) => {
                    setOneShotOption1(e.target.value);
                  }}
                  type="search"
                  autoComplete="off"
                  inputProps={{ enterKeyHint: "Enter" }}
                  sx={{
                    width: "100%",
                    maxWidth: "100%",
                    overflow: "auto",
                  }}
                />
              </CustomTabPanel>
              <CustomTabPanel value={tabvalue} index={1}>
                <TextField
                  size="small"
                  fullWidth
                  multiline
                  rows={25}
                  placeholder={"리드문 예시를 입력하세요."}
                  value={oneShotOption2}
                  onChange={(e) => {
                    setOneShotOption2(e.target.value);
                  }}
                  type="search"
                  autoComplete="off"
                  inputProps={{ enterKeyHint: "Enter" }}
                  sx={{
                    width: "100%",
                    maxWidth: "100%",
                    overflow: "auto",
                  }}
                  disabled={props.themeInfo.category === "포토뉴스"}
                />
              </CustomTabPanel>
              <CustomTabPanel value={tabvalue} index={2}>
                <TextField
                  size="small"
                  fullWidth
                  multiline
                  rows={25}
                  placeholder={"본문 예시를 입력하세요."}
                  value={oneShotOption3}
                  onChange={(e) => {
                    setOneShotOption3(e.target.value);
                  }}
                  type="search"
                  autoComplete="off"
                  inputProps={{ enterKeyHint: "Enter" }}
                  sx={{
                    width: "100%",
                    maxWidth: "100%",
                    overflow: "auto",
                  }}
                />
              </CustomTabPanel>
              <CustomTabPanel value={tabvalue} index={3}>
                {/* option3 */}
                <TextField
                  size="small"
                  fullWidth
                  multiline
                  rows={25}
                  placeholder={"인용문 예시를 입력하세요."}
                  value={oneShotOption4}
                  onChange={(e) => {
                    setOneShotOption4(e.target.value);
                  }}
                  type="search"
                  autoComplete="off"
                  inputProps={{ enterKeyHint: "Enter" }}
                  sx={{
                    width: "100%",
                    maxWidth: "100%",
                    overflow: "auto",
                  }}
                  disabled={props.themeInfo.category === "포토뉴스"}
                />
              </CustomTabPanel>
            </Box>

            {/* 테마 저장 버튼 */}
            <Button
              variant="contained"
              onClick={saveOneShot}
              startIcon={<SaveIcon />}
              sx={{ borderRadius: "7px" }}
              disabled={!ableRequest}
            >
              등록
            </Button>
          </Stack>
          <Toast ref={toastRef} />
          <LoadingCircle loading={isLoading} />
        </Box>
      </Modal>
    </>
  );
};

export default forwardRef(PopOneShotMake);
