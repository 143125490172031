import { HttpMainApi } from "./main-api";
import { HttpNaverAiApi } from "./naver-ai-api";
import { HttpOpenAIAiApi } from "./openai-ai-api";

export class ModelHandler {
  protected model: string;
  protected aiApi: any;
  protected handleStreamResponse: any;
  public mainApi = new HttpMainApi();

  constructor(model: string) {
    this.model = model;
  }

  // aiApi, handleStreamResponse 가져오기
  public getModelElements() {
    this.aiApi =
      this.model === "openai" ? new HttpOpenAIAiApi() : new HttpNaverAiApi();
    this.handleStreamResponse =
      this.model === "openai"
        ? this.handleOpenAIStreamResponse
        : this.handleNaverStreamResponse;

    return [this.aiApi, this.handleStreamResponse];
  }

  // 사용 중인 모델 가져오기
  public async getUsingModel() {
    const param: any = {
      command: "get_llm_model",
    };

    const res = await this.mainApi.post(param);
    if (res.code === "200") {
      return res.response.using_model;
    } else {
      console.error(res.response.error_msg);
      throw new Error(res.response.error_msg);
    }
  }

  // 모델 스위칭
  public async setUsingModel(oldModel: string) {
    let confirmText = "CLOVA 모델로 변경하시겠습니까?";
    if (oldModel === "clova") {
      confirmText = "OPENAI 모델로 변경하시겠습니까?";
    }

    if (!window.confirm(confirmText)) return;

    const newModel = oldModel === "openai" ? "clova" : "openai";
    const param: any = {
      command: "set_llm_model",
      new_model: newModel,
    };

    const res = await this.mainApi.post(param);
    if (res.code === "200") {
      this.model = newModel;
      return newModel;
    } else {
      console.error(res.response.error_msg);
      throw new Error(res.response.error_msg);
    }
  }

  // OpenAI Stream 처리 함수
  public async handleOpenAIStreamResponse(
    streamResponse: any,
    updateResult: (data: string) => void,
    setStreamReader?: (data: any) => void
  ) {
    if (!streamResponse) {
      return;
    }

    const reader = streamResponse?.getReader();
    const decoder = new TextDecoder();
    if (setStreamReader) setStreamReader(reader);

    let content = "";

    try {
      while (true) {
        const { value, done } = await reader.read();
        if (value === undefined) break;
        if (done) break;
        const chunkValue = decoder.decode(value);
        content += chunkValue;
        updateResult(content);
      }
    } catch (error) {
      console.error("Stream reading error:", error);
    } finally {
      // reader.releaseLock();
      if (setStreamReader) setStreamReader(null);
    }
  }

  // clova-x stream 처리 함수
  public async handleNaverStreamResponse(
    streamResponse: any,
    updateResult: (data: string) => void,
    setStreamReader?: (data: any) => void
  ) {
    if (!streamResponse) {
      return;
    }

    const reader = streamResponse?.getReader();
    const decoder = new TextDecoder("utf-8");
    if (setStreamReader) setStreamReader(reader);

    let doneReading: boolean = false;
    let content = "";

    try {
      while (true) {
        const { value, done } = await reader.read();
        if (value === undefined) break;
        if (done) break;
        const chunkValue = decoder.decode(value);

        let stringJson: string[] = [];
        chunkValue.split("\n").forEach((elem) => {
          const parsingString = elem.match(/{"message":([^]*)}/);
          if (parsingString) {
            stringJson.push(parsingString[0]);
          }
        });
        stringJson.some((elem) => {
          let data = JSON.parse(elem);
          if (data.stopReason === "stop_before") {
            return true;
          }
          content = content + data.message.content;
        });
        updateResult(content);
      }
    } catch (error) {
      console.error("Stream reading error:", error);
    } finally {
      // reader.releaseLock();
      if (setStreamReader) setStreamReader(null);
    }
  }
}
