import { useEffect, useRef, useState } from "react";

import {
  Box,
  Stack,
  Divider,
  Button,
  Typography,
  Paper,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import { userState } from "../../interface/MainInterface";
import LoadingCircle from "../../utils/LoadingCircle";
import Toast from "../../utils/Toast";
import { ModelHandler } from "../../interface/model-handler";

import ChangePwd from "./ChangePwd";

interface propsType {
  userState: userState;
}

const Settings = (props: propsType) => {
  const toastRef: any = useRef();
  const chPwdRef: any = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [model, setModel] = useState("");
  const modelHandler = new ModelHandler(model);

  const openChangePwd = () => {
    chPwdRef.current.open(true);
  };

  useEffect(() => {
    return () => {
      setIsLoading(false);
    };
  }, []);

  useEffect(() => {
    modelHandler
      .getUsingModel()
      .then((usingModel) => {
        setModel(usingModel);
      })
      .catch((errorMsg: any) => {
        toastRef.current?.toast("" + errorMsg, "error", 4000, {
          vertical: "top",
          horizontal: "center",
        });
      });
  }, []);

  return (
    <>
      <Box sx={{ p: 0, height: "100%" }}>
        <Stack direction={"column"} spacing={0}>
          <Box className="my-account-header-root">
            <Typography
              variant="h5"
              gutterBottom
              className="my-account-header-title"
            >
              {props.userState.name} / Settings
            </Typography>
          </Box>
          <Divider />
          <Box className="my-account-content-root">
            <Box className="apikey-content-header">
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  gutterBottom
                  className="my-account-content-sub-title"
                >
                  Manage Settings here
                </Typography>
              </Box>
              <Box sx={{ display: "flex" }}>
                <Button
                  variant="contained"
                  style={{
                    display: props.userState.id.includes("@")
                      ? "inline-block"
                      : "none",
                  }}
                  onClick={openChangePwd}
                >
                  Change Password
                </Button>
              </Box>
            </Box>
            <Box>
              <Paper sx={{ p: 3, borderRadius: "4px" }} elevation={3}>
                <Stack direction="column" spacing={2}>
                  <Box className="my-account-setting-detail-row">
                    <Typography className="setting-detail-sub-title">
                      ID
                    </Typography>
                    <Typography>{props.userState.id}</Typography>
                  </Box>
                  <Divider />
                  <Box className="my-account-setting-detail-row">
                    <Typography className="setting-detail-sub-title">
                      Name
                    </Typography>
                    <Typography>{props.userState.name}</Typography>
                  </Box>
                  <Divider />
                  <Box className="my-account-setting-detail-row">
                    <Typography className="setting-detail-sub-title">
                      Company
                    </Typography>
                    <Typography>{props.userState.company}</Typography>
                  </Box>
                  {props.userState.isSuperAdmin && (
                    <>
                      <Divider />
                      <Box className="my-account-setting-detail-row">
                        <Typography className="setting-detail-sub-title">
                          모델설정
                        </Typography>

                        <ToggleButtonGroup
                          color="error"
                          value={model}
                          exclusive
                          onChange={(e) => {
                            modelHandler
                              .setUsingModel(model)
                              .then((newModel) => {
                                newModel && setModel(newModel);
                              })
                              .catch((errorMsg: any) => {
                                toastRef.current?.toast(
                                  "" + errorMsg,
                                  "error",
                                  4000,
                                  {
                                    vertical: "top",
                                    horizontal: "center",
                                  }
                                );
                              });
                            setIsLoading(false);
                          }}
                          aria-label="Platform"
                        >
                          <ToggleButton
                            value="openai"
                            style={{
                              fontSize: "small",
                              height: "30px",
                            }}
                          >
                            OPENAI
                          </ToggleButton>
                          <ToggleButton
                            value="clova"
                            style={{
                              height: "30px",
                              fontSize: "small",
                            }}
                          >
                            CLOVA
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </Box>
                    </>
                  )}
                </Stack>
              </Paper>
            </Box>
          </Box>
        </Stack>
      </Box>
      <LoadingCircle loading={isLoading} />
      <Toast ref={toastRef} />
      <ChangePwd ref={chPwdRef} userState={props.userState} />
    </>
  );
};

export default Settings;
