import {
  useCallback,
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { TextField, Button, Tooltip, Autocomplete } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Toast from "../../utils/Toast";
import LoadingCircle from "../../utils/LoadingCircle";
import { userState } from "../../interface/MainInterface";
import { HttpMainApi } from "../../interface/main-api";
import CategoryLoader from "../theme/CategoryLoader";

interface propsType {
  userState: userState;
  move: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "400px",
  height: "220px",
  bgcolor: "background.paper",
  border: "0px solid #000",
  borderRadius: "1rem",
  boxShadow: 24,
  p: 3,
};

const mainApi = new HttpMainApi();

const PopCreatePress = (props: propsType, ref: any) => {
  const toastRef: any = useRef();
  const titleRef: any = useRef();

  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [themeCategoryList, setThemeCategoryList] = useState<string[]>([]);
  const [category, setCategory] = useState<string | null>("마케팅");
  useEffect(() => {
    return () => {
      setOpenModal(false);
      setTitle("");
    };
  }, []);

  const open = useCallback((open: boolean) => {
    setOpenModal(open);
  }, []);

  const createPressInfo = async () => {
    if (title === "") {
      toastRef.current?.toast("제목을 입력해주세요.", "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
      titleRef.current.focus();
      return;
    }

    setIsLoading(true);
    const param: any = {
      command: "create_press_info",
      press_info: {
        title: title,
        user_id: props.userState.id,
        user_name: props.userState.name,
        user_company: props.userState.company,
        form_data: {},
        form_list: [],
        result: "",
        category: category,
      },
    };

    const res = await mainApi.post(param);
    if (res.code === "200") {
      // 보도자료 작성 페이지로 이동
      props.move("/makePress/" + res.response.sk);
      close();
    } else {
      console.error(res.response.error_msg);
      toastRef.current?.toast("" + res.response.error_msg, "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
    }
    setIsLoading(false);
  };

  const close = () => {
    setTitle("");
    setOpenModal(false);
  };

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    open,
  }));

  return (
    <>
      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack direction="column" spacing={2}>
            <Stack
              direction="row"
              spacing={2}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Typography
                gutterBottom
                sx={{ lineHeight: "40px", fontWeight: "800 !important" }}
              >
                새 보도자료 만들기
              </Typography>
              <Tooltip title="창닫기">
                <IconButton aria-label="close" onClick={close}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Stack>
            <CategoryLoader onCategoriesLoaded={setThemeCategoryList} />
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={themeCategoryList.filter((option) => option !== "전체")}
              sx={{ minWidth: 300 }}
              size="small"
              value={category}
              onChange={(event: any, newValue: string | null) => {
                setCategory(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Category (작성중 변경 할 수 있습니다.)"
                />
              )}
            />
            <TextField
              inputRef={titleRef}
              id="txtTitle"
              value={title}
              label="제목"
              placeholder="직접 입력 하기"
              autoFocus
              fullWidth
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.nativeEvent.isComposing)
                  createPressInfo();
              }}
              type="search"
              autoComplete="off"
              inputProps={{ enterKeyHint: "Enter" }}
            />
            <Stack
              direction="row"
              spacing={2}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button variant="outlined" onClick={close} disabled={isLoading}>
                취소
              </Button>
              <Button
                variant="contained"
                onClick={createPressInfo}
                disabled={isLoading}
              >
                저장
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
      <Toast ref={toastRef} />
      <LoadingCircle loading={isLoading} />
    </>
  );
};

export default forwardRef(PopCreatePress);
