import { ApiBase } from "./api-base";

export class HttpOpenAIAiApi extends ApiBase {
  onApiName(): string {
    return "OpenAIAiApi";
  }

  onUrlPath(): string {
    return "";
  }

  public async postStream(param: any) {
    const response = await this.requestStream(param);
    return response.body;
  }
}
