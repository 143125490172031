import { Box, Button, Checkbox, FormControlLabel, FormGroup, IconButton, Modal, Stack, TextField, Tooltip, Typography } from "@mui/material";
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react"
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { userState } from "../../interface/MainInterface";
import { HttpMainApi } from "../../interface/main-api";

interface propsType {
    userState: userState;
    refresh: any;
}

const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "500px",
    height: "80%",
    bgcolor: "background.paper",
    border: "0px solid #000",
    borderRadius: "1rem",
    boxShadow: 24,
    p: 3,
};

const mainApi = new HttpMainApi();

const PopAddUser = (props: propsType, ref: any) => {
    const toastRef: any = useRef();

    const [isLoading, setIsLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    const [id, setId] = useState("");
    const [formName, setFormName] = useState("");
    const [formCompany, setFormCompany] = useState("");
    const [formDept, setFormDept] = useState("");
    // const [formIsAdmin, setFormIsAdmin] = useState(false);
    // const [formIsSuperAdmin, setFormIsSuperAdmin] = useState(false);

    useEffect(() => {
        return () => {
          setIsLoading(false);
          setOpenModal(false);
        };
    }, []);

    const open = useCallback(() => {
        setOpenModal(true);
    }, []);
    
    const close = () => {
        setOpenModal(false);
        setId("");
        setFormName("");
        setFormCompany("");
        setFormDept("");
    };

    const createNewUser = async () => {
        if (!props.userState.isAdmin) {
            toastRef.current?.toast("권한부여 권한이 없습니다.", "error", 4000, {
                vertical: "top",
                horizontal: "center",
            });
            return;
        }

        if (id === "" || id === undefined) {
            toastRef.current?.toast("아이디(사번)을 입력하세요.", "error", 4000, { vertical: "top", horizontal: "center", });
            return;
        }
        if (formName === "" || formName === undefined) {
            toastRef.current?.toast("사용자 이름을 입력하세요.", "error", 4000, { vertical: "top", horizontal: "center", });
            return;
        }
        if (formCompany === "" || formCompany === undefined) {
            toastRef.current?.toast("소속을 입력하세요.", "error", 4000, { vertical: "top", horizontal: "center", });
            return;
        }
        if (formDept === "" || formDept === undefined) {
            toastRef.current?.toast("부서를 입력하세요.", "error", 4000, { vertical: "top", horizontal: "center", });
            return;
        }

        setIsLoading(true);
        const param: any = {
            command: "create_user",
            email: id,
            name: formName,
            company: formCompany,
            dept: formDept,
            dtype: "N",  // 사번로그인 사용자: N, cognito 사용자: C
            // isAdmin: formIsAdmin,
            // isSuperAdmin: formIsSuperAdmin,
        };

        const res = await mainApi.post(param);
        setIsLoading(false);
        if (res.code === "200") {
            // 사용자 관리 목록 페이지로 이동
            await props.refresh();
            toastRef.current?.toast(res.reponse.msg, "success", 4000, { vertical: "top", horizontal: "center" });
            close();
        } else {
            console.error(res.response.error_msg);
            toastRef.current?.toast("" + res.response.error_msg, "error", 4000, {
                vertical: "top",
                horizontal: "center",
            });
        }
    }

    // 부모 Component에서 접근 가능하도록 함수 전달.
    useImperativeHandle(ref, () => ({
        open,
    }));

    return (
        <>
            <Modal
                open={openModal}
                onClose={() => {
                    close();
                }}
            >
                <Box sx={style}>
                    <Stack direction={"column"} spacing={2}>
                        <Stack
                            direction="row"
                            spacing={2}
                            sx={{ display: "flex", justifyContent: "space-between" }}
                        >
                            <Typography
                                variant="h5"
                                gutterBottom
                                className="sub-header-title"
                                sx={{ lineHeight: "40px !important" }}
                            >
                                사용자 추가하기
                            </Typography>
                            <Tooltip title="창닫기">
                                <IconButton aria-label="close" onClick={close}>
                                    <CloseIcon />
                                </IconButton>
                            </Tooltip>
                        </Stack>
                        <Typography
                            gutterBottom
                            sx={{ lineHeight: "25px !important" }}
                        >
                            * H-art 접속 사용자를 추가합니다. 
                            <br />
                            * 이메일 사용자는 별도 회원가입 링크를 통해 추가 가능합니다.
                        </Typography>
                        {/* ID (사번) */}
                        <TextField
                            id="txtId"
                            value={id}
                            label="ID (사번)"
                            placeholder="사번을 입력해주세요."
                            autoFocus
                            fullWidth
                            size="small"
                            onChange={(e) => {
                                setId(e.target.value);
                            }}
                            type="search"
                            autoComplete="off"
                            inputProps={{ enterKeyHint: "Enter" }}
                        />
                        {/* 이름 */}
                        <TextField
                            id="txtFormName"
                            value={formName}
                            label="이름"
                            placeholder="이름을 입력해주세요."
                            fullWidth
                            size="small"
                            onChange={(e) => {
                                setFormName(e.target.value);
                            }}
                            type="search"
                            autoComplete="off"
                            inputProps={{ enterKeyHint: "Enter" }}
                        />
                        {/* 소속 */}
                        <TextField
                            id="txtFormCompany"
                            value={formCompany}
                            label="소속"
                            placeholder="(예시) 현대백화점, 현대홈쇼핑 ..."
                            fullWidth
                            size="small"
                            onChange={(e) => {
                                setFormCompany(e.target.value);
                            }}
                            type="search"
                            autoComplete="off"
                            inputProps={{ enterKeyHint: "Enter" }}
                        />
                        {/* 부서 */}
                        <TextField
                            id="txtFormDept"
                            value={formDept}
                            label="부서"
                            placeholder="(예시) 홍보실, AI LAB ..."
                            fullWidth
                            size="small"
                            onChange={(e) => {
                                setFormDept(e.target.value);
                            }}
                            type="search"
                            autoComplete="off"
                            inputProps={{ enterKeyHint: "Enter" }}
                        />
                        {/* <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formIsAdmin}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setFormIsAdmin(e.target.checked);
                                        }}
                                    />
                                } 
                                label="홍보실" 
                            />
                            {props.userState.isSuperAdmin && (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={formIsSuperAdmin}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                setFormIsSuperAdmin(e.target.checked);
                                            }}
                                        />
                                    } 
                                label="슈퍼어드민" 
                                />  
                            )}
                        </FormGroup> */}
                        <Button
                            variant="contained"
                            onClick={createNewUser}
                            startIcon={<AddIcon />}
                            sx={{ borderRadius: "7px" }}
                        >
                            사용자 생성
                        </Button>
                    </Stack>
                </Box>
            </Modal>
        </>
    )
}

export default forwardRef(PopAddUser);