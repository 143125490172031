import { useState, useRef, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Auth, Hub } from "aws-amplify";
import { HttpMainApi } from "../../interface/main-api";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import { userState } from "../../interface/MainInterface";

import Toast from "../../utils/Toast";
import { Typography } from "@mui/material";

import { appConfig } from "../../config/Config";
import { AES, enc } from "crypto-js";
// 환경 확인
const isLocalhost =
  window.location.hostname === "localhost" ||
  window.location.hostname === "127.0.0.1" ||
  window.location.hostname.startsWith("haap-dev");

const contentPath = isLocalhost ? "개발서버 로그인" : "로그인";

interface propsType {
  userState: userState;
}

const HartLogIn = (props: propsType) => {
  let navigate = useNavigate();
  const mainApi = new HttpMainApi();
  const toastRef: any = useRef();
  const userEmailRef: any = useRef();
  const userPwdRef: any = useRef();
  const verifyCodeRef: any = useRef();
  const userPwdConfirmRef: any = useRef();

  const [userEmail, setUserEmail] = useState("");
  const [userPwd, setUserPwd] = useState("");
  const [userPwdConfirm, setUserPwdConfirm] = useState("");
  const [realEmail, setRealEmail] = useState("");
  const [realPwd, setRealPwd] = useState("");
  const [needConfirm, setNeedConfirm] = useState(false);
  const [needNewPwd, setNeedNewPwd] = useState(false);
  const [verifyCode, setVerifyCode] = useState("");

  // url 가져오기
  const stateInfo: URLSearchParams = new URLSearchParams(
    window.location.search
  );
  useEffect(() => {
    // none querystring
    window.history.replaceState({}, "", window.location.pathname);

    // requestKey
    if (stateInfo.get("requestKey") && stateInfo.get("time")) {
      const keyInfo: string = stateInfo.get("requestKey") as string;
      const time: string = stateInfo.get("time") as string;
      const customKey = getUserKey(keyInfo);

      let date = new Date();
      let month =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      let days = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      let today = `${date.getFullYear()}${month}${days}` + "";
      if (time === today) {
        // 로그인 진행
        check_user(customKey);
      } else {
        //날짜 확인후 주석
        // alert("아이디와 비밀번호로 로그인 진행해주세요");
      }
    } else {
      // 일반적인 로그인 진행
    }
  }, []);
  const getUserKey = (encryptedKey: string) => {
    const before = "1928374651973";
    const after = "1428385651973";
    const result = encryptedKey.replace(before, "").replace(after, "");
    return result;
  };

  const check_user = async (key_id: string) => {
    const param: any = {
      command: "get_user_info",
      user_id: key_id,
    };
    const res = await mainApi.post(param);
    console.log("res: ", res);
    if ("" + res.code == "200") {
      if (res.response.result === "success") procSesson(res.response.user_info);
    } else {
      console.log("error_msg: ", res);
    }
  };

  const procSesson = (sessionData: any) => {
    // // toastRef.current?.toast("로그인 성공", "info", 3000, { vertical: "bottom", horizontal: "right" });
    const sessionObj: any = {
      id: sessionData.sk,
      name: sessionData.name,
      company: sessionData.company,
      dept: sessionData.dept,
      isAuth: sessionData.is_auth,
      isAdmin: sessionData.is_admin,
      isSuperAdmin: sessionData.is_super_admin,
      dtype: sessionData.dtype,
      expire: Date.now() + 1000 * 60 * 60 * 24, // 24시간
    };
    const objString = JSON.stringify(sessionObj);
    const encObjString = AES.encrypt(objString, appConfig.seed).toString();
    window.localStorage.setItem(appConfig.sessionName, encObjString);

    // 아이디 저장 기억하기
    const rememberIdObj: any = {
      id: sessionData.id,
      remember: "TRUE",
    };
    const objRememberId = JSON.stringify(rememberIdObj);
    if (objRememberId) {
      window.localStorage.setItem(appConfig.rememberidSSName, objRememberId);
    }
    window.location.href = "/myAccount";
  };

  const fncSignIn = async () => {
    if (userEmail === "" || userEmail === undefined) {
      toastRef.current?.toast("Please enter your email.", "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
      userEmailRef.current.focus();
      return;
    }
    if (userPwd === "" || userPwd === undefined) {
      toastRef.current?.toast("Please enter your password.", "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
      userPwdRef.current.focus();
      return;
    }

    setRealEmail(userEmail);
    setRealPwd(userPwd);

    try {
      await Auth.signIn(userEmail, userPwd);
      toastRef.current?.toast("Welcome " + userEmail, "success", 4000, {
        vertical: "top",
        horizontal: "center",
      });
      window.location.href = "/myIngList";
    } catch (error: any) {}
  };

  const autoSignIn = async () => {
    if (realEmail === "" || realEmail === undefined) {
      toastRef.current?.toast("Please enter your email.", "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
      userEmailRef.current.focus();
      return;
    }
    if (realPwd === "" || realPwd === undefined) {
      toastRef.current?.toast("Please enter your password.", "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
      userPwdRef.current.focus();
      return;
    }

    try {
      await Auth.signIn(realEmail, realPwd);
      toastRef.current?.toast("Welcome " + realEmail, "success", 4000, {
        vertical: "top",
        horizontal: "center",
      });
      window.location.href = "/myAccount";
    } catch (error: any) {}
  };

  const fncConfirm = async () => {
    if (verifyCode === "" || verifyCode === undefined) {
      toastRef.current?.toast("Please enter your verify code.", "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
      verifyCodeRef.current.focust();
      return;
    }

    try {
      await Auth.confirmSignUp(realEmail, verifyCode);
      toastRef.current?.toast("Success verify.", "success", 4000, {
        vertical: "top",
        horizontal: "center",
      });
      setNeedConfirm(false);
      autoSignIn();
    } catch (error: any) {
      toastRef.current?.toast("Invalid verify code.", "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
      verifyCodeRef.current.focust();
    }
  };

  const fncResendCode = async () => {
    try {
      await Auth.resendSignUp(realEmail);
      toastRef.current?.toast("Send your verify code.", "success", 4000, {
        vertical: "top",
        horizontal: "center",
      });
      verifyCodeRef.current.focust();
    } catch (err) {
      console.error("error resending code: ", err);
    }
  };

  const fncForgot = async () => {
    if (userEmail === "" || userEmail === undefined) {
      toastRef.current?.toast("Please enter your email.", "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
      userEmailRef.current.focus();
      return;
    }

    setRealEmail(userEmail);
    setRealPwd(userPwd);

    try {
      await Auth.forgotPassword(userEmail);
      setNeedNewPwd(true);
      toastRef.current?.toast(
        "Send verify code to your email.",
        "success",
        4000,
        { vertical: "top", horizontal: "center" }
      );
      verifyCodeRef.current.focust();
    } catch (err) {
      console.error(err);
    }
  };

  const fncNewPwd = async () => {
    if (verifyCode === "" || verifyCode === undefined) {
      toastRef.current?.toast("Please enter your verify code.", "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
      verifyCodeRef.current.focust();
      return;
    }

    if (userPwd === "" || userPwd === undefined) {
      toastRef.current?.toast(
        "Please enter your new password.",
        "error",
        4000,
        { vertical: "top", horizontal: "center" }
      );
      userPwdRef.current.focus();
      return;
    }
    if (userPwdConfirm === "" || userPwdConfirm === undefined) {
      toastRef.current?.toast(
        "Please enter new password again.",
        "error",
        4000,
        { vertical: "top", horizontal: "center" }
      );
      userPwdConfirmRef.current.focus();
      return;
    }
    if (userPwd.length < 6) {
      toastRef.current?.toast(
        "Please enter password more than 6 char",
        "error",
        4000,
        { vertical: "top", horizontal: "center" }
      );
      userPwdRef.current.focus();
      return;
    }
    if (userPwd !== userPwdConfirm) {
      toastRef.current?.toast(
        "Passwords do not match. (new & again)",
        "error",
        4000,
        { vertical: "top", horizontal: "center" }
      );
      userPwdConfirmRef.current.focus();
      return;
    }

    setRealEmail(userEmail);
    setRealPwd(userPwd);

    try {
      const data = await Auth.forgotPasswordSubmit(
        realEmail,
        verifyCode,
        userPwd
      );
      toastRef.current?.toast(
        "Success change your new password",
        "success",
        4000,
        { vertical: "top", horizontal: "center" }
      );
      setNeedConfirm(false);
      autoSignIn();
    } catch (err) {
      console.error(err);
    }
  };

  const handlePasswordEnter = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") fncSignIn();
  };

  const handleVerifyCodeEnter = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") fncConfirm();
  };

  const fncSignUp = useCallback(() => {
    navigate("/signup");
  }, [navigate]);

  return (
    <div className="wrapper hart-login">
      <div className="hart-login-box">
        <h2>
          <img src="/images/login-logo2.png" />
        </h2>
        <p>홍보에 AI를 더하다. 합대리에 오신걸 환영해요.</p>
        <div className="form-wrap"> H-art를 통해서 접속해주시기 바랍니다.</div>

        <footer>
          <p className="copyright">
            © Hyundai G.F. Holdings. All rights reserved.
          </p>
        </footer>
      </div>
    </div>
  );
};

export default HartLogIn;
