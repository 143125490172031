export interface userState {
  id: string;
  name: string;
  company: string;
  dept: string;
  isAuth: boolean;
  isAdmin: boolean;
  isSuperAdmin: boolean;
  dtype: string;
  apiUrl: string;
}

export interface IMenuItem {
  name: string;
  iconType: string;
  icon: any;
  path: string;
  adminOnly?: boolean;
  children?: Array<IMenuItem>;
}

const isLocalhost =
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1" ||
    window.location.hostname.startsWith("haap-dev");
  
export let openAIAiApiUrl = isLocalhost ? "https://zflxftvdspmtlkyz4sezocwrle0meoeq.lambda-url.ap-northeast-2.on.aws/" : "https://oc7ygy2mm6vitr5m7j3rar3rsq0hbass.lambda-url.ap-northeast-2.on.aws/";
export let naverAiApiUrl = isLocalhost ? "https://qohunltknkmerc6f3m6f3h2e3y0qdayk.lambda-url.ap-northeast-2.on.aws/" : "https://tnjn4ylde3phio7m4qdkdd527q0tkuho.lambda-url.ap-northeast-2.on.aws/";

export const themeSeparator = "^";
