import {
  useCallback,
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import { TextField, Button, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import Toast from "../../utils/Toast";
import LoadingCircle from "../../utils/LoadingCircle";
import { userState } from "../../interface/MainInterface";
import { HttpMainApi } from "../../interface/main-api";
import PopSearchAdmin from "./PopSearchAdmin";

interface propsType {
  userState: userState;
  pressInfo: any;
  callback: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "449px",
  bgcolor: "background.paper",
  border: "0px solid #000",
  borderRadius: "1rem",
  boxShadow: 24,
  p: 3,
};

const mainApi = new HttpMainApi();

const PopToss = (props: propsType, ref: any) => {
  const toastRef: any = useRef();
  const commentRef: any = useRef();
  const searchAdminRef: any = useRef();

  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [comment, setComment] = useState("");
  const [tossId, setTossId] = useState("");
  const [tossName, setTossName] = useState("");

  useEffect(() => {
    return () => {
      setOpenModal(false);
      setIsLoading(false);
      setComment("");
      setTossId("");
      setTossName("");
    };
  }, []);

  const open = useCallback(() => {
    setOpenModal(true);
  }, []);

  const close = () => {
    setComment("");
    setTossId("");
    setTossName("");
    setOpenModal(false);
  };

  const searchAdmin = () => {
    searchAdminRef.current.open();
  };

  // 담당자 토스
  const toss = async () => {
    let comfirmMsg =
      "토스할 담당자를 선택하지 않았습니다.\n해당 보도자료를 접수전 상태로 전환하시겠습니까?";
    if (tossId !== "") {
      comfirmMsg = `해당 보도자료를 ${tossName}에게 토스하시겠습니까?`;
    }
    if (!window.confirm(comfirmMsg)) return;
    // 홍보실 전송 comment 저장
    // 보도자료 상태 변경
    setIsLoading(true);
    const param: any = {
      command: "toss_press",
      press_info: props.pressInfo,
      comment_info: {
        user_id: props.userState.id,
        user_name: props.userState.name,
        type: "toss",
        comment: comment,
      },
      toss_user_info: {
        user_id: tossId,
        user_name: tossName,
      },
    };

    const res = await mainApi.post(param);
    if (res.code === "200") {
      await props.callback("reload_toss");
      close();
    } else {
      console.error(res.response.error_msg);
      toastRef.current?.toast("" + res.response.error_msg, "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
    }
    setIsLoading(false);
  };

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    open,
  }));

  return (
    <>
      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack direction="column" spacing={2} sx={{ height: "100%" }}>
            <Stack
              direction="row"
              spacing={2}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Typography
                gutterBottom
                sx={{ lineHeight: "40px", fontWeight: "800 !important" }}
              >
                담당자 토스
              </Typography>
              <Tooltip title="창닫기">
                <IconButton aria-label="close" onClick={close}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Stack>
            <Stack direction={"row"} spacing={2}>
              <TextField
                id="txtTossName"
                value={tossName}
                label="토스할 담당자"
                size="small"
                placeholder="토스할 담당자를 선택하세요."
                type="search"
                autoComplete="off"
                inputProps={{ enterKeyHint: "Enter" }}
              />
              <Tooltip title="담당자 찾기">
                <Button
                  variant="contained"
                  onClick={searchAdmin}
                  sx={{ borderRadius: "7px" }}
                >
                  <PersonSearchIcon />
                </Button>
              </Tooltip>
              <Tooltip title="담당자 초기화">
                <Button
                  variant="outlined"
                  onClick={() => {
                    setTossId("");
                    setTossName("");
                  }}
                  sx={{ borderRadius: "7px" }}
                >
                  <PersonRemoveIcon />
                </Button>
              </Tooltip>
            </Stack>
            <TextField
              inputRef={commentRef}
              id="txtComment"
              value={comment}
              label="의견"
              placeholder="남기실 의견을 입력하세요."
              autoFocus
              fullWidth
              multiline
              rows={11}
              onChange={(e) => {
                setComment(e.target.value);
              }}
              type="search"
              autoComplete="off"
              inputProps={{ enterKeyHint: "Enter" }}
            />
            <Stack
              direction="row"
              spacing={2}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button variant="outlined" onClick={close} disabled={isLoading}>
                취소
              </Button>

              <Button variant="contained" onClick={toss} disabled={isLoading}>
                {tossId === "" ? "접수 취소" : "토스"}
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
      <PopSearchAdmin
        ref={searchAdminRef}
        callback={(param: any) => {
          if (param.command === "set_toss_user") {
            setTossId(param.userId);
            setTossName(param.userName);
          }
        }}
      />
      <Toast ref={toastRef} />
      <LoadingCircle loading={isLoading} />
    </>
  );
};

export default forwardRef(PopToss);
