import { useEffect, useRef } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import Box from "@mui/material/Box";

import MainPage from "../page/mainPage/mainPage";
import SignUp from "../page/signUp/SignUp";
import SignIn from "../page/signIn/SignIn";
import MakePress from "../page/myPress/MakePress";
import MyTotalList from "../page/myWorkSpace/MyTotalList";
import MyCompleteList from "../page/myWorkSpace/MyCompleteList";
import MyIngList from "../page/myWorkSpace/MyIngList";
import RequestHongbo from "../page/myWorkSpace/RequestHongbo";
import MyHongboIng from "../page/myWorkSpace/MyHongboIng";
import MyTheme from "../page/theme/MyTheme";
import CommonTheme from "../page/theme/CommonTheme";
import Settings from "../page/account/Settings";
import SearchPress from "../page/search/SearchPress";
import MngUser from "../page/mngUser/MngUser";

import { userState } from "../interface/MainInterface";
import * as AuthMain from "../auth/AuthMain";
import Toast from "../utils/Toast";
import PopThemeSelect from "../page/theme/PopThemeSelect";
import SearchFilterPress from "../page/search/SearchFilterPress";
import OptionPage from "../page/optionPage/OptionPage";
import HartLogIn from "../page/signIn/HartLogIn";
import PendingApproval from "../page/signIn/PendingApproval";
import MngUser2 from "../page/mngUser/MngUser2";

interface propsType {
  userState: userState;
  drawerWidth: number;
  setSelectedMenu: any;
  winSize: string;
  getLeftCount: any;
  searchResult: any;
  setSearchResult: any;
}

const MainConteiner = (props: propsType) => {
  const location = useLocation();
  const toastRef: any = useRef();

  useEffect(() => {
    const sessionCheck = async () => {
      try {
        if (!AuthMain.getSession() && !(await AuthMain.checkAuthenticated())) {
          moveHome();
        } else {
          props.setSelectedMenu(`/${location.pathname.split("/")[1]}`);
        }
      } catch (error) {
        moveHome();
      }
    };

    const moveHome = () => {
      toastRef.current?.toast(
        "세션이 종료되었습니다.\n로그인 화면으로 이동합니다.",
        "error",
        4000,
        {
          vertical: "top",
          horizontal: "center",
        }
      );
      setTimeout(() => {
        window.location.href = "/hartlogin";
      }, 3000);
    };

    // console.log("location.pathname :", location.pathname);
    if (
      location.pathname === "/" ||
      location.pathname === "/signin" ||
      location.pathname === "/signup" ||
      location.pathname === "/hartlogin" ||
      location.pathname === "/unapproved"
    ) {
      props.setSelectedMenu(`/${location.pathname.split("/")[1]}`);
    } else {
      sessionCheck();
    }
  }, [location, props]);

  return (
    <Box
      sx={{
        ml: `${props.drawerWidth}px`,
        transition: "margin 0.5s",
        height: "calc(100vh - 44px)",
      }}
    >
      <Routes>
        <Route path="/" element={<MainPage userState={props.userState} />} />
        <Route
          path="/signup"
          element={<SignUp userState={props.userState} />}
        />
        <Route
          path="/signin"
          element={<SignIn userState={props.userState} />}
        />
        <Route
          path="/hartlogin"
          element={<HartLogIn userState={props.userState} />}
        />
        <Route
          path="/unapproved"
          element={<PendingApproval />}
        />

        {props.userState.isAuth && (
          <>
            <Route
              path="/makePress/:pressSeq"
              element={
                <MakePress
                  userState={props.userState}
                  getLeftCount={props.getLeftCount}
                />
              }
            />
            <Route
              path="/myTotalList"
              element={<MyTotalList userState={props.userState} />}
            />
            <Route
              path="/myCompleteList"
              element={<MyCompleteList userState={props.userState} />}
            />
            <Route
              path="/myIngList"
              element={<MyIngList userState={props.userState} />}
            />
            <Route
              path="/myTheme"
              element={
                <MyTheme
                  userState={props.userState}
                  startMode={"view"}
                  selectTheme={() => {}}
                  height="calc(100vh - 151px)"
                />
              }
            />

            <Route
              path="/searchPress"
              element={
                <SearchPress
                  userState={props.userState}
                  searchResult={props.searchResult}
                  setSearchResult={props.setSearchResult}
                />
              }
            />
            <Route
              path="/searchFilterPress"
              element={
                <SearchFilterPress
                  userState={props.userState}
                  searchResult={props.searchResult}
                  setSearchResult={props.setSearchResult}
                />
              }
            />
            <Route
              path="/optionPage"
              element={
                <OptionPage
                  userState={props.userState}
                  getLeftCount={props.getLeftCount}
                />
              }
            />
            <Route
              path="/setting"
              element={<Settings userState={props.userState} />}
            />
            {props.userState.isAdmin && (
              <>
                <Route
                  path="/mngUser"
                  element={<MngUser2 userState={props.userState} />}
                />
                {/* <Route
                  path="/mngUser2"
                  element={<MngUser2 userState={props.userState} />}
                /> */}
                <Route
                  path="/commonTheme"
                  element={
                    <CommonTheme
                      userState={props.userState}
                      startMode={"view"}
                      selectTheme={() => {}}
                      height="calc(100vh - 151px)"
                    />
                  }
                />
                <Route
                  path="/requestHongbo"
                  element={<RequestHongbo userState={props.userState} />}
                />
                <Route
                  path="/myHongboIng"
                  element={<MyHongboIng userState={props.userState} />}
                />
              </>
            )}
          </>
        )}
        
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
      <Toast ref={toastRef} />
    </Box>
  );
};

export default MainConteiner;
