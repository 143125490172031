import { useState, useRef, useEffect } from "react";

import {
  Stack,
  Box,
  Typography,
  Grid,
  Button,
  Autocomplete,
  TextField,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";

import LoadingCircle from "../../utils/LoadingCircle";
import Toast from "../../utils/Toast";

import { userState } from "../../interface/MainInterface";
import { HttpMainApi } from "../../interface/main-api";

import CardTheme from "./CardTheme";
import PopThemeMake from "./PopThemeMake";
import CategoryLoader from "./CategoryLoader";

interface propsType {
  userState: userState;
  startMode: string;
  selectTheme: any;
  height: string;
}

const mainApi = new HttpMainApi();

const MyTheme = (props: propsType) => {
  const toastRef: any = useRef();
  const makeThemeRef: any = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const [themeList, setThemeList] = useState<any>([]);
  const [category, setCategory] = useState<string | null>("전체");
  const [themeCategoryList, setThemeCategoryList] = useState<string[]>([]);
  useEffect(() => {
    return () => {
      setIsLoading(false);
      setThemeList([]);
    };
  }, []);

  useEffect(() => {
    if (props.userState.id !== "") {
      getMyThemeList();
    }
  }, [props.userState.id]);

  const getMyThemeList = async () => {
    const param: any = {
      command: "get_theme_list",
      type: "my",
      category: category,
      user_id: props.userState.id,
    };

    const res = await mainApi.post(param);
    if (res.code === "200") {
      // console.log("res : ", res);
      setThemeList(res.response.theme_list);
    }
  };

  useEffect(() => {
    if (category !== null) {
      getMyThemeList();
    }
  }, [category]);

  const openMakeTheme = () => {
    makeThemeRef.current.open();
  };

  return (
    <>
      <Stack
        direction={"column"}
        spacing={2}
        sx={{ p: "32px", pb: "16px !important" }}
      >
        <Box className="sub-header-root">
          <Stack
            direction={"row"}
            spacing={2}
            sx={{ width: "100%", justifyContent: "space-between" }}
          >
            <Typography variant="h5" gutterBottom className="sub-header-title">
              나의 테마
            </Typography>
            <Stack direction={"row"} spacing={2}>
              <>
                <CategoryLoader onCategoriesLoaded={setThemeCategoryList} />

                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={themeCategoryList}
                  sx={{ minWidth: 300 }}
                  size="small"
                  value={category}
                  onChange={(event: any, newValue: string | null) => {
                    setCategory(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Category" />
                  )}
                />
              </>

              <Button
                variant="contained"
                onClick={openMakeTheme}
                startIcon={<AddIcon />}
                sx={{ borderRadius: "7px" }}
              >
                테마 만들기
              </Button>
            </Stack>
          </Stack>
        </Box>
        <Stack
          direction={"column"}
          spacing={1}
          className="theme-select-scroll"
          sx={{ height: props.height }}
        >
          <Box sx={{ width: "100%" }}>
            <Grid container spacing={2}>
              {themeList.map((themeInfo: any, index: number) => (
                <Grid
                  key={`pressCard-${index}`}
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                >
                  <CardTheme
                    userState={props.userState}
                    themeInfo={themeInfo}
                    startMode={props.startMode}
                    refresh={() => {
                      getMyThemeList();
                    }}
                    selectTheme={(theme: any) => {
                      props.selectTheme(theme);
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Stack>
      </Stack>
      <PopThemeMake
        ref={makeThemeRef}
        userState={props.userState}
        type="my"
        refresh={getMyThemeList}
        themeCategoryList={themeCategoryList}
      />
      <Toast ref={toastRef} />
      <LoadingCircle loading={isLoading} />
    </>
  );
};

export default MyTheme;
