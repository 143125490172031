const PendingApproval = () => {
    return (
        <div className="wrapper hart-login">
          <div className="hart-login-box">
            <h2>
              <img src="/images/login-logo2.png" />
            </h2>
            <p>홍보에 AI를 더하다. 합대리에 오신걸 환영해요.</p>
            <div className="form-wrap"> 담당자의 승인이 필요합니다. 로그인 계정을 담당자에게 전달해주세요.</div>
    
            <footer>
              <p className="copyright">
                © Hyundai G.F. Holdings. All rights reserved.
              </p>
            </footer>
          </div>
        </div>
      );
}

export default PendingApproval;