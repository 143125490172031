import React, { useState, useEffect } from "react";
import { HttpMainApi } from "../../interface/main-api";
import LoadingCircle from "../../utils/LoadingCircle";
const mainApi = new HttpMainApi();

interface ThemeCategoryLoaderProps {
  onCategoriesLoaded: (categories: string[]) => void;
}

const CategoryLoader: React.FC<ThemeCategoryLoaderProps> = ({
  onCategoriesLoaded,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const getThemeCategoryList = async () => {
    const param: any = {
      command: "get_theme_category_list",
    };
    setIsLoading(true);
    try {
      const res = await mainApi.post(param);
      if (res.code === "200") {
        let tempList = ["전체"];
        for (const categoryInfo of res.response.theme_category_list) {
          tempList.push(categoryInfo.sk);
        }
        onCategoriesLoaded(tempList);
      } else {
        console.error(res.response.error_msg);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getThemeCategoryList();
  }, []); // 컴포넌트 마운트 시 카테고리 목록 불러오기

  return <LoadingCircle loading={isLoading} />;
};

export default CategoryLoader;
