import { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";

import {
  Box,
  Paper,
  Stack,
  Typography,
  TextField,
  Button,
  IconButton,
  Divider,
  Tooltip,
  Chip,
} from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import StarIcon from "@mui/icons-material/Star";
import SearchIcon from "@mui/icons-material/Search";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArticleIcon from "@mui/icons-material/Article";
import { HttpMainApi } from "../../interface/main-api";
import { userState } from "../../interface/MainInterface";
import { CommonUtils } from "../../utils/common_utils";

import LoadingCircle from "../../utils/LoadingCircle";
import Toast from "../../utils/Toast";

import PopCategory from "./PopCategory";
import PopOneShotMake from "./PopOneShotMake";

interface propsType {
  userState: userState;
  themeInfo: any;
  startMode: string;
  refresh: any;
  selectTheme: any;
}

const mainApi = new HttpMainApi();
const utils = new CommonUtils();

const CardTheme = (props: propsType) => {
  const toastRef: any = useRef();
  const formNameRef: any = useRef();
  const categoryRef: any = useRef();
  const categorySelectRef: any = useRef();
  const makeOneShotRef: any = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const [mode, setChangeMode] = useState(props.startMode);
  const [formName, setFormName] = useState("");
  const [formList, setFormList] = useState<any>([]);
  const [formData, setFormData] = useState<any>({});
  const [category, setCategory] = useState("");
  const [open, setOpen] = useState(false);
  const [themeTitle, setThemeTitle] = useState(props.themeInfo.title);
  const [bookmark, setBookmark] = useState("N");
  useEffect(() => {
    return () => {
      setIsLoading(false);
      setChangeMode(props.startMode);
      setFormName("");
      setFormList([]);
      setFormData({});
      setCategory("");
      setOpen(false);
    };
  }, []);

  useEffect(() => {
    setFormList(props.themeInfo.form_list);
    setFormData(props.themeInfo.form_example);
    if (props.themeInfo.category === undefined) setCategory("");
    else setCategory(props.themeInfo.category);
    setChangeMode(props.startMode);
    setBookmark(props.themeInfo.bookmark_yn);
  }, [props.themeInfo]);

  // 테마 항목 추가
  const addFromList = () => {
    if (formName !== "") {
      let newList: any = [...formList, formName];
      setFormName("");
      setFormList(newList);
    }
  };

  // 테마 항목 삭제(메모리상에만 처리 별도 저장 클릭시 DB 저장)
  const removeFormData = (index: number, value: string) => {
    let oldList: any = [...formList];
    oldList.splice(index, 1);
    setFormList(oldList);
  };

  // 테마 수정
  const putThemeInfo = async () => {
    let newThemeInfo: any = { ...props.themeInfo };
    newThemeInfo.title = themeTitle;
    newThemeInfo.form_list = formList;
    newThemeInfo.form_example = formData;
    newThemeInfo.category = category;
    newThemeInfo.user_id = props.userState.id;
    newThemeInfo.user_name = props.userState.name;

    const param: any = {
      command: "put_theme_info",
      theme_info: newThemeInfo,
    };
    setIsLoading(true);
    const res = await mainApi.post(param);
    if (res.code === "200") {
      await props.refresh();
    } else {
      console.error(res.response.error_msg);
      toastRef.current?.toast("" + res.response.error_msg, "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
    }
    setIsLoading(false);
  };

  const bookmarkThemeInfo = async (pk: string, sk: string) => {
    let text =
      "이 공통 테마를 대표 테마로 선정합니다. \n(보도자료 생성시 공통 테마를 선택하지 않은 자료에 이 테마의 one shot을 사용합니다.)";
    let bookmark_yn = "";
    if (bookmark === "Y") {
      text = `[${category}]의 공통 테마에서 대표 테마를 해제합니다.`;
      bookmark_yn = "N";
    } else {
      text = `이 공통 테마를 대표 테마로 선정합니다. \n[${category}] 보도자료 생성시 공통 테마를 선택하지 않은 자료에 이 테마의 [${category}] one shot을 사용합니다.)`;
      bookmark_yn = "Y";
    }
    if (!window.confirm(text)) return;

    const param: any = {
      command: "bookmark_theme_info",
      pk: pk,
      sk: sk,
      bookmark_yn: bookmark_yn,
    };

    setIsLoading(true);
    const res = await mainApi.post(param);
    if (res.code === "200") {
      if (bookmark === "Y") {
        setBookmark("N");
      } else {
        setBookmark("Y");
      }
    } else {
      console.error(res.response.error_msg);
      toastRef.current?.toast("" + res.response.error_msg, "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
    }
    setIsLoading(false);
  };

  // 테마 삭제
  const deleteThemeInfo = async (pk: string, sk: string) => {
    if (bookmark === "Y") {
      alert("대표 테마 해제 후 삭제가 가능합니다.");
      return;
    }
    if (!window.confirm("선택한 테마를 삭제합니까?")) return;

    const param: any = {
      command: "delete_theme_info",
      pk: pk,
      sk: sk,
    };

    setIsLoading(true);
    const res = await mainApi.post(param);
    if (res.code === "200") {
      await props.refresh();
    } else {
      console.error(res.response.error_msg);
      toastRef.current?.toast("" + res.response.error_msg, "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
    }
    setIsLoading(false);
  };

  // 테마 선택
  const selectTheme = () => {
    let updatedThemeInfo = { ...props.themeInfo };
    props.selectTheme(updatedThemeInfo);
  };

  // 카테고리 선택 버튼 클릭
  const openSelectCategory = () => {
    categorySelectRef.current.open();
  };
  // 샘플 기사 추가

  const openOneShotModal = () => {
    makeOneShotRef.current.open();
  };

  // 제목 수정
  const handleThemeTitleChange = (event: any) => {
    setThemeTitle(event.target.value);
  };

  return (
    <>
      <Box sx={{ position: "relative", zIndex: "100", minWidth: "250px" }}>
        <Paper
          sx={{
            width: "100%",
            height: "100%",
            borderRadius: "7px",
            backgroundColor: "rgba(70, 117, 231, 0.07)",
            color: "inherit",
            padding: "16px",
            boxSizing: "border-box",
          }}
          elevation={0}
        >
          <Stack direction={"column"} spacing={1}>
            {/* 테마 제목 */}
            <Box>
              {mode === "edit" ? (
                // 편집 모드일 때 input 필드를 보여줌
                <TextField
                  fullWidth
                  variant="outlined"
                  value={themeTitle}
                  onChange={handleThemeTitleChange}
                />
              ) : (
                // 읽기 전용 모드일 때 Typography를 보여줌
                <Box>
                  <Typography
                    sx={{
                      color: "#1976d2",
                      fontSize: "18px",
                      fontWeight: "800",
                      textOverflow: "ellipsis",
                      justifyContent: "left",
                    }}
                  >
                    {props.themeInfo.category !== "" &&
                    props.themeInfo.category !== undefined
                      ? `[${props.themeInfo.category}] `
                      : ""}
                    {props.themeInfo.title}
                  </Typography>
                </Box>
              )}
            </Box>
            <Stack direction={"column"} spacing={0}>
              <Stack
                direction={"row"}
                spacing={2}
                sx={{ justifyContent: "space-between" }}
              >
                <Typography
                  sx={{
                    fontSize: "11px !important",
                    color: "#00000080 !important",
                    lineHeight: "20px !important",
                  }}
                >{`Created : ${dayjs(props.themeInfo.create_time).format(
                  "YYYY-MM-DD"
                )}`}</Typography>
                {props.themeInfo.user_name !== undefined && (
                  <Typography
                    sx={{
                      fontSize: "11px !important",
                      color: "#00000080 !important",
                      lineHeight: "20px !important",
                    }}
                  >
                    {`Owner : ${props.themeInfo.user_name}`}
                  </Typography>
                )}
              </Stack>

              <Stack
                direction={"row"}
                spacing={2}
                sx={{ justifyContent: "space-between" }}
              >
                <Stack
                  direction={"row"}
                  spacing={0}
                  sx={{ justifyContent: "flex-start" }}
                >
                  <Tooltip title={open ? "상세정보 닫기" : "상세정보 열기"}>
                    <IconButton
                      onClick={() => {
                        setOpen(!open);
                        setChangeMode(mode);
                      }}
                    >
                      {open ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                  </Tooltip>
                </Stack>
                <Stack
                  direction={"row"}
                  spacing={0}
                  sx={{ justifyContent: "flex-end" }}
                >
                  {mode === "select" && (
                    <>
                      <Tooltip title="일반 기사">
                        <Stack direction="row" spacing={1}>
                          <Chip
                            label="테마 선택"
                            variant="outlined"
                            onClick={() => selectTheme()}
                          />
                        </Stack>
                      </Tooltip>
                    </>
                  )}
                  {mode === "view" && (
                    <>
                      <Tooltip title="샘플기사">
                        <IconButton onClick={openOneShotModal}>
                          <ArticleIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="수정">
                        <IconButton
                          onClick={() => {
                            setChangeMode("edit");
                            setOpen(true);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="삭제">
                        <IconButton
                          onClick={() => {
                            deleteThemeInfo(
                              props.themeInfo.pk,
                              props.themeInfo.sk
                            );
                          }}
                        >
                          <DeleteForeverIcon />
                        </IconButton>
                      </Tooltip>
                      {props.themeInfo.type === "common" && (
                        <Tooltip title="대표 테마">
                          <IconButton
                            onClick={() => {
                              bookmarkThemeInfo(
                                props.themeInfo.pk,
                                props.themeInfo.sk
                              );
                            }}
                          >
                            {bookmark === "Y" ? (
                              <StarIcon sx={{ color: "#ef6961" }} />
                            ) : (
                              <StarOutlineIcon />
                            )}
                          </IconButton>
                        </Tooltip>
                      )}
                    </>
                  )}
                  {mode === "edit" && (
                    <>
                      <Tooltip title="저장">
                        <IconButton
                          onClick={() => {
                            putThemeInfo();
                          }}
                        >
                          <SaveIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="취소">
                        <IconButton
                          onClick={() => {
                            setChangeMode("view");
                          }}
                        >
                          <CancelIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </Stack>
              </Stack>
            </Stack>
            <Divider />
            {mode === "edit" ? (
              <>
                {props.themeInfo.type === "common" ? (
                  <Stack
                    direction={"row"}
                    spacing={0}
                    className="div-template-add"
                  >
                    <Box className="text-template-add">
                      <TextField
                        id="txtCategory"
                        inputRef={categoryRef}
                        value={category}
                        placeholder="카테고리를 선택하세요."
                        fullWidth
                        size="small"
                        type="search"
                        autoComplete="off"
                        inputProps={{ enterKeyHint: "Enter" }}
                      />
                    </Box>
                    <Box className="btn-template-add">
                      <Button
                        variant="text"
                        onClick={openSelectCategory}
                        fullWidth
                        sx={{
                          height: "40px",
                          fontWeight: "700",
                          color: "#ffffff",
                        }}
                        startIcon={<SearchIcon />}
                      >
                        카테고리
                      </Button>
                    </Box>
                  </Stack>
                ) : (
                  <></>
                )}
                <Stack
                  direction={"row"}
                  spacing={0}
                  className="div-template-add"
                >
                  <Box className="text-template-add">
                    <TextField
                      id="txtFormName"
                      inputRef={formNameRef}
                      value={formName}
                      placeholder="추가할 데이터 항목을 넣어주세요."
                      autoFocus
                      fullWidth
                      onChange={(e) => {
                        setFormName(e.target.value);
                      }}
                      size="small"
                      onKeyDown={(e) => {
                        if (e.key === "Enter" && !e.nativeEvent.isComposing)
                          addFromList();
                      }}
                      type="search"
                      autoComplete="off"
                      inputProps={{ enterKeyHint: "Enter" }}
                    />
                  </Box>
                  <Box className="btn-template-add">
                    <Button
                      variant="text"
                      onClick={addFromList}
                      fullWidth
                      sx={{
                        height: "40px",
                        fontWeight: "700",
                        color: "#ffffff",
                      }}
                    >
                      추가
                    </Button>
                  </Box>
                </Stack>
              </>
            ) : (
              <></>
            )}
            <Stack
              direction={"column"}
              spacing={1}
              className="template-form-list"
              sx={open ? { display: "" } : { display: "none" }}
            >
              {formList.map((value: string, index: number) => (
                <Stack key={`formData-${index}`} direction={"row"} spacing={0}>
                  {mode === "edit" && (
                    <Stack direction={"column"} spacing={0}>
                      <IconButton
                        sx={{ p: 0 }}
                        color="primary"
                        disabled={index === 0}
                        onClick={() => {
                          let newList: any = [...formList];
                          newList.splice(index, 1);
                          newList.splice(index - 1, 0, value);
                          setFormList(newList);
                        }}
                      >
                        <ExpandLessIcon sx={{ fontSize: "1.3rem" }} />
                      </IconButton>
                      <IconButton
                        sx={{ p: 0 }}
                        color="primary"
                        disabled={index === formList.length - 1}
                        onClick={() => {
                          let newList: any = [...formList];
                          newList.splice(index, 1);
                          newList.splice(index + 1, 0, value);
                          setFormList(newList);
                        }}
                      >
                        <ExpandMoreIcon sx={{ fontSize: "1.3rem" }} />
                      </IconButton>
                    </Stack>
                  )}
                  <Box className="template-content-title">{value}</Box>
                  <Box className="template-content-content">
                    <TextField
                      size="small"
                      fullWidth
                      multiline
                      placeholder={"예시를 입력하세요."}
                      value={utils.isEmptyObj(formData) ? "" : formData[value]}
                      onChange={(e) => {
                        const newData: any = { [value]: e.target.value };
                        setFormData({ ...formData, ...newData });
                      }}
                      type="search"
                      autoComplete="off"
                      inputProps={{ enterKeyHint: "Enter" }}
                    />
                  </Box>
                  {mode === "edit" && (
                    <Tooltip title="항목 삭제">
                      <IconButton
                        onClick={() => {
                          removeFormData(index, value);
                        }}
                      >
                        <RemoveCircleOutlineIcon sx={{ color: "#ff000090" }} />
                      </IconButton>
                    </Tooltip>
                  )}
                </Stack>
              ))}
            </Stack>
          </Stack>
        </Paper>
      </Box>
      <Toast ref={toastRef} />
      <LoadingCircle loading={isLoading} />
      <PopOneShotMake
        ref={makeOneShotRef}
        userState={props.userState}
        themeInfo={props.themeInfo}
        type="common"
        refresh={props.refresh}
      />
      <PopCategory
        ref={categorySelectRef}
        userState={props.userState}
        callback={(param: any) => {
          if (param.command === "set_theme_category") {
            setCategory(param.category);
          }
        }}
      />
    </>
  );
};

export default CardTheme;
