import { Box, Button } from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";

interface propsType {
  callback: any;
}

const AttachFilesTab = (props: propsType) => {
  return (
    <Box>
      <button
        className="file-list btn-ghost"
        onClick={() => {
          props.callback("open_fileviewer");
        }}
      >
        <img src="/images/btn-file.png" className="shake" />
        <span>첨부파일</span>
      </button>
    </Box>
  );
};

export default AttachFilesTab;
