import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

import { Box, Typography, Avatar, Tooltip, TextField } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { HttpMainApi } from "../../interface/main-api";
import { userState } from "../../interface/MainInterface";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import { CommonUtils } from "../../utils/common_utils";
import LoadingCircle from "../../utils/LoadingCircle";
import Toast from "../../utils/Toast";
import { HttpCustomApi } from "../../interface/custom-api";

interface propsType {
  userState: userState;
  pressInfo: any;
  refresh: any;
  openNewWindow: boolean;
}

const mainApi = new HttpMainApi();
const vectoCoreApi = new HttpCustomApi(process.env.REACT_APP_VECTOR_CORE);
const utils = new CommonUtils();

const CardPress = (props: propsType) => {
  let navigate = useNavigate();

  const toastRef: any = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [pressInfo, setPressInfo] = useState<any>({});

  const inputRef: any = useRef();
  const [editResult, setEditResult] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [visibility, setVisibility] = useState("Y");

  useEffect(() => {
    return () => {
      setIsLoading(false);
    };
  }, []);

  useEffect(() => {
    get_press_info();
  }, [props.pressInfo]);

  useEffect(() => {
    // 특정 영역 외 클릭 시 발생하는 이벤트
    function handleFocus(e: MouseEvent) {
      if (inputRef.current && !inputRef.current.contains(e.target)) {
        // input 체크 해제
        inputRef.current.blur();
        setEditMode(false);
      }
    }
    // 이벤트 리스너에 handleFocus 함수 등록
    document.addEventListener("mouseup", handleFocus);
    return () => {
      document.removeEventListener("mouseup", handleFocus);
    };
  }, [inputRef]);

  const get_press_info = async () => {
    const param: any = {
      command: "get_press_info",
      sk: props.pressInfo.sk,
    };

    // setIsLoading(true);
    const res = await mainApi.post(param);
    if (res.code === "200") {
      // console.log("press info : ", res.response.press_info);
      setPressInfo(res.response.press_info);
      setVisibility(res.response.press_info.open_yn);
    } else {
    }
  };

  // 결과 실시간 저장
  const changeResult = async (newResult: string) => {
    // 결과 저장
    let newPressInfo = { ...pressInfo };

    newPressInfo.title = newResult;

    setPressInfo(newPressInfo);
    const paramPress: any = {
      command: "put_press_info",
      press_info: newPressInfo,
    };

    await mainApi.post(paramPress);
    get_press_info();
  };

  const deletePressInfo = async (pk: string, sk: string, id: string) => {
    if (
      !window.confirm(
        "보도자료를 삭제합니까?\n삭제한 데이터는 복구할 수 없습니다."
      )
    )
      return;
    if (props.userState.id !== id && !props.userState.isAdmin) {
      toastRef.current?.toast(
        "본인의 보도자료만 삭제할 수 있습니다.",
        "error",
        4000,
        { vertical: "top", horizontal: "center" }
      );
      return;
    }
  
    if (props.pressInfo.press_status === "COMPLETE") {
      // itne vectocore db delete
      const deleteParam: any = {
        command: "delete_doc",
        index_name: "naverx-haap-search",
        doc_id: sk,
      }

      const res = await vectoCoreApi.auth_request("api", deleteParam, process.env.REACT_APP_TENANT_KEY);
      if (res.code !== "200")  {
        console.error(res.response.error_msg);
        toastRef.current?.toast("Error : " + res.response.error_msg, "error", 3000, { vertical: "top", horizontal: "center" });
      }

      // extream search db delete
      const extParam: any = {
        command: "delete_extream_data",
        index_name: "naverx-haap-search",
        doc_id: sk,
      }

      const extRes = await vectoCoreApi.auth_request("api", extParam, process.env.REACT_APP_TENANT_KEY);
      console.log("extRes : ", extRes);
      if (extRes.code !== "200") {
        console.error(extRes.response.error_msg);
        toastRef.current?.toast("Error : " + extRes.response.error_msg, "error", 3000, { vertical: "top", horizontal: "center" });
      }
    }

    const param: any = {
      command: "delete_press_info",
      pk: pk,
      sk: sk,
    };

    setIsLoading(true);
    const res = await mainApi.post(param);
    if (res.code === "200") {
      props.refresh();
    } else {
      console.error(res.response.error_msg);
      toastRef.current?.toast("" + res.response.error_msg, "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
    }
    setIsLoading(false);
  };

  const visibilityPressInfo = async (pk: string, sk: string) => {
    let text = "이 보도자료를 검색에서 안보이도록 합니다.";
    let open_yn = "Y";
    if (visibility === "N") {
      text = "이 보도자료를 검색에서 보이도록 합니다.";
      open_yn = "Y";
    } else {
      text = "이 보도자료를 검색에서 안보이도록 합니다.";
      open_yn = "N";
    }
    if (!window.confirm(text)) return;

    const param: any = {
      command: "visibility_press_info",
      pk: pk,
      sk: sk,
      open_yn: open_yn,
    };

    setIsLoading(true);
    const res = await mainApi.post(param);
    if (res.code === "200") {
      if (visibility === "N") {
        setVisibility("Y");
      } else {
        setVisibility("N");
      }
    } else {
      console.error(res.response.error_msg);
      toastRef.current?.toast("" + res.response.error_msg, "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
    }
    setIsLoading(false);
  };

  const getProgressWidth = (): string => {
    switch (pressInfo.press_status) {
      case "CREATE":
      case "RETURNED":
      case "REJECTED":
        return "30%";
      case "HONGBO_ING":
        return "60%";
      case "COMPLETE":
        return "100%";
      default:
        return "0%";
    }
  };

  const getProgressClassName = (): string => {
    switch (pressInfo.press_status) {
      case "CREATE":
        return "bar-01";
      case "RETURNED":
        return "bar-06"
      case "REJECTED":
        return "bar-04";
      case "HONGBO_ING":
        return pressInfo.hongbo_user_id === "" ||
          pressInfo.hongbo_user_id === undefined
          ? "bar-02"
          : "bar-03";
      case "COMPLETE":
        return "bar-05";
      default:
        return "";
    }
  };

  const getProgressTagClassName = (): string => {
    switch (pressInfo.press_status) {
      case "CREATE":
        return "tag-01";
      case "RETURNED":
        return "tag-06"
      case "REJECTED":
        return "tag-04";
      case "HONGBO_ING":
        return pressInfo.hongbo_user_id === "" ||
          pressInfo.hongbo_user_id === undefined
          ? "tag-02"
          : "tag-03";
      case "COMPLETE":
        return "tag-05";
      default:
        return "";
    }
  };

  const getProgressName = (): string => {
    switch (pressInfo.press_status) {
      case "CREATE":
        return "작성중";
      case "RETURNED":
        return "반려";
      case "REJECTED":
        return "보완 요청";
      case "HONGBO_ING":
        return pressInfo.hongbo_user_id === "" ||
          pressInfo.hongbo_user_id === undefined
          ? "접수대기"
          : "홍보실 접수";
      case "COMPLETE":
        return "완료";
      default:
        return "0%";
    }
  };

  const getProgressTagStyle = (): React.CSSProperties => {
    const width = Math.max(getProgressName().length * 13, 60);
    return {
      width: `${width}px`,
    };
  };
  const [openCard, setOpenCard] = useState(false);
  const cardHight = () => {
    setOpenCard((openCard) => !openCard);
  };
  return (
    <>
      {openCard ? (
        <>
          {!utils.isEmptyObj(pressInfo) && (
            <Box
              sx={{ position: "relative", zIndex: "100", minWidth: "250px" }}
            >
              <div className="writeboard">
                <div
                  className="board-box"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (props.openNewWindow)
                      window.open("/makePress/" + pressInfo.sk, "_blank");
                    else navigate("/makePress/" + pressInfo.sk);
                  }}
                >
                  <dl>
                    <dt className="flex align-items-center justify-content-sb">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "80%", // 컨테이너 전체 너비를 사용하도록 설정
                        }}
                      >
                        {/* 왼쪽 요소들을 감싸는 컨테이너 */}
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <span className="thumb-img random1">
                            <Avatar
                              sx={{
                                bgcolor: "#9d97c7",
                                width: "36px",
                                height: "36px",
                              }}
                            >
                              {pressInfo.user_name[0]}
                            </Avatar>
                          </span>
                          <span style={{ marginRight: "8px" }}>
                            {pressInfo.user_name}
                          </span>
                          <span style={{ marginRight: "8px" }}>
                            {`${dayjs(pressInfo.create_time).format(
                              "YYYY-MM-DD"
                            )}`}
                          </span>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginLeft: "4px",
                            }}
                          >
                            <AccessTimeFilledIcon
                              sx={{ fontSize: "medium", color: "#1976d2" }}
                            />
                            <span
                              style={{ marginLeft: "4px" }}
                            >{`${pressInfo.history_cnt}`}</span>
                          </div>
                        </div>
                      </div>

                      {(pressInfo.press_status === "CREATE" ||
                        pressInfo.press_status === "RETURNED" ||
                        pressInfo.press_status === "REJECTED" ||
                        props.userState.isAdmin) && (
                        <div className="box-btn-wrap">
                          <Tooltip title="제목 수정">
                            <>
                              <button
                                className="btnWrite"
                                title="제목 수정"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setEditResult(pressInfo.title);
                                  setEditMode(true);
                                }}
                              />
                            </>
                          </Tooltip>
                          <Tooltip title="보도자료 삭제">
                            <>
                              <button
                                className="btnDelete"
                                title="보도자료 삭제"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  deletePressInfo(
                                    pressInfo.pk,
                                    pressInfo.sk,
                                    pressInfo.user_id
                                  );
                                }}
                              />
                            </>
                          </Tooltip>
                          {props.userState.isAdmin && (
                            <Tooltip title="보도자료 감추기">
                              <>
                                <button
                                  className={
                                    visibility === "N" ? "btnEyecl" : "btnEye"
                                  }
                                  title="보도자료 감추기"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    visibilityPressInfo(
                                      pressInfo.pk,
                                      pressInfo.sk
                                    );
                                  }}
                                />
                              </>
                            </Tooltip>
                          )}
                        </div>
                      )}
                    </dt>
                    <dd
                      style={
                        !editMode
                          ? {
                              display: "",
                            }
                          : { display: "none" }
                      }
                    >
                      {pressInfo.category ? `[${pressInfo.category}] ` : ""}
                      {pressInfo.title}
                      <br />
                      <span style={{ fontSize: "smaller" }}>
                        {pressInfo.themeTitle
                          ? `테마 : ${pressInfo.themeTitle} `
                          : ""}
                      </span>
                    </dd>
                    <Box>
                      <TextField
                        size="small"
                        fullWidth
                        rows={30}
                        placeholder="제목을 입력하세요."
                        value={editResult}
                        onChange={(e) => {
                          setEditResult(e.target.value);
                        }}
                        type="search"
                        autoComplete="off"
                        inputProps={{ enterKeyHint: "Enter" }}
                        sx={
                          editMode
                            ? {
                                display: "flex",
                                width: "80%",
                                margin: "3px",
                              }
                            : { display: "none" }
                        }
                        inputRef={inputRef}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            // 결과 업데이트
                            console.log("결과 저장");
                            e.stopPropagation();
                            changeResult(editResult);
                            setEditMode(false);
                          }
                        }}
                      />
                    </Box>
                  </dl>
                  <div className="flex justify-content-sb">
                    <div
                      className={`progress-tag ${getProgressTagClassName()}`}
                      style={getProgressTagStyle()}
                    >
                      {getProgressName()}
                    </div>
                    <div>
                      {pressInfo.press_status !== "RETURNED" &&
                        pressInfo.press_status !== "REJECTED" &&
                        pressInfo.hongbo_user_name !== undefined &&
                        pressInfo.hongbo_user_name !== "" && (
                          <Typography
                            sx={{
                              fontSize: "11px !important",
                              color: "#00000080 !important",
                              lineHeight: "20px !important",
                            }}
                          >
                            {`홍보실 담당자 : ${pressInfo.hongbo_user_name}`}
                          </Typography>
                        )}
                      {pressInfo.press_status === "RETURNED" &&
                        pressInfo.reject_user_name !== undefined &&
                        pressInfo.reject_user_name !== "" && (
                          <Typography
                            sx={{
                              fontSize: "11px !important",
                              color: "#00000080 !important",
                              lineHeight: "20px !important",
                            }}
                          >
                            {`반려 담당자 : ${pressInfo.reject_user_name}`}
                          </Typography>
                        )}
                      {pressInfo.press_status === "REJECTED" &&
                        pressInfo.reject_user_name !== undefined &&
                        pressInfo.reject_user_name !== "" && (
                          <Typography
                            sx={{
                              fontSize: "11px !important",
                              color: "#00000080 !important",
                              lineHeight: "20px !important",
                            }}
                          >
                            {`보완요청 담당자 : ${pressInfo.reject_user_name}`}
                          </Typography>
                        )}
                    </div>
                  </div>
                  <div className={`process-bar ${getProgressClassName()}`}>
                    <span style={{ width: getProgressWidth() }} />
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "flex-end" }}
                    onClick={() => {
                      cardHight();
                    }}
                  >
                    <KeyboardArrowUpIcon />
                  </div>
                </div>
              </div>
            </Box>
          )}
        </>
      ) : (
        <>
          {!utils.isEmptyObj(pressInfo) && (
            <Box
              sx={{ position: "relative", zIndex: "100", minWidth: "250px" }}
            >
              <div className="writeboard">
                <div
                  className="board-box"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (props.openNewWindow)
                      window.open("/makePress/" + pressInfo.sk, "_blank");
                    else navigate("/makePress/" + pressInfo.sk);
                  }}
                >
                  <dl>
                    <dt className="flex align-items-center justify-content-sb">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "80%", // 컨테이너 전체 너비를 사용하도록 설정
                        }}
                      >
                        {/* 왼쪽 요소들을 감싸는 컨테이너 */}
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <span className="thumb-img random1">
                            <Avatar
                              sx={{
                                bgcolor: "#9d97c7",
                                width: "36px",
                                height: "36px",
                              }}
                            >
                              {pressInfo.user_name[0]}
                            </Avatar>
                          </span>

                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginLeft: "4px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <div
                                className={`progress-tag ${getProgressTagClassName()}`}
                                style={{
                                  marginBottom: "0px",
                                  marginRight: "4px",
                                  padding: "0px 4px",
                                }}
                              >
                                {getProgressName()}
                              </div>
                              <span>
                                {pressInfo.category
                                  ? `[${pressInfo.category}] `
                                  : ""}
                                {pressInfo.title}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                        onClick={() => {
                          cardHight();
                        }}
                      >
                        <KeyboardArrowDownIcon />
                      </div>
                    </dt>
                  </dl>
                </div>
              </div>
            </Box>
          )}
        </>
      )}

      <Toast ref={toastRef} />
      <LoadingCircle loading={isLoading} />
    </>
  );
};

export default CardPress;
