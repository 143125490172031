import { Box, Button } from "@mui/material";
import MessageIcon from "@mui/icons-material/Message";

interface propsType {
  callback: any;
}

const CommentsTab = (props: propsType) => {
  return (
    <Box>
      <Button
        variant="contained"
        sx={{ borderRadius: "1.5rem 0 0 1.5rem", width: "8em" }}
        onClick={() => {
          props.callback("open_commentsviewer");
        }}
        startIcon={<MessageIcon />}
      >
        {"의견"}
      </Button>
    </Box>
  );
};

export default CommentsTab;
