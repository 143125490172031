import { useEffect, useState, useRef } from "react";

import {
  Box,
  Paper,
  Stack,
  TextField,
  CardMedia,
  Button,
  Typography,
} from "@mui/material";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useWindowSize } from "../../utils/useWindowSize";

import LoadingCircle from "../../utils/LoadingCircle";
import Toast from "../../utils/Toast";
import { ModelHandler } from "../../interface/model-handler";

interface propsType {
  model: string;
  eventId: string;
  getFormData: any;
  originResult: string;
  resultRef: any;
  setResult: any;
  changeResult: any;
}

const moreWidth: number = 532;
const moreHeigth: number = 150;
const smallMoreWidth: number = 224;

const AiMore = (props: propsType, ref: any) => {
  const toastRef: any = useRef();
  const windowSize = useWindowSize();

  const _selectionText = useRef("");

  const [isLoading, setIsLoading] = useState(false);
  const [display, setDisplay] = useState("none");
  const [aiMorePos, setAiMorePos] = useState({
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  });
  const [result, setResult] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [selfEditMode, setSelfEditMode] = useState(false);
  const [moreActionText, setMoreActionText] = useState("");
  const [selectionText, setSelectionText] = useState("");
  const [viewBtn, setViewBtn] = useState(false);
  const [aiApi, handleStreamResponse] = new ModelHandler(
    props.model
  ).getModelElements();
  const [streamReader, setStreamReader] =
    useState<ReadableStreamDefaultReader | null>(null);

  useEffect(() => {
    return () => {
      setIsLoading(false);
      setDisplay("none");
      setAiMorePos({ top: 0, left: 0, right: 0, bottom: 0 });
      setResult("");
      setMoreActionText("");
      setSelectionText("");
      setViewBtn(false);
      setEditMode(false);
      setSelfEditMode(false);
    };
  }, []);

  // 마우스 드래그로 텍스트 선택시
  const processSelection = (event: any) => {
    // 팝업 레이어를 띄울 element인지 확인
    if (checkEventElements(event)) {
      // 선택한 문자열 가져오기
      setSelfEditMode(false);
      const selection: Selection | null = window.getSelection();
      if (selection !== null && selection?.toString() !== "") {
        _selectionText.current = selection?.toString(); // 렌더링 되기전에 사용할 값
        setSelectionText(selection?.toString().replaceAll("&", "&amp;").replaceAll("<", "&lt;").replaceAll(">", "&gt;")); // 렌더링 이후에 사용할 값
        setSelectionColor(_selectionText.current); // 포커스 잃을 때 선택영역 색상 지정

        // 마우스 포지션 체크 후 해당 영역에 popup layer 띄우기
        const selectionRange: Range = selection?.getRangeAt(0);
        const selectionRangeRect: DOMRect =
          selectionRange.getBoundingClientRect();
        setAiMorePos({
          top: selectionRangeRect.top,
          left: selectionRangeRect.left,
          right: selectionRangeRect.right,
          bottom: selectionRangeRect.bottom,
        });
        // popup layer open
        open();
      } else {
        close(); // 아무것도 선택 안했을 때
      }
    } else {
      // 팝업 레이어 바깥 영역 클릭시 팝업 닫기 처리
      try {
        // 팝업 레이어 안쪽 버튼등을 클릭시 화면이 닫히지 않도록 처리
        if (!event.target?.className.indexOf("not-click")) {
          close();
        }
      } catch (error) {
        close();
      }
    }
  };

  // 드레그 이벤트를 처리할 요소인지 확인
  const checkEventElements = (event: MouseEvent) => {
    if (document.getElementById(props.eventId) === event.target) {
      return true;
    } else {
      return false;
    }
  };

  // 영역 선택후 팝업 레이어를 올리면 포커스를 엃어 배경색이 초기화 되는데 이때 다시 선택된 영역에 색상을 입히는 코드
  const setSelectionColor = (replaceText: string) => {
    const oldText = props.resultRef.current?.innerHTML;
    const newText = oldText?.replaceAll(
      replaceText,
      '<span class="selection-text">' + replaceText + "</span>"
    );
    props.setResult(newText);
  };

  // 프로세스를 모두 처리 후 색상을 원상태로 복원
  const removeSelectionColor = () => {
    const oldText = props.resultRef.current?.innerHTML;
    const newText1 = oldText?.replaceAll('<span class="selection-text">', "");
    const newText = newText1?.replaceAll("</span>", "");
    props.setResult(newText);
    return newText;
  };

  // 팝업 레이어 오픈
  const open = () => {
    setDisplay("flex");
  };

  // 팝업 레이어 닫기
  const close = () => {
    // 각종 변수 초기화
    _selectionText.current = "";
    setSelectionText("");
    removeSelectionColor();
    setDisplay("none");
    setViewBtn(false);
    setMoreActionText("");
    setResult("");
  };

  // Text 선택 Event
  useEffect(() => {
    window.addEventListener("mouseup", (event) => {
      processSelection(event);
    });

    return () => {
      // 메모리 누수 방지를 위해 핸들러 클리어
      window.removeEventListener("mouseup", (event) => {
        processSelection(event);
      });
    };
  }, []);

  // 추가 액션 (버튼형)
  const moreAction = async (command: string) => {
    let actionText = "";
    setEditMode(false);
    setResult("");
    setViewBtn(false);
    setSelfEditMode(false);
    if (command === "summarize") {
      actionText = "1. 2. 3. 양식에 3개의 핵심내용을 요약해줘. ";
    }
    if (command === "freeFormat") {
      actionText = moreActionText;
    }
    if (command === "increase") {
      actionText = "문맥에 맞게 내용을 보충해서 글자수를 50% 늘려줘.";
    }
    if (command === "decrease") {
      actionText = "글자수를 30% 줄여줘.";
    }
    if (command === "edit") {
      // setEditMode(true);
      setSelfEditMode(true);
      setResult(_selectionText.current);
      setViewBtn(true);
      return;
    }

    const param: any = {
      command: "more_ai_action_free_v1",
      source_text: _selectionText.current,
      form_data: props.getFormData,
      originResult: props.originResult,
      free_request: actionText,
    };

    setIsLoading(true);
    const streamResponse = await aiApi.postStream(param);
    setIsLoading(false);

    handleStreamResponse(streamResponse, setResult, setStreamReader);

    setViewBtn(true);
    setSelfEditMode(false);
  };

  // 적용버튼 클릭시
  const setNewResult = () => {
    if (selectionText === "") return;

    // 기존 Text Replace
    const oldText = props.resultRef.current?.innerHTML;
    let newText = oldText?.replaceAll(selectionText, result);
    newText = newText.replaceAll('<span class="selection-text">', "");
    newText = newText.replaceAll("</span>", "");
    newText = newText.replaceAll("&amp;", "&").replaceAll("&lt;", "<").replaceAll("&gt;", ">");

    // 새로운 결과 저장
    props.setResult(newText);
    // 변경사항 DB 저장
    props.changeResult(newText);
    setTimeout(() => {
      close();
    }, 200);
  };

  const [isAnimated, setIsAnimated] = useState(false);
  const toggleAnimation = () => setIsAnimated(!isAnimated);
  return (
    <>
      <Box
        id="moreRoot"
        sx={{
          p: 0,
          position: "absolute",
          display: display,
          ...(windowSize.height < aiMorePos.bottom + 5 + moreHeigth
            ? aiMorePos.top - moreHeigth - 5 < 5
              ? { bottom: 5 }
              : { top: aiMorePos.top - moreHeigth - 5 }
            : { top: aiMorePos.bottom + 5 }),
          ...(windowSize.width < aiMorePos.left + moreWidth
            ? { left: windowSize.width - moreWidth - 10 }
            : { left: aiMorePos.left }),
        }}
      >
        <Stack direction={"column"} spacing={1}>
          <Paper
            sx={{
              width: moreWidth,
            }}
            elevation={3}
            className="not-click"
          >
            <div className="toolbox">
              {(result !== "" || viewBtn) && (
                <div className="corr-text">
                  <TextField
                    variant="standard"
                    id="txtResult"
                    value={result}
                    fullWidth
                    onChange={(e) => {
                      setResult(e.target.value);
                    }}
                    size="small"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    multiline
                    maxRows={20}
                    type="search"
                    autoComplete="off"
                    inputProps={{ enterKeyHint: "Enter" }}
                  />
                  {viewBtn && streamReader === null ? (
                    <button
                      onClick={setNewResult}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <i style={{ display: "flex", alignItems: "center" }}>
                        <img src="/images/icon-check.png" />
                      </i>
                      <span>적용하기</span>
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        streamReader?.cancel();
                        setStreamReader(null);
                      }}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <i style={{ display: "flex", alignItems: "center" }}>
                        <StopCircleIcon color="primary" />
                      </i>
                      <span>중지하기</span>
                    </button>
                  )}
                </div>
              )}
              {!selfEditMode && (
                <>
                  <div className="edit-txt flex justify-content-sb align-items-center">
                    <div className="user-write flex align-items-center">
                      <i></i>
                      <TextField
                        id="txtMoreAction"
                        variant="standard"
                        value={moreActionText}
                        multiline
                        placeholder="직접 명령어 입력 하기"
                        autoFocus
                        fullWidth
                        onChange={(e) => {
                          setMoreActionText(e.target.value);
                        }}
                        size="small"
                        onKeyDown={(e) => {
                          if (e.key === "Enter" && !e.nativeEvent.isComposing) {
                            e.preventDefault();
                            moreAction("freeFormat");
                          }
                        }}
                        type="search"
                        autoComplete="off"
                        inputProps={{
                          enterKeyHint: "Enter",
                        }}
                        InputProps={{
                          disableUnderline: true,
                        }}
                      />
                      <button
                        className="all-del"
                        onClick={() => setMoreActionText("")}
                      ></button>
                    </div>
                    <div>
                      <button
                        id="btnQuick"
                        className="quick-write"
                        onClick={toggleAnimation}
                      >
                        <span>빠른 명령어</span>
                        <img src="/images/icon-quick-magic.png" alt="" />
                      </button>
                    </div>
                  </div>
                  <div
                    id="quickList"
                    className={`quick-list ${
                      isAnimated ? "quick-list-animate" : ""
                    }`}
                  >
                    <ul>
                      <li>
                        <img src="/images/icon-quick-simple.png" alt="" />
                        <span
                          onClick={() => {
                            moreAction("edit");
                          }}
                        >
                          직접 수정
                        </span>
                      </li>
                      <li>
                        <img src="/images/icon-quick-inc.png" alt="" />
                        <span
                          onClick={() => {
                            moreAction("increase");
                          }}
                        >
                          늘여 쓰기
                        </span>
                      </li>
                      <li>
                        <img src="/images/icon-quick-red.png" alt="" />
                        <span
                          onClick={() => {
                            moreAction("decrease");
                          }}
                        >
                          줄여 쓰기
                        </span>
                      </li>
                      <li>
                        <img src="/images/icon-quick-dir.png" alt="" />
                        <span
                          onClick={() => {
                            moreAction("summarize");
                          }}
                        >
                          요약 하기
                        </span>
                      </li>
                      <li>
                        <ContentCopyIcon
                          style={{ fontSize: "15px", marginRight: "8px" }}
                        />
                        <CopyToClipboard text={selectionText}>
                          <span>복사 하기</span>
                        </CopyToClipboard>
                      </li>
                    </ul>
                  </div>
                </>
              )}
            </div>
          </Paper>
        </Stack>
      </Box>
      <LoadingCircle loading={isLoading} />
      <Toast ref={toastRef} />
    </>
  );
};

export default AiMore;
