import {
  useEffect,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";

import {
  Box,
  List,
  CardMedia,
  Button,
  Typography,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Icon,
  Chip,
  Stack,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ImagesearchRollerIcon from "@mui/icons-material/ImagesearchRoller";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import Face4Icon from "@mui/icons-material/Face4";
import ChromeReaderModeIcon from "@mui/icons-material/ChromeReaderMode";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import { userState, IMenuItem } from "../interface/MainInterface";
import SummarizeIcon from "@mui/icons-material/Summarize";
import { HttpMainApi } from "../interface/main-api";
import PopCreatePress from "../page/module/PopCreatePress";
import { saveAs } from "file-saver";
import HelpIcon from '@mui/icons-material/Help';

const isLocalhost =
  window.location.hostname === "localhost" ||
  window.location.hostname === "127.0.0.1" ||
  window.location.hostname.startsWith("haap-dev");
const imagePath = isLocalhost
  ? "/images/logo-dev.png"
  : "/images/logo-prod.png";

const devClass = isLocalhost && "dev";

interface propsType {
  userState: userState;
  drawerWidth: number;
  largeDrawerWidth: number;
  selectedMenu: string;
  move: any;
}

// 공통 메뉴 : 일반사용자 접근
export const MENU: IMenuItem[] = [
  {
    name: "유사 자료 검색",
    iconType: "img",
    icon: "/images/icon-search.png",
    path: "/searchPress",
  },
  // {
  //   name: "보도 자료 검색",
  //   iconType: "mui-icon",
  //   icon: (
  //     <ContentPasteSearchIcon
  //       sx={{ width: "16px", height: "16px", color: "#aec5fe" }}
  //     />
  //   ),
  //   path: "/searchFilterPress",
  // },
  {
    name: "자유 양식",
    iconType: "mui-icon",
    icon: (
      <SummarizeIcon sx={{ width: "16px", height: "16px", color: "#aec5fe" }} />
    ),
    path: "/optionPage",
  },
  {
    name: "공통 테마",
    iconType: "mui-icon",
    icon: (
      <ChromeReaderModeIcon
        sx={{ width: "16px", height: "16px", color: "#aec5fe" }}
      />
    ),
    path: "/commonTheme",
    adminOnly: true,
  },
  {
    name: "나의 테마",
    iconType: "mui-icon",
    icon: (
      <ImagesearchRollerIcon
        sx={{ width: "16px", height: "16px", color: "#aec5fe" }}
      />
    ),
    path: "/myTheme",
  },
  {
    name: "마이페이지",
    iconType: "img",
    icon: "/images/icon-mypage.png",
    path: "/setting",
  },
  {
    name: "매뉴얼",
    iconType: "mui-icon",
    icon: (
      <HelpIcon 
        sx={{ width: "16px", height: "16px", color: "#aec5fe" }}
      />
    ),
    path: "/",
  },
];

export const MY_WORKSPACE_MENU: IMenuItem[] = [
  {
    name: "전체",
    iconType: "img",
    icon: "/images/icon-all.png",
    path: "/myTotalList",
  },
  {
    name: "완료",
    iconType: "img",
    icon: "/images/icon-complete.png",
    path: "/myCompleteList",
  },
  {
    name: "진행",
    iconType: "img",
    icon: "/images/icon-proceeding.png",
    path: "/myIngList",
  },
];

export const ADMIN_WORKSPACE_MENU: IMenuItem[] = [
  {
    name: "전체",
    iconType: "img",
    icon: "/images/icon-all.png",
    path: "/myTotalList",
  },
  {
    name: "홍보실 접수",
    iconType: "mui-icon",
    icon: (
      <PeopleAltIcon sx={{ width: "16px", height: "16px", color: "#aec5fe" }} />
    ),
    path: "/requestHongbo",
  },
  {
    name: "나의 접수",
    iconType: "mui-icon",
    icon: (
      <Face4Icon sx={{ width: "16px", height: "16px", color: "#aec5fe" }} />
    ),
    path: "/myHongboIng",
  },
  {
    name: "진행",
    iconType: "img",
    icon: "/images/icon-proceeding.png",
    path: "/myIngList",
  },
  {
    name: "완료",
    iconType: "img",
    icon: "/images/icon-complete.png",
    path: "/myCompleteList",
  },
];

export const ADMIN_MENU: IMenuItem[] = [
  {
    name: "사용자 관리",
    iconType: "mui-icon",
    icon: (
      <ManageAccountsIcon
        sx={{ width: "16px", height: "16px", color: "#aec5fe" }}
      />
    ),
    path: "/mngUser",
  },
];

const mainApi = new HttpMainApi();

const LeftMenu = (props: propsType, ref: any) => {
  const popCreatePressRef: any = useRef(); // 보도자료 생성용 팝업
  const [expandMenu, setExpandMenu] = useState<any>([]); // 자식메뉴 있을때

  const [hongboList, setHongboList] = useState<any>([]);
  const [myHongboList, setMyHongboList] = useState<any>([]);

  useEffect(() => {
    get_hongbo_total_list();
    return () => {
      setExpandMenu([]);
      setHongboList([]);
      setMyHongboList([]);
    };
  }, []);

  // 보도자료 생성 팝업 띄우기
  const pop_create_press = () => {
    popCreatePressRef.current.open(true);
  };

  useEffect(() => {
    // 메뉴 변경시 하일라이트를 위한 State 계산
    for (const menu of MENU) {
      if (menu.children) {
        for (const child of menu.children) {
          if (props.selectedMenu.split("/")[1] === child.path.split("/")[1]) {
            let temp = { ...expandMenu };
            temp[menu.path] = true;
            setExpandMenu(temp);
          }
        }
      }
    }
  }, [props.selectedMenu, expandMenu]);

  // 사이트 로고 동적 할당
  function SiteLogo() {
    return (
      <h1 className="logo-root">
        <a href="/" className={`logo-a ${devClass}`}>
          <img className="logo-img" src={imagePath} alt="logo" />
        </a>
      </h1>
    );
  }
  
  const fncDownloadManual = async () => {
    const fileDir = 'manual/';
    const fileName = 'haap_manual.pdf';
    const filePath = fileDir + fileName;
  
    const params = {
      command: "get_cf_presigned_url",
      key: filePath,
    };

    const res = await mainApi.post(params);
    if (res.code === "200") {
      const downloadUrl = res.response.object_url;

      await fetch(downloadUrl, { method: "GET" })
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        saveAs(blob, '합대리 매뉴얼.pdf');
      })
      .catch((err) => {
        console.error("err : ", err);
      });
    }
  }

  const ListMenu = () => {
    return (
      <>
        <Box className="left-menu-root">
          <Typography className="logo-menu-title">MENU</Typography>
          <List key={"left-menu"} sx={{ p: 0 }}>
            {MENU.map((menuInfo, index) =>
              menuInfo.adminOnly && !props.userState.isAdmin ? (
                <div key={menuInfo.name + "" + index}></div>
              ) : (
                <div key={menuInfo.name + "" + index}>
                  <ListItem
                    disablePadding
                    sx={{
                      height: "42px",
                      width: "218px",
                      p: 0,
                    }}
                  >
                    <ListItemButton
                      className="left-menu-wrapper"
                      selected={menuInfo.path === props.selectedMenu}
                      onClick={() => {
                        if (menuInfo.name === "매뉴얼")
                          fncDownloadManual();
                        else
                          props.move(menuInfo.path);
                      }}
                    >
                      <ListItemIcon sx={{ minWidth: "24px" }}>
                        {menuInfo.iconType === "img" && (
                          <CardMedia
                            component="img"
                            className="left-menu-icon"
                            image={menuInfo.icon}
                          />
                        )}
                        {menuInfo.iconType === "mui-icon" && (
                          <Icon sx={{ fontSize: "16px" }}>{menuInfo.icon}</Icon>
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          props.drawerWidth === props.largeDrawerWidth && (
                            <Typography className={"left-menu-text"}>
                              {menuInfo.name}
                            </Typography>
                          )
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                </div>
              )
            )}
          </List>
        </Box>
      </>
    );
  };

  const MyWorkSpace = () => {
    return (
      <Box className="my-workspace-root">
        <Typography className="logo-menu-title">MY WORKSPACE</Typography>
        <Box className="width-100">
          {/* 쿼리가 너무 과함... 따라서 일단 hidden 처리 */}
          {/* <CardMyProgress userState={props.userState} /> */}
          <List key={"left-menu"} sx={{ p: 0, mt: "4px" }}>
            {MY_WORKSPACE_MENU.map((menuInfo, index) => (
              <div key={menuInfo.name + "" + index}>
                <ListItem
                  disablePadding
                  sx={{
                    height: "42px",
                    width: "218px",
                    p: 0,
                  }}
                >
                  <ListItemButton
                    className="left-menu-wrapper"
                    selected={menuInfo.path === props.selectedMenu}
                    onClick={() => {
                      props.move(menuInfo.path);
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: "24px" }}>
                      {menuInfo.iconType === "img" && (
                        <CardMedia
                          component="img"
                          className="left-menu-icon"
                          image={menuInfo.icon}
                        />
                      )}
                      {menuInfo.iconType === "mui-icon" && (
                        <Icon sx={{ fontSize: "16px" }}>{menuInfo.icon}</Icon>
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        props.drawerWidth === props.largeDrawerWidth && (
                          <Typography className={"left-menu-text"}>
                            {menuInfo.name}
                          </Typography>
                        )
                      }
                    />
                  </ListItemButton>
                </ListItem>
              </div>
            ))}
          </List>
        </Box>
      </Box>
    );
  };

  const AdminWorkSpace = () => {
    return (
      <Box className="my-workspace-root">
        <Typography className="logo-menu-title">MY WORKSPACE</Typography>
        <Box className="width-100">
          {/* 쿼리가 너무 과함... 따라서 일단 hidden 처리 */}
          {/* <CardMyProgress userState={props.userState} /> */}
          <List key={"left-menu"} sx={{ p: 0, mt: "4px" }}>
            {ADMIN_WORKSPACE_MENU.map((menuInfo, index) => (
              <div key={menuInfo.name + "" + index}>
                <ListItem
                  disablePadding
                  sx={{
                    height: "42px",
                    width: "218px",
                    p: 0,
                  }}
                >
                  <ListItemButton
                    className="left-menu-wrapper"
                    selected={menuInfo.path === props.selectedMenu}
                    onClick={() => {
                      get_hongbo_total_list();
                      props.move(menuInfo.path);
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: "24px" }}>
                      {menuInfo.iconType === "img" && (
                        <CardMedia
                          component="img"
                          className="left-menu-icon"
                          image={menuInfo.icon}
                        />
                      )}
                      {menuInfo.iconType === "mui-icon" && (
                        <Icon sx={{ fontSize: "16px" }}>{menuInfo.icon}</Icon>
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        props.drawerWidth === props.largeDrawerWidth && (
                          <Stack direction={"row"} spacing={1}>
                            <Typography className={"left-menu-text"}>
                              {menuInfo.name}
                            </Typography>
                            {menuInfo.path === "/requestHongbo" && (
                              <Chip
                                label={hongboList.length}
                                size="small"
                                color="warning"
                                sx={{ height: "16px", mt: "4px !important" }}
                              />
                            )}
                            {menuInfo.path === "/myHongboIng" && (
                              <Chip
                                label={myHongboList.length}
                                size="small"
                                color="warning"
                                sx={{ height: "16px", mt: "4px !important" }}
                              />
                            )}
                          </Stack>
                        )
                      }
                    />
                  </ListItemButton>
                </ListItem>
              </div>
            ))}
          </List>
        </Box>
      </Box>
    );
  };

  const AdminMenu = () => {
    return (
      <>
        <Box className="left-menu-root mb-20">
          <Typography className="logo-menu-title">ADMIN</Typography>
          <List key={"left-menu"} sx={{ p: 0 }}>
            {ADMIN_MENU.map((menuInfo, index) =>
              menuInfo.adminOnly && !props.userState.isAdmin ? (
                <div key={menuInfo.name + "" + index}></div>
              ) : (
                <div key={menuInfo.name + "" + index}>
                  <ListItem
                    disablePadding
                    sx={{
                      height: "42px",
                      width: "218px",
                      p: 0,
                    }}
                  >
                    <ListItemButton
                      className="left-menu-wrapper"
                      selected={menuInfo.path === props.selectedMenu}
                      onClick={() => {
                        props.move(menuInfo.path);
                      }}
                    >
                      <ListItemIcon sx={{ minWidth: "24px" }}>
                        {menuInfo.iconType === "img" && (
                          <CardMedia
                            component="img"
                            className="left-menu-icon"
                            image={menuInfo.icon}
                          />
                        )}
                        {menuInfo.iconType === "mui-icon" && (
                          <Icon sx={{ fontSize: "16px" }}>{menuInfo.icon}</Icon>
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          props.drawerWidth === props.largeDrawerWidth && (
                            <Typography className={"left-menu-text"}>
                              {menuInfo.name}
                            </Typography>
                          )
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                </div>
              )
            )}
          </List>
        </Box>
      </>
    );
  };

  const get_hongbo_total_list = async () => {
    const param: any = {
      command: "get_hongbo_request_list",
      from_date: "19000101",
      to_date: "30001231",
      sortForward: false,
    };

    const res = await mainApi.post(param);
    if (res.code === "200") {
      let myList: any = [];
      let etcList: any = [];
      for (const hongbo_info of res.response.request_list) {
        if (hongbo_info.hongbo_user_id === props.userState.id) {
          myList.push(hongbo_info);
        } else {
          if (hongbo_info.hongbo_user_id === "") {
            etcList.push(hongbo_info);
          }
        }
      }
      setMyHongboList(myList);
      setHongboList(etcList);
    }
  };

  const getLeftCount = () => {
    get_hongbo_total_list();
  };

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    getLeftCount,
  }));

  return (
    <>
      <SiteLogo />
      {props.userState.isAdmin ? <AdminWorkSpace /> : <MyWorkSpace />}
      <Box className="new-workspace-root">
        <Button
          className="make-new-press-btn"
          variant="contained"
          onClick={pop_create_press}
          fullWidth
          startIcon={<AddIcon />}
        >
          새 보도자료 만들기
        </Button>
      </Box>
      <ListMenu />
      <Box sx={{ flexGrow: "1" }}></Box>
      {/* {props.userState.isAdmin && <AdminMenu />} */}
      {/* 사용자 관리 화면 만들고 변경 */}
      {props.userState.isAdmin && <AdminMenu />}

      <PopCreatePress
        ref={popCreatePressRef}
        userState={props.userState}
        move={props.move}
      />
    </>
  );
};

export default forwardRef(LeftMenu);
