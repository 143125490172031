import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

import {
  Box,
  Stack,
  Typography,
  TextField,
  Grid,
  IconButton,
  CardMedia,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import { userState } from "../../interface/MainInterface";

import LoadingCircle from "../../utils/LoadingCircle";
import Toast from "../../utils/Toast";

import { HttpCustomApi } from "../../interface/custom-api";

import CardSearchResult from "../module/CardSearchResult";

interface propsType {
  userState: userState;
  searchResult: any;
  setSearchResult: any;
}

let _maxCount: number = 30;

const vectoCoreApi = new HttpCustomApi(process.env.REACT_APP_VECTOR_CORE);

const SearchPress = (props: propsType) => {
  const location = useLocation();
  const queryParams: any = queryString.parse(location.search);

  const toastRef: any = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [question, setQuestion] = useState("");
  const [result, setResult] = useState<any>(props.searchResult);

  useEffect(() => {
    if (queryParams.q !== undefined) {
      setQuestion(queryParams.q);
      if (result.length === 0) fncQuestion(queryParams.q);
    } else {
      props.setSearchResult([]);
      setResult([]);
    }
    return () => {
      setIsLoading(false);
      setQuestion("");
    };
  }, []);

  const fncQuestion = async (searchText: string) => {
    setResult([]);
    if (searchText === "") return;
    setIsLoading(true);
    // extream search
    const extParam: any = {
      command: "extream_search",
      index_name: "naverx-haap-search",
      question: searchText, // 사용자 질문
      max_count: _maxCount, // 검색결과 최대 수량
    };

    const extRes = await vectoCoreApi.auth_request(
      "api",
      extParam,
      process.env.REACT_APP_TENANT_KEY
    );
    console.log(extRes);
    if (extRes.code === "200") {
      setResult(extRes.response.result);
      props.setSearchResult(extRes.response.result);
    } else {
      toastRef.current?.toast(
        "Error fncQuestion : " + extRes.response.error_msg,
        "error",
        3000,
        { vertical: "top", horizontal: "center" }
      );
    }

    setIsLoading(false);
  };

  return (
    <>
      <Stack
        direction={"column"}
        spacing={2}
        sx={{ p: "32px", pb: "16px !important" }}
      >
        <Box className="sub-header-root">
          <Typography variant="h5" gutterBottom className="sub-header-title">
            유사자료 검색
          </Typography>
        </Box>
        {/* 검색박스 */}
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box className="search-bar-wrapper">
            <Stack direction="row" spacing={1}>
              <IconButton>
                <CardMedia
                  component="img"
                  image={"/images/haap.png"}
                  sx={{ width: "35px", height: "35px", objectFit: "contain" }}
                />
              </IconButton>
              <TextField
                id="txtQuestion"
                value={question}
                placeholder="검색어를 입력해 주세요."
                variant="outlined"
                fullWidth
                required
                size="small"
                onChange={(e) => {
                  setQuestion(e.target.value);
                  window.history.pushState(
                    "",
                    "searchUrl",
                    "/searchPress?q=" + e.target.value
                  );
                }}
                inputProps={{
                  style: { fontWeight: "800 !important", fontSize: "24px" },
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !e.nativeEvent.isComposing)
                    fncQuestion(question);
                }}
              />
              <IconButton
                onClick={() => {
                  fncQuestion(question);
                }}
                sx={{ marginRight: "5px" }}
              >
                <SearchIcon sx={{ color: "#527fed", fontSize: "35px" }} />
              </IconButton>
            </Stack>
          </Box>
        </Box>
        <Box sx={{ mt: "40px !important" }}>
          <Grid container spacing={2}>
            {result.map((pressInfo: any, index: number) => (
              <Grid key={`pressCard-${index}`} item xs={12}>
                <CardSearchResult pressInfo={pressInfo} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Stack>
      <Toast ref={toastRef} />
      <LoadingCircle loading={isLoading} />
    </>
  );
};

export default SearchPress;
