import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import { CopyToClipboard } from "react-copy-to-clipboard";

import {
  Box,
  Stack,
  Divider,
  Button,
  Typography,
  Pagination,
  IconButton,
  TextField,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";

import NearMeIcon from "@mui/icons-material/NearMe";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import AddCommentIcon from "@mui/icons-material/AddComment";
import UndoIcon from "@mui/icons-material/Undo";
import GroupsIcon from "@mui/icons-material/Groups";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import { HttpMainApi } from "../../interface/main-api";
import { userState } from "../../interface/MainInterface";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import { CommonUtils } from "../../utils/common_utils";
import S3UploaderViaApi from "../../utils/attach/S3UploaderViaApi";
import AttachFilesTab from "../../utils/attach/AttachFilesTab";
import AttachFileViewer from "../../utils/attach/AttachFileViewer";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

import LoadingCircle from "../../utils/LoadingCircle";
import Toast from "../../utils/Toast";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import AiMore from "../aiUtils/AiMore";
import CardPress from "../module/CardPress";
import ThemeModule from "./themeModule/ThemeModule";

import PopAddComment from "../module/Comment/PopAddComment";
import CommentsTab from "../module/Comment/CommentsTab";
import CommentsViewer from "../module/Comment/CommentsViewer";
import { styled } from "@mui/system";
import PopToss from "../module/PopToss";
import { ModelHandler } from "../../interface/model-handler";

const isLocalhost =
  window.location.hostname === "localhost" ||
  window.location.hostname === "127.0.0.1" ||
  window.location.hostname.startsWith("haap-dev");

const S3_BUCKET_NAME = isLocalhost
  ? process.env.REACT_APP_S3_BUCKET_LOCAL_NAME
  : process.env.REACT_APP_S3_BUCKET_NAME;

interface propsType {
  userState: userState;
  getLeftCount: any;
}
interface StreamResponse {
  getReader: () => ReadableStreamDefaultReader;
}

const mainApi = new HttpMainApi();

const utils = new CommonUtils();

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

const MakePress = (props: propsType) => {
  const { pressSeq } = useParams();

  const toastRef: any = useRef();
  const resultRef: any = useRef();
  const themeRef: any = useRef();
  const attachFileViewerRef: any = useRef();
  const addCommentRef: any = useRef();
  const commentsViewerRef: any = useRef();
  const tossRef: any = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const [ableRequest, setAbleRequest] = useState(true);
  const [result, setResult] = useState("");
  const [result1, setResult1] = useState("");
  const [result2, setResult2] = useState("");
  const [result3, setResult3] = useState("");
  const [result4, setResult4] = useState("");
  const [totalCharacters, setTotalCharacters] = useState("글자수 : 0");
  const [isStreamIng, setIsStreamIng] = useState(false);
  const [editResult, setEditResult] = useState("");
  const [pressInfo, setPressInfo] = useState<any>({});
  const [pressHistory, setPressHistory] = useState<any>([]);
  const [hisPage, setHisPage] = useState(0);
  const [startCategory, setStartCategory] = useState("마케팅");
  const [attachFiles, setAttachFiles] = useState<any>([]);
  const [comments, setComments] = useState<any>([]);
  const [free_theme_example, setFree_theme_example] = useState("");
  const [free_theme_oneshot, setFree_theme_oneshot] = useState("");
  const [free_theme_category, setFree_theme_category] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [model, setModel] = useState("");
  const [streamReader1, setStreamReader1] =
    useState<ReadableStreamDefaultReader | null>(null);
  const [streamReader2, setStreamReader2] =
    useState<ReadableStreamDefaultReader | null>(null);
  const [streamReader3, setStreamReader3] =
    useState<ReadableStreamDefaultReader | null>(null);
  const [streamReader4, setStreamReader4] =
    useState<ReadableStreamDefaultReader | null>(null);
  const modelHandler = new ModelHandler(model);

  useEffect(() => {
    modelHandler
      .getUsingModel()
      .then((usingModel) => {
        setModel(usingModel);
      })
      .catch((errorMsg: any) => {
        toastRef.current?.toast("" + errorMsg, "error", 4000, {
          vertical: "top",
          horizontal: "center",
        });
      });
    // get_llm_model();
    return () => {
      setIsLoading(false);
      setAbleRequest(true);
      setResult("");
      setPressInfo({});
      setPressHistory([]);
      setHisPage(1);
      setAttachFiles([]);
      setComments([]);
      setEditResult("");
      setEditMode(false);
    };
  }, []);

  // 보도자료 조회
  const getPressInfo = async (seq: string) => {
    const param: any = {
      command: "get_press_info",
      sk: seq,
    };

    const res = await mainApi.post(param);
    if (res.code === "200") {
      // console.log("press info : ", res.response.press_info);
      setPressInfo(res.response.press_info);
      getPressHistoryList(res.response.press_info.sk);
      setAttachFiles(res.response.press_info.attach_files);
      get_comment_list(res.response.press_info.sk);
      setStartCategory(res.response.press_info.category);
    }
  };

  // 동시 작성 시작
  useEffect(() => {
    const combinedData = `${result1}\n\n${result2}\n\n${result3}\n\n${result4}`;
    setResult(combinedData);
  }, [result1, result2, result3, result4]);

  useEffect(() => {
    // isLoading 상태를 검사하여 스트리밍 데이터 처리가 완료되었는지 확인
    if (isStreamIng && result) {
      setIsStreamIng(false);
      changeResult(result);
    }
  }, [result, isStreamIng]);

  function calculateTotalCharacters(data: Record<string, any>): number {
    let totalCharacters = 0;

    Object.values(data).forEach((value) => {
      if (typeof value === "string") {
        totalCharacters += value.length;
      }
    });

    return totalCharacters;
  }

  function formatNumber(number: number): string {
    const formatter = new Intl.NumberFormat("en-US", {
      maximumFractionDigits: 0,
    });

    // 숫자를 포맷하여 반환
    return formatter.format(number);
  }

  const createPressRelease = async () => {
    setIsLoading(true);

    try {
      const formData = themeRef.current?.getFormData();

      if (Object.keys(formData).length < 4) {
        toastRef.current?.toast(
          "입력된 값이 너무 부족합니다. [회사명],[배포 일시],[회사 멘트] 등 내용을 입력해 주시기 바랍니다.",
          "error",
          4000,
          {
            vertical: "top",
            horizontal: "center",
          }
        );
        return;
      }
      let adjustedTotalCharacters = 0;
      if (formData) {
        const totalCharacters = calculateTotalCharacters(formData);
        adjustedTotalCharacters = Math.round(totalCharacters * 1.3);
      }

      let oneshot_data_temp = themeRef.current?.getformOneShotData();
      let form_example_temp = themeRef.current?.getFormExample();
      if (oneshot_data_temp === undefined || oneshot_data_temp === "") {
        const param: any = {
          command: "get_theme_list",
          type: "common",
          category: startCategory,
          user_id: props.userState.id,
        };

        const res = await mainApi.post(param);
        if (res.code === "200") {
          let temp_list = [];
          temp_list = res.response.theme_list;
          temp_list = temp_list.filter((item: any) => item.bookmark_yn === "Y");
          if (temp_list.length > 0) {
            form_example_temp = temp_list[0]["form_example"];
            oneshot_data_temp = temp_list[0]["one_shot"];
          } else {
            form_example_temp = res.response.theme_list[0]["form_example"];
            oneshot_data_temp = res.response.theme_list[0]["one_shot"];
          }

          setFree_theme_example(form_example_temp);
          setFree_theme_oneshot(oneshot_data_temp);
          setFree_theme_category(startCategory);
        }
      }

      const [aiApi, handleStreamResponse] = modelHandler.getModelElements();

      if (pressInfo.category === "포토뉴스") {
        const param1 = {
          command: "create_photo_release_v1", //제목
          form_data: formData,
          oneshot_data: oneshot_data_temp,
          form_example: form_example_temp,
        }
        const param2 = {
          command: "create_photo_release_v2", //본문(세부내용)
          form_data: formData,
          oneshot_data: oneshot_data_temp,
          form_example: form_example_temp,
        };

        const [
          streamResponse1,
          streamResponse2,
        ] = await Promise.all([
          aiApi.postStream(param1),
          aiApi.postStream(param2),
        ]);
        setIsLoading(false);
        await Promise.all([
          handleStreamResponse(streamResponse1, setResult1, setStreamReader1),
          handleStreamResponse(streamResponse2, setResult2, setStreamReader2),
        ]);
      } else {
        const param1 = {
          command: "create_press_release_v1", //제목
          form_data: formData,
          oneshot_data: oneshot_data_temp,
          form_example: form_example_temp,
        };
        const param2 = {
          command: "create_press_release_v2", //리드문
          form_data: formData,
          oneshot_data: oneshot_data_temp,
          form_example: form_example_temp,
        };
        const param3 = {
          command: "create_press_release_v3", //본문
          form_data: formData,
          oneshot_data: oneshot_data_temp,
          form_example: form_example_temp,
          adjustedTotalCharacters: adjustedTotalCharacters,
        };
        const param4 = {
          command: "create_press_release_v4", //인용문
          form_data: formData,
          oneshot_data: oneshot_data_temp,
          form_example: form_example_temp,
        };

        const [
          streamResponse1,
          streamResponse2,
          streamResponse3,
          streamResponse4,
        ] = await Promise.all([
          aiApi.postStream(param1),
          aiApi.postStream(param2),
          aiApi.postStream(param3),
          aiApi.postStream(param4),
        ]);
        setIsLoading(false);
        await Promise.all([
          handleStreamResponse(streamResponse1, setResult1, setStreamReader1),
          handleStreamResponse(streamResponse2, setResult2, setStreamReader2),
          handleStreamResponse(streamResponse3, setResult3, setStreamReader3),
          handleStreamResponse(streamResponse4, setResult4, setStreamReader4),
        ]);
      }
    } catch (error) {
      console.error("Error fetching press release data:", error);
    } finally {
      setIsLoading(false);
      setTimeout(() => {
        setIsStreamIng(true);
      }, 1000);
    }
  };

  const stopPressRelease = () => {
    if (streamReader1 !== null) {
      streamReader1.cancel();
      streamReader1.releaseLock();
      setStreamReader1(null);
    }
    if (streamReader2 !== null) {
      streamReader2.cancel();
      streamReader2.releaseLock();
      setStreamReader2(null);
    }
    if (streamReader3 !== null) {
      streamReader3.cancel();
      streamReader3.releaseLock();
      setStreamReader3(null);
    }
    if (streamReader4 !== null) {
      streamReader4.cancel();
      streamReader4.releaseLock();
      setStreamReader4(null);
    }
  };

  const isEmptyObject = (obj: any) =>
    Object.keys(obj).length === 0 && obj.constructor === Object;

  // 결과 실시간 저장
  const changeResult = async (newResult: string) => {
    // 결과 저장

    let newPressInfo = { ...pressInfo };
    const { formList, formData } = themeRef.current.getThemeInfo();

    newPressInfo.form_list = formList;
    newPressInfo.form_data = formData;
    newPressInfo.one_shot =
      themeRef.current?.getformOneShotData() || free_theme_oneshot;
    newPressInfo.form_example = !isEmptyObject(
      themeRef.current?.getFormExample()
    )
      ? themeRef.current?.getFormExample()
      : free_theme_example;
    newPressInfo.category =
      themeRef.current?.getCategory() || free_theme_category;
    newPressInfo.themeTitle = themeRef.current?.getThemeTitle();

    newPressInfo.result = newResult;
    const totalCharacters = calculateTotalCharacters(formData);
    setTotalCharacters(
      `글자 입력 : ${formatNumber(
        totalCharacters
      )} 자 , 보도자료 글자 수 : ${formatNumber(newResult.length)} 자`
    );
    setPressInfo(newPressInfo);
    const paramPress: any = {
      command: "put_press_info",
      press_info: newPressInfo,
    };

    await mainApi.post(paramPress);
    getPressInfo(pressInfo.sk);
  };

  useEffect(() => {
    if (!utils.isEmptyObj(pressInfo)) {
      // 작성중이던 결과 지정하기
      setResult(pressInfo.result);
    }
  }, [pressInfo]);

  // queryString 보도자료 Seq 변경시 최신 보도자료 정보 조회
  useEffect(() => {
    if (pressSeq !== undefined) {
      // 상태 초기화
      setIsLoading(false);
      setAbleRequest(true);
      setResult("");
      setPressInfo({});
      setPressHistory([]);
      setHisPage(1);
      setAttachFiles([]);
      getPressInfo(pressSeq);
    }
  }, [pressSeq]);

  // 보도자료 히스토리 조회
  const getPressHistoryList = async (pressSk: string) => {
    const param: any = {
      command: "get_press_history_list",
      sk: pressSk,
    };

    const res = await mainApi.post(param);
    if (res.code === "200") {
      setPressHistory(res.response.history_list);
      setHisPage(res.response.history_list.length);
    }
  };

  // 히스토리 페이지 이동시 결과 및 테마 동기화
  const handleHisPage = (event: any, value: number) => {
    setHisPage(value);
    setResult(pressHistory[value - 1].result);
    // 테마, llm 결과만 보도자료 데이터 업데이트하기
    const newPressInfo: any = { ...pressInfo };
    newPressInfo.form_list = pressHistory[value - 1].form_list;
    newPressInfo.form_data = pressHistory[value - 1].form_data;
    newPressInfo.result = pressHistory[value - 1].result;
    setPressInfo(newPressInfo);
    const totalCharacters = calculateTotalCharacters(
      pressHistory[value - 1].form_data
    );
    setTotalCharacters(
      `글자 입력 : ${formatNumber(
        totalCharacters
      )} 자 , 보도자료 글자 수 : ${formatNumber(
        pressHistory[value - 1].result.length
      )} 자`
    );
  };

  // 홍보실 전송
  const sendPressToHongbo = async () => {
    // 반려의견창 띄우기
    addCommentRef.current.open("requestHongbo");
  };

  // 홍보실 접수
  const takeOverPress = async () => {
    if (!window.confirm("해당 보도자료 초안을 접수하시겠습니까?")) return;

    const param: any = {
      command: "take_over_press",
      pk: pressInfo.pk,
      sk: pressInfo.sk,
      hongbo_user_id: props.userState.id,
      hongbo_user_name: props.userState.name,
    };

    setIsLoading(true);
    const res = await mainApi.post(param);
    if (res.code === "200") {
      props.getLeftCount();
      getPressInfo(pressInfo.sk);
    } else {
      console.error(res.response.error_msg);
      toastRef.current?.toast("" + res.response.error_msg, "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
    }
    setIsLoading(false);
  };

  const returnPress = () => {
    // 반려창 띄우기
    addCommentRef.current.open("return");
  }

  const rejectPress = () => {
    // 반려의견창 띄우기
    addCommentRef.current.open("reject");
  };

  // 최종완료
  const completePress = async () => {
    addCommentRef.current.open("complete");
  };

  // 담당자 토스
  const tossPress = async () => {
    // 토스 선택창 띄우기
    // 1. 접수전 상태로 상태 변경
    // 2. 담당자 지정해서 토스하기
    tossRef.current.open();
  };

  // 첨부파일 업로드 완료시 콜백
  const fncUploadComplete = (result: any) => {
    if (result.status === "ok") {
      let temp = [...attachFiles, ...result.fileInfo];
      setAttachFiles(temp); // 새로 업로도된 첨부파일 목록을 추가
    }
  };

  // 첨부파일 업데이트시 이벤트
  useEffect(() => {
    if (attachFiles.length > 0 && !utils.isEmptyObj(pressInfo)) {
      updateAttach(attachFiles);
    }
  }, [attachFiles, pressInfo]);

  // 첨부파일 업데이트시 보도자료의 첨부파일 목록 업데이트
  const updateAttach = async (files: any) => {
    if (JSON.stringify(files) === JSON.stringify(pressInfo.attach_files))
      return;
    const param: any = {
      command: "update_press_attach_files",
      pk: pressInfo.pk,
      sk: pressInfo.sk,
      files: files,
    };

    await mainApi.post(param);
    if (pressSeq !== undefined) {
      let newPressInfo: any = { ...pressInfo };
      const { formList, formData } = themeRef.current.getThemeInfo();

      newPressInfo.attach_files = files;
      setPressInfo(newPressInfo);
      setTimeout(() => {
        themeRef.current.setThemeInfo(formList, formData);
      }, 300);
    }
    attachFileViewerRef.current.open();
  };

  // 첨부파일 삭제
  const fncDeleteAttach = (attachInfo: any) => {
    if (attachInfo.SEQ === undefined) {
      // 아직 저장전인 첨부파일
      let temp = [...attachFiles];
      for (let i = 0; i < temp.length; i++) {
        if (temp[i].FILE_PATH === attachInfo.FILE_PATH) {
          temp.splice(i, 1);
          i--;
        }
      }
      setAttachFiles(temp);
    }
  };

  // 우측 첨부파일TAB 버튼 클릭시 첨부파일 목록 Open
  const attachFilesTabCallback = (command: string) => {
    if (command === "open_fileviewer") {
      attachFileViewerRef.current.open();
    }
  };

  // 첨부파일 목록에서 첨부파일 삭제시 콜백
  const attachFileViewerCallback = (payload: any) => {
    if (payload?.command === "delete") {
      fncDeleteAttach(payload?.fileInfo);
    }
  };

  // 의견추가 팝업
  const addComment = () => {
    addCommentRef.current.open("");
  };

  // 의견 목록 조회
  const get_comment_list = async (press_key: string) => {
    const param: any = {
      command: "get_press_comment_list",
      press_key: press_key,
    };

    const res = await mainApi.post(param);
    if (res.code === "200") {
      setComments(res.response.comment_list);
    } else {
      console.error(res.response.error_msg);
      toastRef.current?.toast("" + res.response.error_msg, "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
    }
  };

  const commentsTabCallback = (command: string) => {
    if (command === "open_commentsviewer") {
      commentsViewerRef.current.open();
    }
  };

  const commentsViewerCallback = () => {};

  // 복사 금지
  const copyControl = (event: any) => {
    if (!props.userState.isAdmin) {
      event.preventDefault();
      event.clipboardData.clearData("Text");
    }
  };

  // Text 선택 Event
  useEffect(() => {
    document.getElementById("resultBox")?.addEventListener("copy", (event) => {
      copyControl(event);
    });

    return () => {
      // 메모리 누수 방지를 위해 핸들러 클리어
      document
        .getElementById("resultBox")
        ?.removeEventListener("copy", (event) => {
          copyControl(event);
        });
    };
  }, []);
  const [toolopen, setToolopen] = useState(true);

  return (
    <>
      <Box sx={{ p: 0, height: "100%" }}>
        <Stack direction={"row"} spacing={0} sx={{ height: "100%" }}>
          <Stack
            direction={"column"}
            spacing={0}
            sx={{
              width: "500px",
              minWidth: "500px",
              p: "32px",
              pb: "16px !important",
            }}
          >
            <Box className="sub-header-root">
              <Typography
                variant="h5"
                gutterBottom
                className="sub-header-title"
              >
                보도자료 작성
              </Typography>

              <ToggleButtonGroup
                color="standard"
                value={model}
                exclusive
                onChange={(e, newValue) => {
                  setModel(newValue);
                }}
                aria-label="Platform"
              >
                <ToggleButton
                  value="openai"
                  style={{
                    fontSize: "small",
                    height: "30px",
                  }}
                >
                  일반기사
                </ToggleButton>
                <ToggleButton
                  value="clova"
                  style={{
                    height: "30px",
                    fontSize: "small",
                  }}
                >
                  창의기사
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
            <Box className="sub-content-root">
              {/* 보도자료 진행 정보 */}
              <Box sx={{ width: "100%" }}>
                {!utils.isEmptyObj(pressInfo) && (
                  <CardPress
                    userState={props.userState}
                    pressInfo={pressInfo}
                    refresh={() => {
                      window.location.href = "/";
                    }}
                    openNewWindow={false}
                  />
                )}
              </Box>
              {/* 테마 영역 */}
              <ThemeModule
                ref={themeRef}
                userState={props.userState}
                pressInfo={pressInfo}
                ableRequest={ableRequest}
              />
              {/* 왼쪽 하단 버튼영역 */}
              <div className="creat-btn-wrap">
                {/* 파일첨부 버튼*/}
                {pressInfo.press_status === "CREATE" ||
                pressInfo.press_status === "RETURNED" ||
                pressInfo.press_status === "REJECTED" ||
                (props.userState.isAdmin &&
                  props.userState.id === pressInfo.hongbo_user_id &&
                  pressInfo.press_status !== "COMPLETE") ? (
                  <S3UploaderViaApi
                    rootBucket={S3_BUCKET_NAME}
                    uploadDir={`${props.userState.id}/press/`}
                    callBack={fncUploadComplete}
                  />
                ) : props.userState.isAdmin &&
                  props.userState.id === pressInfo.hongbo_user_id &&
                  pressInfo.press_status !== "COMPLETE" ? (
                  <S3UploaderViaApi
                    rootBucket={S3_BUCKET_NAME}
                    uploadDir={`${props.userState.id}/press/`}
                    callBack={fncUploadComplete}
                  />
                ) : (
                  <></>
                )}{" "}
                {/* 보도자료 생성 버튼 */}
                {pressInfo.press_status !== "COMPLETE" && (
                  <button
                    className="create btn-second"
                    onClick={() => {
                      if (
                        streamReader1 !== null ||
                        streamReader2 !== null ||
                        streamReader3 !== null ||
                        streamReader4 !== null
                      ) {
                        stopPressRelease();
                      } else if (
                        pressInfo.press_status === "CREATE" ||
                        pressInfo.press_status === "RETURNED" ||
                        pressInfo.press_status === "REJECTED" ||
                        (props.userState.isAdmin &&
                          props.userState.id === pressInfo.hongbo_user_id &&
                          pressInfo.press_status !== "COMPLETE")
                      )
                        createPressRelease();
                    }}
                    disabled={
                      !ableRequest ||
                      (!(
                        pressInfo.press_status === "CREATE" ||
                        pressInfo.press_status === "RETURNED" ||
                        pressInfo.press_status === "REJECTED"
                      ) &&
                        !(
                          props.userState.isAdmin &&
                          props.userState.id === pressInfo.hongbo_user_id &&
                          pressInfo.press_status !== "COMPLETE"
                        ))
                    }
                    title="보도자료 생성"
                  >
                    {streamReader1 !== null ||
                    streamReader2 !== null ||
                    streamReader3 !== null ||
                    streamReader4 !== null ? (
                      <>
                        <StopCircleIcon
                          style={{
                            marginRight: "8px",
                            verticalAlign: "middle",
                          }}
                        />
                        작성 중지
                      </>
                    ) : hisPage >= 1 ? (
                      "보도자료 재생성"
                    ) : (
                      "보도자료 생성"
                    )}
                  </button>
                )}
              </div>
            </Box>
          </Stack>
          {/* 구분선 */}
          <Divider orientation="vertical" variant="middle" flexItem />
          {/* llm 결과 */}
          <Stack
            direction={"column"}
            spacing={0}
            sx={{ width: "100%", p: "32px", pb: "16px !important" }}
          >
            <Box className="sub-header-root">
              <Stack direction={"row"} spacing={2}>
                <Typography
                  variant="h5"
                  gutterBottom
                  className="sub-header-title"
                >
                  작업툴
                </Typography>
                {props.userState.isAdmin && (
                  <>
                    {editMode && (
                      <>
                        <Tooltip title="변경사항 저장">
                          <IconButton
                            sx={{ p: 0 }}
                            onClick={async () => {
                              if (
                                !window.confirm(
                                  "수정한 내용을 저장하시겠습니까?"
                                )
                              )
                                return;
                              await changeResult(editResult);
                              setEditMode(false);
                            }}
                          >
                            <SaveIcon color="primary" />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                    {!editMode && pressInfo.press_status !== "COMPLETE" && (
                      <Tooltip title="내용 수정">
                        <IconButton
                          sx={{ p: 0 }}
                          onClick={() => {
                            setEditResult(result);
                            setEditMode(true);
                          }}
                        >
                          <img
                            src="/images/icon-write-op.png"
                            alt="Copy Icon"
                          />
                        </IconButton>
                      </Tooltip>
                    )}
                    <CopyToClipboard
                      text={result}
                      onCopy={() => {
                        toastRef.current?.toast(
                          "보도자료를 복사했습니다.",
                          "success",
                          3000,
                          {
                            vertical: "top",
                            horizontal: "center",
                          }
                        );
                      }}
                    >
                      <Tooltip title="결과 복사">
                        <IconButton sx={{ p: 0 }}>
                          <img src="/images/icon-copy-op.png" alt="Copy Icon" />
                        </IconButton>
                      </Tooltip>
                    </CopyToClipboard>
                    <>
                      <Tooltip title={totalCharacters}>
                        <IconButton sx={{ p: 0 }}>
                          <img
                            src="/images/icon-information-op.png"
                            alt="Copy Icon"
                          />
                        </IconButton>
                      </Tooltip>
                    </>

                    {editMode && (
                      <>
                        <Tooltip title="편집모드 닫기">
                          <IconButton
                            sx={{ p: 0 }}
                            onClick={() => {
                              if (
                                !window.confirm(
                                  "편집모드를 종료합니다.\n저장하지 않은 자료는 적용되지 않습니다."
                                )
                              )
                                return;
                              setEditMode(false);
                            }}
                          >
                            <CloseIcon color="primary" />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                  </>
                )}
              </Stack>
            </Box>
            <Box className="sub-content-root">
              {/* llm 결과 영역 */}
              <TextField
                size="small"
                fullWidth
                multiline
                rows={30}
                placeholder={"예시를 입력하세요."}
                value={editResult}
                onChange={(e) => {
                  setEditResult(e.target.value);
                }}
                type="search"
                autoComplete="off"
                inputProps={{ enterKeyHint: "Enter" }}
                sx={editMode ? { display: "flex" } : { display: "none" }}
              />
              <Box
                ref={resultRef}
                id={"resultBox"}
                className="result-content new-line"
                dangerouslySetInnerHTML={{ __html: result }}
                sx={!editMode ? { display: "" } : { display: "none" }}
              />

              {/* 우측 하단 영역 */}
              <Box className="template-footer">
                <Stack
                  direction={"column"}
                  spacing={2}
                  sx={{ width: "100%", alignItems: "center" }}
                >
                  {/* 히스토리 페이징 */}
                  {pressHistory.length > 0 && ableRequest && (
                    <Pagination
                      count={pressHistory.length}
                      siblingCount={3}
                      color="primary"
                      page={hisPage}
                      onChange={handleHisPage}
                    />
                  )}
                  {/* 홍보실 전송 버튼 */}
                  {!props.userState.isAdmin &&
                  pressInfo.press_status !== "COMPLETE" ? ( // 사업장
                    <Stack
                      direction={"row"}
                      spacing={2}
                      sx={{
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        variant="outlined"
                        className="btn-ghost"
                        onClick={addComment}
                        disabled={
                          !ableRequest ||
                          !(
                            pressInfo.press_status === "CREATE" ||
                            pressInfo.press_status === "RETURNED" ||
                            pressInfo.press_status === "REJECTED"
                          )
                        }
                        startIcon={
                          <img
                            src="/images/icon-opinion.png"
                            alt="Opinion Icon"
                          />
                        }
                        sx={{ width: "30%", borderRadius: "7px" }}
                      >
                        의견 추가
                      </Button>
                      <Button
                        variant="contained"
                        onClick={sendPressToHongbo}
                        disabled={
                          !ableRequest ||
                          !(
                            pressInfo.press_status === "CREATE" ||
                            pressInfo.press_status === "RETURNED" ||
                            pressInfo.press_status === "REJECTED"
                          )
                        }
                        endIcon={<NearMeIcon />}
                        sx={{ width: "30%", borderRadius: "7px" }}
                      >
                        홍보실 전송
                      </Button>
                    </Stack>
                  ) : pressInfo.press_status === "CREATE" ||
                    pressInfo.press_status === "RETURNED" ||
                    pressInfo.press_status === "REJECTED" ? ( //홍보실
                    <Stack
                      direction={"row"}
                      spacing={2}
                      sx={{
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        variant="outlined"
                        className="btn-ghost"
                        onClick={addComment}
                        disabled={
                          !ableRequest ||
                          !(
                            pressInfo.press_status === "CREATE" ||
                            pressInfo.press_status === "RETURNED" ||
                            pressInfo.press_status === "REJECTED"
                          )
                        }
                        startIcon={
                          <img
                            src="/images/icon-opinion.png"
                            alt="Opinion Icon"
                          />
                        }
                        sx={{ width: "30%", borderRadius: "7px" }}
                      >
                        의견 추가
                      </Button>
                      <Button
                        variant="contained"
                        className="btn-prim"
                        onClick={sendPressToHongbo}
                        disabled={
                          !ableRequest ||
                          !(
                            pressInfo.press_status === "CREATE" ||
                            pressInfo.press_status === "RETURNED" ||
                            pressInfo.press_status === "REJECTED"
                          )
                        }
                        endIcon={
                          <img src="/images/icon-send.png" alt="Opinion Icon" />
                        }
                        sx={{ width: "30%", borderRadius: "7px" }}
                      >
                        홍보실 전송
                      </Button>
                    </Stack>
                  ) : pressInfo.press_status === "HONGBO_ING" &&
                    (pressInfo.hongbo_user_id === undefined ||
                      pressInfo.hongbo_user_id === "") ? ( // 홍보실, 접수전
                    <Stack
                      direction={"row"}
                      spacing={2}
                      sx={{
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        variant="outlined"
                        className="btn-ghost"
                        onClick={returnPress}
                        disabled={!ableRequest}
                        color="error"
                        startIcon={<ErrorOutlineOutlinedIcon />}
                        sx={{ width: "30%", borderRadius: "7px" }}
                      >
                        반려
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={rejectPress}
                        disabled={!ableRequest}
                        color="error"
                        startIcon={<UndoIcon />}
                        sx={{ width: "30%", borderRadius: "7px" }}
                      >
                        보완 요청
                      </Button>
                      <Button
                        variant="contained"
                        className="btn-prim"
                        onClick={takeOverPress}
                        disabled={!ableRequest}
                        startIcon={<PlaylistAddIcon />}
                        sx={{ width: "30%", borderRadius: "7px" }}
                      >
                        홍보실 접수
                      </Button>
                    </Stack>
                  ) : pressInfo.press_status === "HONGBO_ING" &&
                    pressInfo.hongbo_user_id !== undefined &&
                    pressInfo.hongbo_user_id !== "" &&
                    pressInfo.hongbo_user_id === props.userState.id ? ( // 홍보실, 접수후
                    <Stack
                      direction={"row"}
                      spacing={2}
                      sx={{
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        variant="outlined"
                        className="btn-ghost"
                        onClick={addComment}
                        disabled={!ableRequest}
                        startIcon={<AddCommentIcon />}
                        fullWidth
                        sx={{ borderRadius: "7px", minWidth: "110px" }}
                      >
                        의견 추가
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={tossPress}
                        disabled={!ableRequest}
                        startIcon={<GroupsIcon />}
                        fullWidth
                        sx={{ borderRadius: "7px", minWidth: "130px" }}
                      >
                        담당자 토스
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={rejectPress}
                        disabled={!ableRequest}
                        color="error"
                        startIcon={<UndoIcon />}
                        fullWidth
                        sx={{ borderRadius: "7px", minWidth: "120px" }}
                      >
                        보완 요청
                      </Button>
                      <Button
                        variant="contained"
                        className="btn-prim"
                        onClick={completePress}
                        disabled={!ableRequest}
                        startIcon={<PlaylistAddCheckIcon />}
                        fullWidth
                        sx={{ borderRadius: "7px", minWidth: "160px" }}
                      >
                        홍보실 최종 완료
                      </Button>
                    </Stack>
                  ) : (
                    <></>
                  )}
                </Stack>
              </Box>
            </Box>
          </Stack>
        </Stack>
      </Box>
      {/* 로딩바 */}
      <LoadingCircle loading={isLoading} />
      {/* 알림 토스트 */}
      <Toast ref={toastRef} />
      {/* 추가 수정 레이어 */}
      {pressInfo.press_status === "CREATE" ||
      pressInfo.press_status === "RETURNED" ||
      pressInfo.press_status === "REJECTED" ||
      (props.userState.isAdmin &&
        props.userState.id === pressInfo.hongbo_user_id &&
        pressInfo.press_status !== "COMPLETE") ? (
        <AiMore
          model={model}
          eventId={"resultBox"}
          getFormData={themeRef.current.getFormData()} //참고자료
          originResult={result} //전체보도자료
          resultRef={resultRef}
          setResult={setResult}
          changeResult={changeResult}
        />
      ) : (
        <></>
      )}
      <Box
        sx={{
          display: "flex",
          position: "absolute",
          top: "66px",
          right: "0px",
          zIndex: "700",
        }}
      >
        <Stack direction={"column"} spacing={1}>
          {/* 첨부파일TAB */}
          {pressInfo.attach_files !== undefined &&
            pressInfo.attach_files.length > 0 && (
              <AttachFilesTab callback={attachFilesTabCallback} />
            )}
          {/* 의견TAB */}
          {comments.length > 0 && (
            <CommentsTab callback={commentsTabCallback} />
          )}
        </Stack>
      </Box>
      {/* 첨부파일 목록 뷰어 */}
      <AttachFileViewer
        ref={attachFileViewerRef}
        files={pressInfo.attach_files}
        canDelete={pressInfo.user_id === props.userState.id ? true : false}
        callback={attachFileViewerCallback}
      />
      {/* 의견 뷰어 */}
      <CommentsViewer
        ref={commentsViewerRef}
        comments={comments}
        callback={commentsViewerCallback}
      />
      {/* 의견추가 팝업 */}
      <PopAddComment
        ref={addCommentRef}
        userState={props.userState}
        pressInfo={pressInfo}
        callback={(command: string) => {
          if (command === "reload_comment") {
            get_comment_list(pressInfo.sk);
          }
          if (command === "reload_request_hongbo") {
            props.getLeftCount();
            window.location.href = "/myIngList";
          }
          if (command === "reload_return") {
            props.getLeftCount();
            window.location.href = "/myIngList";
          }
          if (command === "reload_reject") {
            props.getLeftCount();
            window.location.href = "/myIngList";
          }
          if (command === "reload_complete") {
            props.getLeftCount();
            getPressInfo(pressInfo.sk);
          }
        }}
      />
      {/* 담당자 토스 팝업 */}
      <PopToss
        ref={tossRef}
        userState={props.userState}
        pressInfo={pressInfo}
        callback={() => {
          props.getLeftCount();
          window.location.href = "/myIngList";
        }}
      />
    </>
  );
};

export default MakePress;
